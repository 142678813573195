<template>
  <b-row class="h-100">
    <b-col cols="12">
      <base-card style="position: sticky; top: 20px" v-if="loading">
        <b-row align-v="center">
          <b-col cols="10" class="">
            <b-row align-v="center">
              <b-col cols="auto">
                <h4 class="mb-0">{{ user_data.email }}</h4>
              </b-col>
              <b-col cols="auto" class="ps-0"
                ><img
                  v-if="user_data.email_confirm"
                  src="@/assets/img/icon/Solid/Status/Checked-box.svg"
                /><img
                  v-else
                  src="@/assets/img/icon/Solid/Status/Info-triangle.svg"
                />
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="2" class="text-end">
            <img
              src="@/assets/img/icon/Solid/Interface/Other 2.svg"
              alt="Деактивировать пользователя"
              title="Меню"
              class="pointer"
              tabindex="1"
              @click="openContentMenu"
            />
            <profile-context-menu> </profile-context-menu>
          </b-col>
          <b-col cols="12">
            <p class="mb-0">{{ user_data.telegram }}</p>
          </b-col>
        </b-row>
        <b-row class="mb-4">
          <b-col
            cols="auto"
            class="px-2"
            v-for="(item, index) in tags"
            :key="index"
          >
            <base-card class="p-1 px-3">{{ item.name }}</base-card>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-row v-for="(item, index) in balances" :key="index" class="mb-3">
              <b-col cols="6" class="mb-0">
                <p class="mb-0">Баланс {{ item.currency }}:</p>
              </b-col>
              <b-col cols="6" class="text-end mb-0">
                <p class="mb-0">
                  {{ item.balance.toLocaleString() }}
                  {{ item.currency }} ({{ item.balance_usd.toLocaleString() }}
                  USD)
                </p>
              </b-col>
              <b-col cols="6" v-if="item.general_address">
                <p class="mb-0">{{ item.general_address }}</p>
              </b-col>
              <b-col cols="6" class="text-end" v-if="item.general_address">
                <p class="mb-0" v-if="item.currency == 'USDT'">
                  {{ item.balance_coin.toLocaleString() }} USDT /
                  {{ item.balance_trx.toLocaleString() }} TRX
                </p>
                <p class="mb-0" v-else-if="item.currency == 'ETH'">
                  {{ item.balance_coin.toLocaleString() }} ETH
                </p>
              </b-col>
            </b-row>
          </b-col>
          <hr />
          <b-col cols="12">
            <b-row
              v-for="(item, index) in projects"
              :key="index"
              class="mb-3"
              align-v="center"
            >
              <b-col cols="6">
                <b-row
                  align-v="center"
                  @click="openProject(item.id)"
                  class="pointer"
                >
                  <b-col cols="auto" class="pe-0"
                    ><img
                      v-if="item.active"
                      src="@/assets/img/icon/Solid/Status/Checked-box.svg"
                    /><img
                      v-else
                      src="@/assets/img/icon/Solid/Status/Info-triangle.svg"
                    />
                  </b-col>
                  <b-col cols="auto">
                    <p class="mb-0">{{ item.name }}</p>
                    <p class="mb-0 еуче-ыьфдд">
                      <span v-if="item.cms_enable">CMS</span>
                      <span v-else>API / Html-Модуль</span>

                      <span v-if="item.cms_enable"> / {{ item.cms }}</span>
                    </p>
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="6" class="text-end pe-4">
                <p class="mb-0 pe-2">
                  <a :href="item.url_site" target="_blank">Ссылка на сайт</a>
                </p>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </base-card>
      <base-card style="position: sticky; top: 20px" v-else>
        <b-row align-h="center" class="text-center">
          <b-spinner variant="light"></b-spinner>
        </b-row>
      </base-card>
    </b-col>
  </b-row>
</template>
<script>
import { httpClient } from "../../HttpClientV1/http-client";
import ProfileContextMenu from "../ContextMenu/ProfileContextMenu.vue";
import eventBus from "../../EventBus.js";

export default {
  components: { ProfileContextMenu },

  data() {
    return {
      id: null,
      projects: null,
      balances: null,
      user_data: null,
      loading: false,
      tags: [
        {
          title: "Новый пользователь",
        },
        {
          title: "WooCommerce",
        },
      ],
    };
  },
  methods: {
    openContentMenu() {
      eventBus.$emit("open_profile_context_menu", this.id);
    },
    LoadingUserProfile() {
      this.loading = false;
      httpClient
        .get("/v1/user/dashboard/user/detail?email=" + this.id)
        .then((response) => {
          this.balances = response.data.balances;
          this.projects = response.data.projects;
          this.user_data = response.data;
          this.tags = response.data.tags;
        })
        .finally(() => {
          this.loading = true;
        });
    },
    openProject(id) {
      eventBus.$emit("set_selected_component", "ProjectDetail");
      setTimeout(() => {
        eventBus.$emit("loading_project_detail", id);
      }, 100);
    },
  },
  mounted() {
    eventBus.$on("load_user_detail", (data) => {
      this.id = data;
      this.LoadingUserProfile();
    });
  },
  beforeDestroy() {
    eventBus.$off("load_user_detail");
  },
};
</script>
