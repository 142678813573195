<template>
  <div class="chat__card text-left">
    <b-row align-h="center">
      <b-col>
        <div class="subtitle mx-auto">{{ subTitle }}</div>
        <div class="text mx-auto">{{ text }}</div>
        <div class="hr_badge red_badge">{{ departament }}</div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
export default {
  inheritAttrs: false,
  name: "chat-card",
  props: {
    departament: {
      type: String,
      description: "Departament",
    },
    subTitle: {
      type: String,
      description: "Card subtitle",
    },
    text: {
      type: String,
      description: "card text",
    },
  }
};
</script>
