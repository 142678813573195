<template>
  <div class="sidebar">
    <b-row class="subHeader">
      <b-col md="auto">
        <h4>Чаты</h4>
      </b-col>
      <b-col md="auto">
        <h6>Смотреть все</h6>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <chat-card subTitle="Срочный вывод средств на карту" text="info@easypayments.vip" departament="support"></chat-card>
      </b-col>
      <b-col cols="12">
        <chat-card subTitle="Срочный вывод средств на карту" text="info@easypayments.vip" departament="payment"></chat-card>
      </b-col>
      <b-col cols="12">
        <chat-card subTitle="Срочный вывод средств на карту" text="info@easypayments.vip" departament="tehnical"></chat-card>
      </b-col>
      <b-col cols="12">
        <chat-card subTitle="Срочный вывод средств на карту" text="info@easypayments.vip" departament="support"></chat-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  inheritAttrs: false,
  name: "right-sidebar",
  props: {
    title: {
      type: String,
      description: "title",
    },
    subTitle: {
      type: String,
      description: "Table subtitle",
    },
  }
};
</script>
