<template>
  <div
    class="custom-select select-wrapper"
    @click="is_open = true"
    @blur="is_open = false"
    tabindex="1"
  >
    <div class="selected">
      <base-card :class="{ context: !blur }">
        <b-row align-h="between" align-v="center">
          <b-col cols="auto">{{ label }}</b-col>
          <b-col><span class="arrow" :class="{ open: is_open }"></span></b-col>
        </b-row>
      </base-card>
    </div>
    <div class="list-items" v-if="is_open">
      <base-card
        class="p-0 border-0"
        :class="{ context: !blur }"
        v-if="Array.isArray(selected)"
      >
        <div
          class="item"
          v-for="(item, index) in options"
          :key="index"
          :class="{ active: selected.find((p) => p.value == item.value) }"
          @click.stop="selectItem(item)"
        >
          {{ item.name }}
        </div>
      </base-card>
      <base-card class="p-0 border-0" :class="{ context: !blur }" v-else>
        <div
          class="item"
          v-for="(item, index) in options"
          :key="index"
          :class="{ active: selected.value === item.value }"
          @click.stop="selectItem(item)"
        >
          {{ item.name }}
        </div>
      </base-card>
    </div>
  </div>
</template>
<script>
import BaseCard from "../card/BaseCard.vue";
export default {
  components: { BaseCard },
  inheritAttrs: false,
  name: "base-select",
  data() {
    return {
      is_open: false,
    };
  },
  props: {
    label: {
      type: String,
      description: "Label для инпута",
    },
    options: {
      type: Array,
      default: [],
      required: true,
    },

    selectedOptions: {
      type: [Array, Object],
      required: true,
    },
    classLabel: {
      type: String,
      description: "Class для label",
    },
    value: {
      type: [String, Number, Array],
      description: "Значение инпута",
    },
    blur: {
      type: Boolean,
      default: true,
    },
  },
  model: {
    prop: "value",
    event: "input",
  },
  computed: {
    selected: {
      get() {
        return this.selectedOptions;
      },

      set(value) {
        this.$emit("update:default", value);
      },
    },
  },
  methods: {
    onInput(evt) {
      this.$emit("input", evt.target.value);
    },
    selectItem(item) {
      this.$emit("change", item);
      if (!Array.isArray(item)) {
        this.is_open = false;
      }
    },
  },
};
</script>
