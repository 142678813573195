<template>
  <div class="home">
    <h1>Support</h1>
  </div>
</template>

<script>

export default {
  name: 'Support',
  components: {
    
  }
}
</script>
