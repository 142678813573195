<template>
  <div class="content-block pe-0">
    <b-container>
      <div class="notification-wrapper">
        <span
          class="notification-item show"
          v-for="(item, index) in notification"
          :key="index"
          :class="item.type"
          >{{ item.text }}</span
        >
      </div>
      <b-row class="pt-4 h-100" align-v="center">
        <b-col>
          <h4>Редактировать статью</h4>
        </b-col>
        <!-- <b-col cols="auto">
          <base-select
            :options="all_tags"
            :selectedOptions="tags"
            v-model="tags"
            @change="addUserTag"
            label="Добавить тэги"
            :blur="true"
          ></base-select>
        </b-col> -->
      </b-row>
      <!-- <b-row v-if="tags.length > 0">
        <b-col
          cols="auto"
          class="px-2"
          v-for="(item, index) in tags"
          :key="index"
        >
          <base-card class="p-1 px-3 border-0 context" :accent="true">
            <b-row align-h="between" align-v="center">
              <b-col cols="auto">{{ item.name }}</b-col>
              <b-col cols="auto" class="p-0">
                <img
                  class="pointer"
                  @click="addUserTag(item)"
                  src="@/assets/img/icon/Solid/Interface/Cross.svg"
              /></b-col> </b-row
          ></base-card>
        </b-col>
      </b-row>

      <b-row v-else>
        <b-col cols="auto">
          <p class="small">Здесь появяться тэги, когда ты их выберешь</p>
        </b-col>
      </b-row> -->
      <b-row class="mb-2 align-content-stretch h-100">
        <b-col cols="12">
          <base-card>
            <b-row>
              <b-col cols="12">
                <b-row align-h="between">
                  <b-col cols="6">
                    <h4>Основные данные</h4>
                  </b-col>
                  <b-col cols="auto">
                    <b-row>
                      <b-col cols="auto">
                        <base-select
                          :label="article_status.name"
                          :options="options_status"
                          :selectedOptions="article_status"
                          @change="selectStatus"
                          :blur="false"
                        ></base-select>
                      </b-col>
                      <b-col cols="auto">
                        <base-select
                          label="Категории"
                          :options="options_categoryes"
                          :selectedOptions="article_category"
                          @change="selectCategory"
                          :blur="false"
                        ></base-select>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="6">
                <base-input
                  v-model="title"
                  @input="generateSlugArticle"
                  label="Заголовок статьи"
                  placeholder="Напиши сюда заголовок статьи, он будет отображаться в списке статей..."
                >
                </base-input>
              </b-col>
              <b-col cols="6">
                <base-input
                  v-model="titleH1"
                  label="Заголовок H1"
                  placeholder="Напиши сюда заголовок статьи, он будет отображаться на странице статьи..."
                >
                </base-input>
              </b-col>
              <b-col cols="6" class="position-relative">
                <base-input
                  v-model="slug"
                  label="Ссылка на статью"
                  placeholder="Ссылка на статью, к ней добавить /blog/{ссылка}"
                  :readonly="!hand_slug"
                >
                </base-input>

                <b-badge
                  variant="success"
                  class="btn-input pointer"
                  v-if="!hand_slug"
                  >Указать руками</b-badge
                >
                <b-badge
                  variant="warning"
                  class="btn-input pointer"
                  v-else
                ></b-badge>
              </b-col>
              <b-col cols="6">
                <base-input
                  v-model="time_to_read"
                  label="Время на чтение"
                  placeholder="Напиши сюда сколько примерно необходимо потратить на чтение статьи..."
                >
                </base-input>
              </b-col>
              <b-col cols="6">
                <base-input
                  v-model="keywords"
                  label="Ключевые слова"
                  placeholder="Укажи ключевые слова через запятую, это нужно для СЕО"
                >
                </base-input>
              </b-col>
              <b-col cols="6">
                <base-input
                  v-model="meta_description"
                  label="Описание"
                  placeholder="Короткое описание 160-180 символов"
                >
                </base-input>
              </b-col>
              <b-col cols="6">
                <b-row>
                  <b-col cols="12">
                    <v-date-picker
                      v-model="published_date"
                      :model-config="modelConfig"
                    >
                      <template v-slot="{ inputValue, inputEvents }">
                        <base-input
                          label="Дата публикации"
                          type="text"
                          placeholder="Укажи дату и время когда статья будет доступна(только в статусе опубликована)"
                          class="rounded focus:outline-none focus:border-blue-300"
                          :value="inputValue"
                          v-on="inputEvents"
                        />
                      </template>
                    </v-date-picker>
                  </b-col>
                  <!-- <b-col cols="12">
                    <div class="form-control">
                      <label class="control-label fw-medium mb-0"
                        >Категории статьи</label
                      >
                    </div>
                  </b-col> -->
                  <!-- <b-col cols="12">
                    <b-row class="px-3">
                      <b-col cols="auto">
                        <base-select
                          :options="options"
                          :selectedOptions="selected"
                          v-model="selected"
                          @change="addUserType"
                          label="Назначить категорию"
                          :blur="false"
                        ></base-select>
                      </b-col>
                    </b-row>
                    <div class="px-3">
                      <b-row v-if="selected.length > 0">
                        <b-col
                          cols="auto"
                          class=""
                          v-for="(item, index) in selected"
                          :key="index"
                        >
                          <base-card class="border-0 context" :accent="true">
                            <b-row align-h="between" align-v="center">
                              <b-col cols="auto">{{ item.name }}</b-col>
                              <b-col cols="auto" class="p-0">
                                <img
                                  class="pointer"
                                  @click="addUserType(item)"
                                  src="@/assets/img/icon/Solid/Interface/Cross.svg"
                              /></b-col> </b-row
                          ></base-card>
                        </b-col>
                      </b-row>
                    </div> -->
                  <!-- <div class="form-control">
                      <b-form-select
                        class="multiple-select"
                        v-model="selected"
                        :options="options"
                        multiple
                        :select-size="5"
                      ></b-form-select>
                    </div> -->
                  <!-- </b-col> -->
                  <!-- <b-col cols="4">
                    <base-button class="mt-0">Добавить</base-button>
                  </b-col> -->
                </b-row>
              </b-col>
              <b-col cols="6">
                <img
                  src=""
                  alt=""
                  id="image_drop_area"
                  width="100%"
                  class="mt-4 px-3"
                />
                <b-form-file
                  class="mt-4 px-3"
                  v-model="cover_file"
                  plain
                  @input="uploadCover"
                ></b-form-file>
              </b-col>
              <b-col cols="6" offset="6">
                <base-input
                  v-model="alt_cover"
                  label="ALT Обложки"
                  placeholder="Короткое описание 160-180 символов"
                >
                </base-input>
              </b-col>
            </b-row>
            <b-row align-h="between">
              <b-col cols="3" class="mt-3">
                <base-button class="py-0" @click="createArticle"
                  >Сохранить</base-button
                >
              </b-col>
              <b-col cols="3" class="mt-3 text-end">
                <base-button
                  class="py-0 w-100"
                  variant="secondary"
                  @click="goToArticleDetail"
                  >Перейти к редактору</base-button
                >
              </b-col>
            </b-row>
          </base-card>
        </b-col>
      </b-row>
      <b-modal
        id="tags-modal"
        ref="tags-modal"
        title="Создать Тэг"
        scrollable
        hide-footer
      >
        <b-row class="mt-3" align-h="center">
          <b-col cols="12" class="px-0">
            <base-input
              placeholder="Название"
              v-model="nameTag"
              @input="generateCodeTag"
            ></base-input>
          </b-col>
          <b-col cols="12" class="px-0">
            <base-input
              placeholder="Код тэга"
              v-model="codeTag"
              readonly
            ></base-input>
          </b-col>
          <b-col cols="8">
            <base-button @click="addNewTag">Добавить</base-button>
          </b-col>
        </b-row>
      </b-modal>
      <b-modal
        id="type-modal"
        ref="type-modal"
        title="Создать категорию"
        scrollable
        hide-footer
      >
        <b-row class="mt-3" align-h="center">
          <b-col cols="12" class="px-0">
            <base-input
              placeholder="Название"
              v-model="nameType"
              @input="generateCodeTag"
            ></base-input>
          </b-col>
          <b-col cols="12" class="px-0">
            <base-input
              placeholder="Уникальная ссылка"
              v-model="codeType"
              readonly
            ></base-input>
          </b-col>
          <b-col cols="12" class="px-0">
            <base-input placeholder="Порядок" v-model="orderType"></base-input>
          </b-col>
          <b-col cols="8">
            <base-button @click="addNewType">Добавить</base-button>
          </b-col>
        </b-row>
      </b-modal>
    </b-container>
  </div>
</template>

<script>
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import BaseCalendar from "../components/calendar/BaseCalendar.vue";
import BaseCard from "../components/card/BaseCard.vue";
import { httpClient } from "../HttpClientV1/http-client";
import BaseInput from "../components/input/BaseInput.vue";
import BaseButton from "../components/button/BaseButton.vue";
import HTMLEditer from "../components/editer/HTMLEditer.vue";
import DropdownMenu from "../components/editer/DropdownMenu.vue";

import eventBus from "../EventBus";
import BaseSelect from "../components/input/BaseSelect.vue";
export default {
  name: "Payments",
  components: {
    BaseCalendar,
    BaseCard,
    BaseInput,
    BaseButton,
    HTMLEditer,
    DropdownMenu,
    BaseSelect,
  },
  data() {
    return {
      nameType: null,
      codeType: null,
      ordertype: 0,
      hand_slug: true,
      start: moment().startOf("isoWeek").format("DD.MM.YY"),
      end: moment().format("DD.MM.YY"),
      slug: null,
      title: null,
      titleH1: null,
      time_to_read: null,
      keywords: null,
      alt_cover: null,
      meta_description: null,
      cover_file: null,
      tags: [],
      options: [],
      article_status: {
        name: "Черновик",
        value: "draft",
      },
      article_category: [
        {
          name: "Платежные системы",
          value: "payment-systems",
        },
      ],
      options_status: [
        {
          name: "Черновик",
          value: "draft",
        },
        {
          name: "На проверке",
          value: "review",
        },
        {
          name: "Опубликована",
          value: "published",
        },
      ],
      options_categoryes: [
        {
          name: "Все статьи",
          value: "recent",
        },
        {
          name: "	Популярные статьи",
          value: "popular",
        },
        {
          name: "Регистрация компаний",
          value: "company-registration",
        },
        {
          name: "Платежные системы",
          value: "payment-systems",
        },
        {
          name: "Банковские счета",
          value: "bank-accounts",
        },
        {
          name: "Маркетплейсы",
          value: "marketplaces",
        },
        {
          name: "Кейсы и Гайды",
          value: "cases",
        },
      ],
      modelConfig: {
        type: "string",
        mask: "DD.MM.YY", // Uses 'iso' if missing
      },
      all_tags: [],
      codeTag: null,
      nameTag: null,
      published_date: null,
      cover: null,
      selected: [],

      notification: [],
    };
  },
  methods: {
    ...mapActions(["toggleCalendar"]),
    toggleHandSlug() {
      this.hand_slug = !this.hand_slug;
      if (!this.hand_slug) {
        this.slug = window.URLify(this.title);
      }
    },
    createArticle(go_edir = false) {
      let cover_link;
      if (/media/.test(this.cover)) {
        cover_link = this.cover.substring(6);
      } else {
        cover_link = this.cover;
      }
      let type_article = Array();
      this.selected.forEach((item) => {
        type_article.push(item.code);
      });
      let data = {
        type_article: type_article,
        id: parseInt(this.$route.params.id),
      };
      let article = {
        id: parseInt(this.$route.params.id),
        title: this.title,
        titleH1: this.titleH1,
        time_to_read: this.time_to_read,
        // category: this.article_category.value,
        slug: this.slug,
        meta_description: this.meta_description,
        status: this.article_status.value,
        published_date: moment(this.published_date, "DD.MM.YYYY").format(
          "DD.MM.YY"
        ),
        keywords: this.keywords,
        cover: cover_link,
        alt_cover: this.alt_cover,
      };
      const categories = [];
      this.article_category.forEach((item) => {
        categories.push(item.value);
      });
      const data_article = {
        article: article,
        categories: categories,
      };
      httpClient
        .post("/api/blog/dashboard/article/update", data_article)
        .then((response) => {
          this.loadArticleDetail();

          httpClient
            .post("/api/blog/dashboard/type/bind", data)
            .then((response) => {
              this.notification.push({
                text: "Сохранено",
                type: "success",
              });
              let self = this;
              setTimeout(() => {
                self.notification = [];
                if (go_edir) {
                  self.$router.push(
                    "/content/articles/" + this.$route.params.id
                  );
                }
              }, 2000);
            });
        });
    },
    uploadCover() {
      let formData = new FormData();
      formData.append("file", this.cover_file);
      httpClient
        .post("/api/blog/dashboard/article/upload", formData)
        .then((response) => {
          document
            .querySelector("#image_drop_area")
            .setAttribute(
              "src",
              "https://easypayments.online/media/" + response.data
            );
          this.cover = response.data;
          console.log(response.data);
          console.log(this.cover);
        });
    },
    goToArticleDetail() {
      this.createArticle(true);
    },
    selectStatus(item) {
      this.article_status = item;
    },
    selectCategory(item) {
      // this.article_category = item;
      if (this.article_category.includes(item)) {
        const findedOption = this.article_category.find(
          (opt) => opt.value.toUpperCase() === item.value.toUpperCase()
        );
        let myIndex = this.article_category.indexOf(findedOption);
        if (myIndex !== -1) {
          this.article_category.splice(myIndex, 1);
        }
      } else {
        this.article_category.push(item);
      }
    },

    generateSlugArticle() {
      if (!this.hand_slug) {
        this.slug = window.URLify(this.title);
      }
    },
    generateCodeTag() {
      this.codeTag = window.URLify(this.nameTag);
    },
    addNewTag() {
      let tag_article = {
        name: this.nameTag,
        code: this.codeTag,
      };
      httpClient
        .post("v1/blog/dashboard/tags/create", { tag_article })
        .then((response) => {
          this.getAllTags();
          this.$root.$emit("bv::hide::modal", "tags-modal");
        });
    },
    getAllTags() {
      this.all_tags = [];
      this.all_tags.push({
        name: "Создать новый",
        code: "add_new_tag",
      });
      httpClient.get("v1/blog/dashboard/tags/all").then((response) => {
        this.all_tags = [...this.all_tags, ...response.data];
      });
    },
    getAllType() {
      httpClient.get("v1/blog/dashboard/type/all").then((response) => {
        let new_option = Array();
        response.data.forEach((option) => {
          let data = {
            code: option.slug,
            name: option.name,
          };
          new_option.push(data);
        });
        this.options = new_option;
      });
    },
    addUserTag(item) {
      if (item.code == "add_new_tag") {
        this.$root.$emit("bv::show::modal", "tags-modal", "#tags-modal");
      } else {
        if (this.tags.includes(item)) {
          const findedOption = this.tags.find(
            (opt) => opt.code.toUpperCase() === item.code.toUpperCase()
          );
          let myIndex = this.tags.indexOf(findedOption);
          if (myIndex !== -1) {
            let data = {
              id: this.id,
              code: item.code,
            };
            httpClient
              .post("v1/blog/dashboard/tags/unbind", data)
              .then((response) => {
                this.getAllArticlerTags();
              });
          }
        } else {
          let data = {
            id: this.id,
            code: item.code,
          };
          httpClient
            .post("v1/blog/dashboard/tags/bind", data)
            .then((response) => {
              this.getAllArticlerTags();
            });
        }
      }
    },
    addUserType(item) {
      const findedOption = this.selected.find(
        (opt) => opt.code.toUpperCase() === item.code.toUpperCase()
      );
      console.log(findedOption);
      if (findedOption) {
        let myIndex = this.selected.indexOf(findedOption);
        console.log(myIndex);
        if (myIndex !== -1) {
          this.selected.splice(myIndex, 1);
        }
      } else {
        this.selected.push(item);
      }
    },

    getAllArticlerTags() {
      httpClient
        .get("v1/blog/dashboard/tags/article?id=" + this.$route.params.id)
        .then((response) => {
          this.tags = response.data;
          this.key += 1;
        });
    },
    loadArticleDetail() {
      this.selected = [];
      httpClient
        .get("/api/blog/dashboard/article/detail?id=" + this.$route.params.id)
        .then((response) => {
          Object.keys(response.data).forEach((key) => {
            try {
              this[key] = response.data[key];
              if (this[key] == "None") {
                this[key] = "";
              }
            } catch (e) {
              console.log(e);
            }
          });
          this.article_status = this.options_status.find(
            (item) => item.value === response.data.status
          );
          this.article_category.splice(0, this.article_category.length);
          response.data.categories.forEach((item) => {
            let search = this.options_categoryes.find(
              (i) => i.value === item.slug
            );
            if (search) {
              this.article_category.push(search);
            }
          });
          // this.article_category = this.options_categoryes.find(
          //   (item) => item.value === response.data.category
          // );
          httpClient
            .get("/api/blog/dashboard/type/article?id=" + this.$route.params.id)
            .then((response) => {
              response.data.forEach((item) => {
                console.log(this.options);
                let search = this.options.find((i) => i.code === item);
                if (search) {
                  this.selected.push(search);
                }
              });
            });
          document
            .querySelector("#image_drop_area")
            .setAttribute("src", "https://easypayments.online/" + this.cover);
          this.loading = false;
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
  mounted() {
    const RUSSIAN_MAP = {
      а: "a",
      б: "b",
      в: "v",
      г: "g",
      д: "d",
      е: "e",
      ё: "yo",
      ж: "zh",
      з: "z",
      и: "i",
      й: "j",
      к: "k",
      л: "l",
      м: "m",
      н: "n",
      о: "o",
      п: "p",
      р: "r",
      с: "s",
      т: "t",
      у: "u",
      ф: "f",
      х: "h",
      ц: "c",
      ч: "ch",
      ш: "sh",
      щ: "sh",
      ъ: "",
      ы: "y",
      ь: "",
      э: "e",
      ю: "yu",
      я: "ya",
      А: "A",
      Б: "B",
      В: "V",
      Г: "G",
      Д: "D",
      Е: "E",
      Ё: "Yo",
      Ж: "Zh",
      З: "Z",
      И: "I",
      Й: "J",
      К: "K",
      Л: "L",
      М: "M",
      Н: "N",
      О: "O",
      П: "P",
      Р: "R",
      С: "S",
      Т: "T",
      У: "U",
      Ф: "F",
      Х: "H",
      Ц: "C",
      Ч: "Ch",
      Ш: "Sh",
      Щ: "Sh",
      Ъ: "",
      Ы: "Y",
      Ь: "",
      Э: "E",
      Ю: "Yu",
      Я: "Ya",
    };
    const ALL_DOWNCODE_MAPS = [RUSSIAN_MAP];
    const Downcoder = {
      Initialize: function () {
        if (Downcoder.map) {
          // already made
          return;
        }
        Downcoder.map = {};
        for (const lookup of ALL_DOWNCODE_MAPS) {
          Object.assign(Downcoder.map, lookup);
        }
        Downcoder.regex = new RegExp(Object.keys(Downcoder.map).join("|"), "g");
      },
    };
    function downcode(slug) {
      Downcoder.Initialize();
      return slug.replace(Downcoder.regex, function (m) {
        return Downcoder.map[m];
      });
    }
    function URLify(s, num_chars, allowUnicode) {
      // changes, e.g., "Petty theft" to "petty-theft"
      if (!allowUnicode) {
        s = downcode(s);
      }
      s = s.toLowerCase(); // convert to lowercase
      // if downcode doesn't hit, the char will be stripped here
      if (allowUnicode) {
        // Keep Unicode letters including both lowercase and uppercase
        // characters, whitespace, and dash; remove other characters.
        s = XRegExp.replace(s, XRegExp("[^-_\\p{L}\\p{N}\\s]", "g"), "");
      } else {
        s = s.replace(/[^-\w\s]/g, ""); // remove unneeded chars
      }
      s = s.replace(/^\s+|\s+$/g, ""); // trim leading/trailing spaces
      s = s.replace(/[-\s]+/g, "-"); // convert spaces to hyphens
      s = s.substring(0, num_chars); // trim to first num_chars chars
      s = s.replace(/-+$/g, ""); // trim any trailing hyphens
      return s;
    }
    window.URLify = URLify;
    this.loadArticleDetail();
    this.getAllTags();
    this.getAllType();
  },
};
</script>
