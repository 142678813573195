<template>
  <div
    class="context-menu-wrapper dropdown_menu"
    @blur="is_open = false"
    tabindex="1"
    ref="dropdown_menu"
    @mouseleave="is_open = false"
  >
    <div
      class="selected pointer"
      @mouseenter="
        is_open = true;
        return false;
      "
    >
      <base-card class="context">
        <b-row align-h="between" align-v="center">
          <b-col cols="auto">{{ label }}</b-col>
        </b-row>
      </base-card>
    </div>
    <div class="list-items" v-if="is_open">
      <slot></slot>
    </div>
  </div>
</template>
<script>
import eventBus from "../../EventBus";
export default {
  props: {
    label: {
      type: String,
      default: "label",
    },
  },
  data() {
    return {
      email: null,
      is_open: false,
    };
  },
  mounted() {
    eventBus.$on("open_dropdown_context_menu", (data) => {
      this.is_open = !this.is_open;
      if (this.is_open) {
        this.$refs.dropdown_menu.focus();
      }
    });
  },
  beforeDestroy() {
    eventBus.$off("open_dropdown_context_menu");
  },
};
</script>
