<template>
  <div class="form-control">
    <slot name="label">
      <label v-if="label" class="control-label fw-medium" :class="[classLabel]">
        {{ label }}
      </label>
    </slot>
    <slot>
      <input
        class="base__input"
        v-bind="$attrs"
        :value="value"
        v-on="listeners"
      />
    </slot>
  </div>
</template>
<script>
export default {
  inheritAttrs: false,
  name: "base-input",
  props: {
    label: {
      type: String,
      description: "Label для инпута",
    },

    classLabel: {
      type: String,
      description: "Class для label",
    },
    value: {
      type: [String, Number, Array],
      description: "Значение инпута",
    },
  },
  model: {
    prop: "value",
    event: "input",
  },
  computed: {
    listeners() {
      return {
        ...this.$listeners,
        input: this.onInput,
      };
    },
  },
  methods: {
    onInput(evt) {
      this.$emit("input", evt.target.value);
    },
  },
};
</script>
