<template>
  <div class="content-block pe-0">
    <b-container>
      <b-row class="pt-4">
        <b-col>
          <h1>Вывод комиссий</h1>
        </b-col>
        <b-col md="auto" class="mt-3">
          <BaseCalendar
            :dateStart="start"
            :dateEnd="end"
            @submitFilterData="setDataFilter"
            :arrowCalendar="arrowCalendar"
          >
          </BaseCalendar>
        </b-col>
      </b-row>
      <b-row class="mb-2 align-content-stretch h-100">
        <b-col cols="6" v-if="!loading">
          <b-row v-if="!openMerchantInfo">
            <b-col
              cols="12"
              v-for="(item, index) in data"
              :key="index"
              @click="selectItem(item.email)"
              class="pointer"
            >
              <base-card
                :accent="selected == item.email"
                :selected="selected == item.email"
              >
                <b-row>
                  <b-col cols="6">
                    <p class="mb-0">{{ item.email }}</p>
                  </b-col>
                  <b-col cols="6" class="text-end">
                    <p class="mb-0">{{ item.amount }} USD</p>
                  </b-col>
                </b-row>
              </base-card>
            </b-col>
            <b-col cols="12" v-if="data.length == 0">
              <base-card>
                <b-row align-h="center" class="text-center">
                  <p>Нет данных за выбранный период</p>
                  <p
                    @click="openCalendar"
                    class="pointer text-decoration-underline mb-0"
                  >
                    Выбрать период
                  </p>
                </b-row>
              </base-card>
            </b-col>
          </b-row>
          <b-row v-else>
            <b-col cols="12">
              <base-card>
                <b-row>
                  <b-col cols="12" class="text-end pe-1">
                    <img
                      class="pointer"
                      @click="closeMerchant"
                      src="@/assets/img/icon/Solid/Interface/Cross.svg"
                    />
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="6">
                    <p class="mb-0">Мерчант:</p>
                  </b-col>
                  <b-col cols="6" class="text-end">
                    <p class="mb-0">{{ merchantEmail }}</p>
                  </b-col>
                  <b-col cols="6">
                    <p>Telegram:</p>
                  </b-col>
                  <b-col cols="6" class="text-end">
                    <p>{{ telegram }}</p>
                  </b-col>
                  <hr />
                  <b-col cols="6">
                    <p>Баланс BTC:</p>
                  </b-col>
                  <b-col cols="6" class="text-end">
                    <p>{{ balance_btc }} BTC</p>
                  </b-col>
                  <b-col cols="6">
                    <p>Баланс LTC:</p>
                  </b-col>
                  <b-col cols="6" class="text-end">
                    <p>{{ balance_ltc }} LTC</p>
                  </b-col>
                  <b-col cols="6">
                    <p>Баланс ETH:</p>
                  </b-col>
                  <b-col cols="6" class="text-end">
                    <p>{{ balance_eth }} ETH</p>
                  </b-col>
                  <b-col cols="6">
                    <p>Баланс USDT:</p>
                  </b-col>
                  <b-col cols="6" class="text-end">
                    <p>{{ balance_usdt }} USDT</p>
                  </b-col>
                  <hr />

                  <b-col cols="12">
                    <b-row
                      align-content="center"
                      align-h="between"
                      align-v="center"
                      v-for="(item, index) in data_table_project"
                      :key="index"
                    >
                      <b-col cols="6">
                        <p>{{ item.name }}:</p>
                      </b-col>
                      <b-col cols="4" class="text-end">
                        <p class="text-truncate">
                          <span
                            class="project_status"
                            :class="{
                              active: item.active,
                              deactive: !item.active,
                            }"
                          ></span
                          >{{ item.url_site }}
                        </p>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </base-card>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="6" v-else>
          <base-card>
            <b-row align-h="center" class="text-center">
              <b-spinner variant="light"></b-spinner>
            </b-row>
          </base-card>
        </b-col>
        <b-col cols="6" v-if="!loading_data">
          <b-row class="h-100">
            <b-col cols="12">
              <base-card style="position: sticky; top: 20px">
                <b-row>
                  <b-col cols="6">
                    <p>Мерчант:</p>
                  </b-col>
                  <b-col cols="6" class="text-end">
                    <p class="pointer" @click="openMerchant(merchant_id)">
                      {{ selected }}
                    </p>
                  </b-col>
                  <b-col cols="6">
                    <p class="mb-0">Сумма BTC:</p>
                  </b-col>
                  <b-col cols="6" class="text-end">
                    <p class="mb-0">{{ amount_btc }} BTC</p>
                  </b-col>
                  <b-col cols="6">
                    <p class="mb-0">Сумма LTC:</p>
                  </b-col>
                  <b-col cols="6" class="text-end">
                    <p class="mb-0">{{ amount_ltc }} LTC</p>
                  </b-col>
                  <b-col cols="6">
                    <p class="mb-0">Сумма ETH:</p>
                  </b-col>
                  <b-col cols="6" class="text-end">
                    <p class="mb-0">{{ amount_eth }} ETH</p>
                  </b-col>
                  <b-col cols="6">
                    <p class="mb-0">Сумма USDT:</p>
                  </b-col>
                  <b-col cols="6" class="text-end">
                    <p class="mb-0">{{ amount_usdt }} USDT</p>
                  </b-col>
                </b-row>
                <b-row class="mt-5">
                  <b-col cols="12">
                    <base-input
                      label="Адрес вывода USDT"
                      class="px-0"
                      v-model="address_usdt"
                      placeholder="Адрес вывода USDT"
                    ></base-input>
                  </b-col>
                  <b-col cols="4" сlass="px-0">
                    <base-button
                      class="px-0"
                      @click="sendLadgerUSDT"
                      :disabled="disabled_btn"
                      >Отправить USDT</base-button
                    >
                  </b-col>
                </b-row>
                <!-- <b-row class="mt-3">
                  <b-col cols="12">
                    <base-input
                      label="Адрес вывода ETH"
                      class="px-0"
                      v-model="address_eth"
                      placeholder="Адрес вывода ETH"
                    ></base-input>
                  </b-col>
                  <b-col cols="4" сlass="px-0">
                    <base-button class="px-0" @click="sendLadgerETH"
                      >Отправить ETH</base-button
                    >
                  </b-col>
                </b-row> -->
                <!-- <b-row class="mt-3">
                  <b-col cols="12">
                    <base-input
                      label="Адрес вывода BTC"
                      class="px-0"
                      v-model="address_btc"
                      placeholder="Адрес вывода BTC"
                    ></base-input>
                  </b-col>
                  <b-col cols="4" сlass="px-0">
                    <base-button class="px-0" @click="sendLadgerBTC"
                      >Отправить BTC</base-button
                    >
                  </b-col>
                </b-row> -->
                <!-- <b-row class="mt-3">
                  <b-col cols="12">
                    <base-input
                      label="Адрес вывода LTC"
                      class="px-0"
                      v-model="address_ltc"
                      placeholder="Адрес вывода LTC"
                    ></base-input>
                  </b-col>
                  <b-col cols="4" сlass="px-0">
                    <base-button class="px-0" @click="sendLadgerLTC"
                      >Отправить LTC</base-button
                    >
                  </b-col>
                </b-row> -->
              </base-card>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="6" v-else>
          <base-card v-if="data.length !== 0">
            <b-row align-h="center" class="text-center">
              <b-spinner variant="light"></b-spinner>
            </b-row>
          </base-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import BaseCalendar from "../components/calendar/BaseCalendar.vue";
import BaseCard from "../components/card/BaseCard.vue";
import { httpClient } from "../HttpClientV1/http-client";
import BaseInput from "../components/input/BaseInput.vue";
import BaseButton from "../components/button/BaseButton.vue";

export default {
  name: "Payments",
  components: {
    BaseCalendar,
    BaseCard,
    BaseInput,
    BaseButton,
  },
  data() {
    return {
      start: moment().startOf("isoWeek").format("DD.MM.YY"),
      end: moment().format("DD.MM.YY"),
      options: ["BTC", "LTC", "ETH"],
      selected: 1,
      data: [
        {
          id: 1,
          email: "Longmail89@domain.com",
          uuid: "INV-OK89NM",
          amount: 35.25,
        },
        {
          id: 2,
          email: "Longmail89@domain.com",
          uuid: "INV-OK89NM",
          amount: 35.25,
        },
        {
          id: 3,
          email: "Longmail89@domain.com",
          uuid: "INV-OK89NM",
          amount: 35.25,
        },
        {
          id: 4,
          email: "Longmail89@domain.com",
          uuid: "INV-OK89NM",
          amount: 35.25,
        },
        {
          id: 5,
          email: "Longmail89@domain.com",
          uuid: "INV-OK89NM",
          amount: 35.25,
        },
        {
          id: 6,
          email: "Longmail89@domain.com",
          uuid: "INV-OK89NM",
          amount: 35.25,
        },
        {
          id: 7,
          email: "Longmail89@domain.com",
          uuid: "INV-OK89NM",
          amount: 35.25,
        },
      ],
      openMerchantInfo: false,
      merchantEmail: "Longmail89@domain.com",
      loading: true,
      loading_data: true,
      arrowCalendar: false,
      merchant_id: null,
      amount_btc: 0,
      amount_ltc: 0,
      amount_eth: 0,
      amount_usdt: 0,
      balance_btc: 0,
      balance_ltc: 0,
      balance_eth: 0,
      balance_usdt: 0,
      data_table: [],
      telegram: null,
      data_table_project: [],
      address_usdt: null,
      address_eth: null,
      address_btc: null,
      address_ltc: null,
      disabled_btn: false,
    };
  },
  methods: {
    ...mapActions(["toggleCalendar"]),
    openCalendar() {
      this.toggleCalendar();
    },
    getUser(id) {
      httpClient
        .get("/v1/user/dashboard/project/detail?id=" + this.merchant_id)
        .then((resp) => {
          this.merchantEmail = resp.data.userdata.username;
          this.telegram = resp.data.userdata.telegram;
          // this.created_at = resp.data.userdata.created_at
          // this.ref_balance = resp.data.userdata.ref_balance
          // this.ref_code = resp.data.userdata.ref_code
          // this.ref_percent = resp.data.userdata.ref_percent
          this.data_table = resp.data.balance_wallet;
          for (var item of resp.data.balance_wallet) {
            console.log(item);
            if (item.type == "BTC") {
              this.balance_btc = item.balance;
            } else if (item.type == "LTC") {
              this.balance_ltc = item.balance;
            } else if (item.type == "ETH") {
              this.balance_eth = item.balance;
            } else if (item.type == "TRX-20") {
              this.balance_usdt = item.balance;
            }
          }
          this.loading = false;
        });
    },
    getUserProject() {
      httpClient
        .get("api/wallet/user_project?user_id=" + this.merchant_id)
        .then((response) => {
          this.data_table_project = response.data.project;
        });
    },
    sendLadgerUSDT() {
      this.disabled_btn = true;
      let data = {
        start: this.start,
        end: this.end,
        email: this.selected,
        address: this.address_usdt,
        amount: this.amount_usdt,
      };
      console.table(data);
      httpClient
        .post("/v1/ledger/dahsboard/transaction/usdt", data)
        .then((response) => {
          this.disabled_btn = false;
        });
    },
    sendLadgerETH() {
      this.disabled_btn = true;
      let data = {
        start: this.start,
        end: this.end,
        email: this.selected,
        address: this.address_eth,
        amount: this.amount_eth,
      };
      console.table(data);
      httpClient
        .post("/v1/ledger/dahsboard/transaction/eth", data)
        .then((response) => {
          this.disabled_btn = false;
        });
    },
    sendLadgerBTC() {
      this.disabled_btn = true;
      let data = {
        start: this.start,
        end: this.end,
        email: this.selected,
        address: this.address_btc,
        amount: this.amount_btc,
      };
      console.table(data);
      httpClient
        .post("/v1/ledger/dahsboard/transaction/btc", data)
        .then((response) => {
          this.disabled_btn = false;
        });
    },
    sendLadgerLTC() {
      this.disabled_btn = true;
      let data = {
        start: this.start,
        end: this.end,
        email: this.selected,
        address: this.address_ltc,
        amount: this.amount_ltc,
      };
      console.table(data);
      httpClient
        .post("/v1/ledger/dahsboard/transaction/ltc", data)
        .then((response) => {
          this.disabled_btn = false;
        });
    },
    loadLedgerMerchantList() {
      const data = {
        start: this.start,
        end: this.end,
      };
      httpClient
        .post("/v1/ledger/dahsboard/user/list", data)
        .then((response) => {
          this.data = response.data;
          // this.selected = response.data[0].email
          this.loading = false;
          this.loadLedgerMerchantOne();
        });
    },
    loadLedgerMerchantOne() {
      const data = {
        start: this.start,
        end: this.end,
        email: this.selected,
      };
      httpClient
        .post("/v1/ledger/dahsboard/user/detail", data)
        .then((response) => {
          this.merchant_id = response.data.id;
          this.amount_btc = response.data.amount_btc;
          this.amount_ltc = response.data.amount_ltc;
          this.amount_eth = response.data.amount_eth;
          this.amount_usdt = response.data.amount_usdt;
          this.loading_data = false;
        });
    },
    selectItem(id) {
      this.selected = id;
      this.loading_data = true;
      this.loadLedgerMerchantOne();
    },
    openMerchant() {
      this.openMerchantInfo = true;
      this.loading = true;
      this.getUser();
      this.getUserProject();
    },
    closeMerchant() {
      this.openMerchantInfo = false;
    },
    setDataFilter(data) {
      this.loading = true;
      this.start = data.start;
      this.end = data.end;
      this.loadLedgerMerchantList();
    },
  },
  mounted() {
    this.loadLedgerMerchantList();
  },
};
</script>
