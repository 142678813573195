<template>
  <div v-if="loading">
    <base-card>
      <b-row>
        <b-col cols="6"><h4>Менеджер тэгов</h4></b-col>
        <b-col cols="6" class="text-end">
          <img
            src="@/assets/img/icon/Solid/Interface/Cross.svg"
            alt=""
            class="pointer"
            @click="closeInfoTags()"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="auto">
          <base-select
            :options="all_tags"
            :selectedOptions="tags"
            v-model="tags"
            @change="addUserTag"
            label="Добавить тэги"
            :blur="false"
          ></base-select>
        </b-col>
      </b-row>
      <b-row class="mt-4">
        <b-col cols="auto">
          <h6>Текущие тэги пользователя</h6>
        </b-col>
      </b-row>
      <b-row class="mb-4">
        <b-col
          cols="auto"
          class="px-2"
          v-for="(item, index) in tags"
          :key="index"
        >
          <base-card class="p-1 px-3 context">
            <b-row align-h="between" align-v="center">
              <b-col cols="auto">{{ item.name }}</b-col>
              <b-col cols="auto" class="p-0">
                <img
                  class="pointer"
                  @click="addUserTag(item)"
                  src="@/assets/img/icon/Solid/Interface/Cross.svg"
              /></b-col> </b-row
          ></base-card>
        </b-col>
      </b-row>
    </base-card>
    <b-modal
      id="tags-modal"
      ref="tags-modal"
      title="Создать Тэг"
      scrollable
      hide-footer
    >
      <b-row class="mt-3" align-h="center">
        <b-col cols="12" class="px-0">
          <base-input
            placeholder="Название"
            v-model="nameTag"
            @input="generateCodeTag"
          ></base-input>
        </b-col>
        <b-col cols="12" class="px-0">
          <base-input
            placeholder="Код тэга"
            v-model="codeTag"
            readonly
          ></base-input>
        </b-col>
        <b-col cols="8">
          <base-button @click="addNewTag">Добавить</base-button>
        </b-col>
      </b-row>
    </b-modal>
  </div>
  <div v-else></div>
</template>
<script>
import eventBus from "../../EventBus";
import { httpClient } from "../../HttpClientV1/http-client";
import BaseButton from "../button/BaseButton.vue";
import BaseCard from "../card/BaseCard.vue";
import BaseInput from "../input/BaseInput.vue";
import BaseSelect from "../input/BaseSelect.vue";
import ModalInvoice from "../modal/ModalInvoice.vue";
export default {
  components: { BaseCard, BaseSelect, ModalInvoice, BaseInput, BaseButton },
  data() {
    return {
      key: 0,
      loading: true,
      all_tags: [],
      tags: [
        {
          name: "Новый пользователь",
          code: "new_user",
        },
        {
          name: "WooCommerce",
          code: "woo",
        },
      ],
      email: null,
      codeTag: null,
      nameTag: null,
    };
  },
  methods: {
    closeInfoTags() {
      eventBus.$emit("set_selected_component", "UserList");
    },
    generateCodeTag() {
      this.codeTag = window.URLify(this.nameTag);
    },
    addNewTag() {
      let tag = {
        name: this.nameTag,
        code: this.codeTag,
      };
      httpClient
        .post("v1/user/dashboard/user/tags/create", { tag })
        .then((response) => {
          this.all_tags = response.data;
          this.all_tags.push({
            id: this.all_tags.length + 1,
            name: "Создать новый",
            code: "add_new_tag",
          });
        });
    },
    getAllTags() {
      httpClient.post("v1/user/dashboard/user/tags/all").then((response) => {
        this.all_tags = response.data;
        this.all_tags.push({
          id: this.all_tags.length + 1,
          name: "Создать новый",
          code: "add_new_tag",
        });
      });
    },
    getAllUserTags() {
      httpClient
        .post("v1/user/dashboard/user/tags/user/all", { email: this.email })
        .then((response) => {
          this.tags = response.data;
          this.key += 1;
        });
    },
    addUserTag(item) {
      if (item.code == "add_new_tag") {
        this.$root.$emit("bv::show::modal", "tags-modal", "#tags-modal");
      } else {
        if (this.tags.includes(item)) {
          const findedOption = this.tags.find(
            (opt) => opt.code.toUpperCase() === item.code.toUpperCase()
          );
          let myIndex = this.tags.indexOf(findedOption);
          if (myIndex !== -1) {
            let data = {
              email: this.email,
              tag: item,
            };
            httpClient
              .post("v1/user/dashboard/user/tags/unbind", data)
              .then((response) => {
                this.getAllUserTags();
              });
          }
        } else {
          let data = {
            email: this.email,
            tag: item,
          };
          httpClient
            .post("v1/user/dashboard/user/tags/bind", data)
            .then((response) => {
              this.getAllUserTags();
            });
        }
      }
    },
  },
  mounted() {
    const RUSSIAN_MAP = {
      а: "a",
      б: "b",
      в: "v",
      г: "g",
      д: "d",
      е: "e",
      ё: "yo",
      ж: "zh",
      з: "z",
      и: "i",
      й: "j",
      к: "k",
      л: "l",
      м: "m",
      н: "n",
      о: "o",
      п: "p",
      р: "r",
      с: "s",
      т: "t",
      у: "u",
      ф: "f",
      х: "h",
      ц: "c",
      ч: "ch",
      ш: "sh",
      щ: "sh",
      ъ: "",
      ы: "y",
      ь: "",
      э: "e",
      ю: "yu",
      я: "ya",
      А: "A",
      Б: "B",
      В: "V",
      Г: "G",
      Д: "D",
      Е: "E",
      Ё: "Yo",
      Ж: "Zh",
      З: "Z",
      И: "I",
      Й: "J",
      К: "K",
      Л: "L",
      М: "M",
      Н: "N",
      О: "O",
      П: "P",
      Р: "R",
      С: "S",
      Т: "T",
      У: "U",
      Ф: "F",
      Х: "H",
      Ц: "C",
      Ч: "Ch",
      Ш: "Sh",
      Щ: "Sh",
      Ъ: "",
      Ы: "Y",
      Ь: "",
      Э: "E",
      Ю: "Yu",
      Я: "Ya",
    };
    const ALL_DOWNCODE_MAPS = [RUSSIAN_MAP];
    const Downcoder = {
      Initialize: function () {
        if (Downcoder.map) {
          // already made
          return;
        }
        Downcoder.map = {};
        for (const lookup of ALL_DOWNCODE_MAPS) {
          Object.assign(Downcoder.map, lookup);
        }
        Downcoder.regex = new RegExp(Object.keys(Downcoder.map).join("|"), "g");
      },
    };
    function downcode(slug) {
      Downcoder.Initialize();
      return slug.replace(Downcoder.regex, function (m) {
        return Downcoder.map[m];
      });
    }
    function URLify(s, num_chars, allowUnicode) {
      // changes, e.g., "Petty theft" to "petty-theft"
      if (!allowUnicode) {
        s = downcode(s);
      }
      s = s.toLowerCase(); // convert to lowercase
      // if downcode doesn't hit, the char will be stripped here
      if (allowUnicode) {
        // Keep Unicode letters including both lowercase and uppercase
        // characters, whitespace, and dash; remove other characters.
        s = XRegExp.replace(s, XRegExp("[^-_\\p{L}\\p{N}\\s]", "g"), "");
      } else {
        s = s.replace(/[^-\w\s]/g, ""); // remove unneeded chars
      }
      s = s.replace(/^\s+|\s+$/g, ""); // trim leading/trailing spaces
      s = s.replace(/[-\s]+/g, "-"); // convert spaces to hyphens
      s = s.substring(0, num_chars); // trim to first num_chars chars
      s = s.replace(/-+$/g, ""); // trim any trailing hyphens
      return s;
    }
    window.URLify = URLify;
    eventBus.$on("open_tag_manager", (data) => {
      this.email = data;
      this.getAllTags();
      this.getAllUserTags();
    });
  },
  beforeDestroy() {
    eventBus.$off("open_tag_manager");
  },
};
</script>
