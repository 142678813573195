<template>
  <div
    class="base__card text-left"
    :class="{ accnet: accent, selected: selected, white: white }"
  >
    <slot></slot>
  </div>
</template>
<script>
export default {
  inheritAttrs: false,
  name: "base-card",
  props: {
    icon: {
      type: String,
      description: "Icon",
    },
    subTitle: {
      type: String,
      description: "Card subtitle",
    },
    text: {
      type: String,
      description: "card text",
    },
    accent: {
      type: Boolean,
      default: false,
      description: "card accent",
    },
    white: {
      type: Boolean,
      default: false,
      description: "card white",
    },
    selected: {
      type: Boolean,
      default: false,
      description: "card accent",
    },
  },
};
</script>
