<template>
  <div class="content-block pe-0">
    <b-container>
      <b-row class="pt-4 pb-4 h-100" align-h="between" align-v="center">
        <b-col>
          <h1>Счета</h1>
        </b-col>
        <b-col cols="auto">
          <BaseCalendar
            class="h-48"
            :dateStart="start"
            :dateEnd="end"
            @submitFilterData="setDataFilter"
          >
          </BaseCalendar>
        </b-col>
        <b-col cols="4">
          <base-input
            class="pe-0"
            placeholder="Поиск..."
            v-model="query"
            @input="searchUser"
          ></base-input>
        </b-col>
      </b-row>

      <b-row class="mb-2 align-content-stretch">
        <b-col cols="auto"
          ><base-select
            label="Статус"
            :options="options_status"
            v-model="selectedStatus"
            :selectedOptions="selectedStatus"
            @change="ChangeSelectedItem"
          ></base-select>
        </b-col>
        <b-col cols="auto"
          ><base-select
            label="Тип"
            :options="options_type"
            v-model="selectedTypes"
            :selectedOptions="selectedTypes"
            @change="ChangeSelectedItemTypes"
          ></base-select>
        </b-col>
        <b-col cols="auto"
          ><base-select
            label="Валюта"
            :options="options_currency"
            v-model="selectedCurrency"
            :selectedOptions="selectedCurrency"
            @change="ChangeSelectedItemCurrency"
          ></base-select>
        </b-col>
        <b-col cols="auto" v-for="(item, index) in selectedStatus" :key="index"
          ><base-card class="border-0 accnet">
            <b-row align-h="between" align-v="center">
              <b-col cols="auto">{{ item.name }}</b-col>
              <b-col cols="auto" class="p-0">
                <img
                  class="pointer"
                  @click="ChangeSelectedItem(item)"
                  src="@/assets/img/icon/Solid/Interface/Cross.svg"
              /></b-col>
            </b-row>
          </base-card>
        </b-col>
        <b-col cols="auto" v-for="(item, index) in selectedTypes" :key="index"
          ><base-card class="border-0 accnet">
            <b-row align-h="between" align-v="center">
              <b-col cols="auto">{{ item.name }}</b-col>
              <b-col cols="auto" class="p-0">
                <img
                  class="pointer"
                  @click="ChangeSelectedItemTypes(item)"
                  src="@/assets/img/icon/Solid/Interface/Cross.svg"
              /></b-col>
            </b-row>
          </base-card>
        </b-col>
        <b-col
          cols="auto"
          v-for="(item, index) in selectedCurrency"
          :key="index"
          ><base-card class="border-0 accnet">
            <b-row align-h="between" align-v="center">
              <b-col cols="auto">{{ item.name }}</b-col>
              <b-col cols="auto" class="p-0">
                <img
                  class="pointer"
                  @click="ChangeSelectedItemCurrency(item)"
                  src="@/assets/img/icon/Solid/Interface/Cross.svg"
              /></b-col>
            </b-row>
          </base-card>
        </b-col>
      </b-row>
      <b-row class="mb-2 align-content-stretch h-100">
        <b-col cols="12" md="6" v-if="!loading">
          <b-row v-if="!openActionInvoice">
            <b-col
              cols="12"
              v-for="(item, index) in data"
              :key="index"
              @click="selectItem(item.id)"
              class="pointer"
            >
              <base-card
                :accent="selected == item.id"
                :selected="selected == item.id"
              >
                <b-row>
                  <b-col cols="2">
                    <p class="mb-0">{{ item.transaction }}</p>
                  </b-col>
                  <b-col cols="2">
                    <p class="mb-0">{{ item.status }}</p>
                    <p class="mb-0">{{ item.type }}</p>
                  </b-col>
                  <b-col cols="4" class="text-end">
                    <p class="mb-0">{{ item.amount }} {{ item.currency }}</p>
                    <p class="mb-0">{{ item.amount_usd }} USD</p>
                  </b-col>
                  <b-col cols="4" class="text-end">
                    <p class="mb-0">{{ item.project_uuid }}</p>
                    <p class="mb-0">
                      {{ dateFormate(item.created) }}
                    </p>
                  </b-col>
                </b-row>
              </base-card>
            </b-col>
            <b-col cols="12" v-if="data.length == 0">
              <base-card>
                <b-row align-h="center" class="text-center">
                  <p>Нет данных по запросу</p>
                </b-row>
              </base-card>
            </b-col>
          </b-row>
          <b-row v-else>
            <b-col cols="12">
              <base-card>
                <b-row>
                  <b-col cols="6" class="text-start pe-1 mb-3">
                    <h5>Детали счета</h5>
                  </b-col>
                  <b-col cols="6" class="text-end pe-1">
                    <img
                      class="pointer"
                      @click="openActionInvoice = false"
                      src="@/assets/img/icon/Solid/Interface/Cross.svg"
                    />
                  </b-col>
                </b-row>

                <b-row v-if="data_table_invoice.currency == 'USDT'">
                  <b-col cols="12">
                    <p>
                      Баланс TRX: {{ data_table_invoice.trxBalanceAddress }} TRX
                    </p>
                    <p>
                      Баланс USDT:
                      {{ data_table_invoice.usdtBalanceAddress }} USDT
                    </p>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12">
                    <b-row
                      class="mb-2"
                      v-if="data_table_invoice.status != 'paid'"
                    >
                      <b-col cols="auto">
                        <input
                          type="checkbox"
                          id="transit"
                          class="chkbx-toggle"
                          v-model="transit"
                          :disabled="disabled_transit"
                          @change="logging('transit')"
                        />
                        <label for="transit"> </label> </b-col
                      ><b-col cols="auto"
                        ><span>Транзитный перевод</span></b-col
                      >
                    </b-row>
                    <!-- <b-row class="mb-2">
                      <b-col cols="auto">
                        <input
                          type="checkbox"
                          id="notification"
                          class="chkbx-toggle"
                          value="1"
                          checked
                        />
                        <label for="notification"> </label> </b-col
                      ><b-col cols="auto"
                        ><span>Отправить уведомление</span></b-col
                      >
                    </b-row> -->
                    <b-row
                      class="mb-2"
                      v-if="data_table_invoice.status != 'paid'"
                    >
                      <b-col cols="auto">
                        <input
                          type="checkbox"
                          id="webhook"
                          class="chkbx-toggle"
                          v-model="webhook"
                          :disabled="disabled_webhook"
                          @change="logging('webhook')"
                        />
                        <label for="webhook"> </label> </b-col
                      ><b-col cols="auto"><span>Отправить Webhook</span></b-col>
                    </b-row>
                    <b-row
                      class="mb-2"
                      v-if="data_table_invoice.status != 'paid'"
                    >
                      <b-col cols="auto">
                        <input
                          type="checkbox"
                          id="balance"
                          class="chkbx-toggle"
                          v-model="balance"
                          :disabled="disabled_balance"
                          @change="logging('balance')"
                        />
                        <label for="balance"> </label> </b-col
                      ><b-col cols="auto"><span>Зачислить баланс</span></b-col>
                    </b-row>

                    <b-row
                      class="mb-2"
                      v-if="data_table_invoice.status != 'paid'"
                    >
                      <b-col cols="12">
                        <base-input
                          placeholder="Сумма"
                          class="px-0"
                          v-model="amount_invoice"
                        ></base-input
                      ></b-col>
                    </b-row>
                    <b-row
                      class="mb-2"
                      v-if="data_table_invoice.status != 'paid'"
                    >
                      <b-col cols="12">
                        <base-input
                          placeholder="TxHash"
                          class="px-0"
                          v-model="txId"
                        ></base-input
                      ></b-col>
                    </b-row>
                    <b-row class="mb-2">
                      <b-col
                        cols="4"
                        v-if="data_table_invoice.status != 'paid'"
                      >
                        <base-button
                          variant="primary"
                          class="px-0"
                          :disabled="disabled_btn"
                          @click="
                            confirmInvoice(data_table_invoice.transaction)
                          "
                          >Подтвердить</base-button
                        >
                      </b-col>
                      <b-col
                        cols="4"
                        v-if="data_table_invoice.status != 'paid'"
                      >
                        <base-button
                          variant="danger"
                          class="px-0"
                          :disabled="disabled_btn"
                          @click="
                            canceledInvoice(data_table_invoice.transaction)
                          "
                          >Отменить</base-button
                        >
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </base-card>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="12" md="6" v-else>
          <base-card>
            <b-row align-h="center" class="text-center">
              <b-spinner variant="light"></b-spinner>
            </b-row>
          </base-card>
        </b-col>
        <b-col cols="12" md="6" v-if="!loading_data">
          <b-row class="h-100">
            <b-col cols="12">
              <base-card style="position: sticky; top: 20px">
                <b-row>
                  <b-col>
                    <h6>
                      <a
                        v-if="data_table_invoice.type == 'up'"
                        :href="
                          'https://pay.cryptocloud.plus/' +
                          data_table_invoice.transaction.slice(4)
                        "
                        target="_blank"
                        >{{ data_table_invoice.transaction }}</a
                      >
                      <span v-else>{{ data_table_invoice.transaction }}</span>
                    </h6>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="6" md="4"> Тип счета </b-col>
                  <b-col cols="6" md="8">
                    <span v-if="data_table_invoice.type == 'up'"
                      >Поступление</span
                    >
                    <span v-else-if="data_table_invoice.type == 'dw'"
                      >Вывод</span
                    >
                    <span v-else-if="data_table_invoice.type == 'exchange_dw'"
                      >Обмен -</span
                    >
                    <span v-else-if="data_table_invoice.type == 'exchange_up'"
                      >Обмен +</span
                    >
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="6" md="4"> Статус </b-col>
                  <b-col cols="6" md="8">
                    <span v-if="data_table_invoice.status == 'created'"
                      >Создан</span
                    >
                    <span
                      v-else-if="data_table_invoice.status == 'paid'"
                      class="true"
                      >Оплачен</span
                    >
                    <span
                      v-else-if="data_table_invoice.status == 'partial'"
                      class="warning"
                      >Частично оплачн</span
                    >
                    <span
                      v-else-if="data_table_invoice.status == 'canceled'"
                      class="false"
                      >Отменен</span
                    >
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="6" md="4"> Валюта </b-col>
                  <b-col cols="6" md="8">
                    <span>{{ data_table_invoice.currency }}</span>
                  </b-col>
                </b-row>
                <b-row v-if="data_table_invoice.type == 'up'">
                  <b-col cols="6" md="4"> Проект </b-col>
                  <b-col cols="6" md="8">
                    <span
                      @click="openMerchant(data_table_invoice.project.id)"
                      >{{ data_table_invoice.project.name }}</span
                    >
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="6" md="4"> Мерчант </b-col>
                  <b-col cols="6" md="8">
                    <span @click="openMerchant(data_table_invoice.merchant)">{{
                      data_table_invoice.merchant
                    }}</span>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="6" md="4"> Адрес </b-col>
                  <b-col cols="6" md="8">
                    <span v-if="data_table_invoice.currency == 'BTC'"
                      ><a
                        target="_blank"
                        :href="
                          'https://blockchair.com/search?q=' +
                          data_table_invoice.address
                        "
                      >
                        {{ data_table_invoice.address }}
                      </a></span
                    >
                    <span v-else-if="data_table_invoice.currency == 'LTC'"
                      ><a
                        target="_blank"
                        :href="
                          'https://blockchair.com/search?q=' +
                          data_table_invoice.address
                        "
                      >
                        {{ data_table_invoice.address }}
                      </a></span
                    >
                    <span v-else-if="data_table_invoice.currency == 'ETH'"
                      ><a
                        target="_blank"
                        :href="
                          'https://etherscan.io/address/' +
                          data_table_invoice.address
                        "
                      >
                        {{ data_table_invoice.address }}
                      </a></span
                    >
                    <span v-else-if="data_table_invoice.currency == 'USDT'"
                      ><a
                        target="_blank"
                        :href="
                          'https://tronscan.org/#/address/' +
                          data_table_invoice.address
                        "
                      >
                        {{ data_table_invoice.address }}
                      </a></span
                    >
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="6" md="4"> Сумма счета </b-col>
                  <b-col cols="6" md="8">
                    <span
                      >{{ data_table_invoice.amount }}
                      {{ data_table_invoice.currency }}</span
                    >
                  </b-col>
                </b-row>

                <b-row>
                  <b-col cols="6" md="4"> Сумма счета USD </b-col>
                  <b-col cols="6" md="8">
                    <span>{{ data_table_invoice.amount_usd }}</span>
                  </b-col>
                </b-row>
                <b-row v-if="data_table_invoice.status == 'partial'">
                  <b-col cols="6" md="4"> Поступило </b-col>
                  <b-col cols="6" md="8">
                    <span class="warning"
                      >{{
                        parseFloat(data_table_invoice.amount) -
                        data_table_invoice.min_surcharge
                      }}
                      {{ data_table_invoice.currency }}</span
                    >
                  </b-col>
                </b-row>
                <b-row v-if="data_table_invoice.status == 'partial'">
                  <b-col cols="6" md="4"> К доплате </b-col>
                  <b-col cols="6" md="8">
                    <span class="warning"
                      >{{ data_table_invoice.min_surcharge.toFixed(6) }}
                      {{ data_table_invoice.currency }}</span
                    >
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="6" md="4"> Комиссия </b-col>
                  <b-col cols="6" md="8">
                    <span
                      >{{ data_table_invoice.commission }}
                      {{ data_table_invoice.currency }}</span
                    >
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="6" md="4"> Комиссия USD</b-col>
                  <b-col cols="6" md="8">
                    <span>{{ data_table_invoice.commission_usd }}</span>
                  </b-col>
                </b-row>
                <b-row v-if="data_table_invoice.type == 'up'">
                  <b-col cols="6" md="4">Трансферная комиссия</b-col>
                  <b-col cols="6" md="8">
                    <span>{{ data_table_invoice.transfer_commission }}</span>
                  </b-col>
                </b-row>
                <b-row v-if="data_table_invoice.type == 'up'">
                  <b-col cols="6" md="4">Переплата</b-col>
                  <b-col cols="6" md="8">
                    <span>{{ data_table_invoice.overpayment }}</span>
                  </b-col>
                </b-row>
                <b-row v-if="data_table_invoice.type == 'up'">
                  <b-col cols="6" md="4">Переплата USD</b-col>
                  <b-col cols="6" md="8">
                    <span>{{ data_table_invoice.overpayment_usd }}</span>
                  </b-col>
                </b-row>
                <hr v-if="data_table_invoice.type == 'up'" />

                <b-row v-if="data_table_invoice.type == 'up'">
                  <b-col cols="6" md="4">Номер заказа</b-col>
                  <b-col cols="6" md="8">
                    <span>{{ data_table_invoice.order_id }}</span>
                  </b-col>
                </b-row>
                <b-row v-if="data_table_invoice.type == 'up'">
                  <b-col cols="6" md="4">Почта покупателя</b-col>
                  <b-col cols="6" md="8">
                    <span>{{ data_table_invoice.customer_email }}</span>
                  </b-col>
                </b-row>

                <hr />
                <h6>TxHash</h6>
                <b-row
                  v-for="(item, index) in data_table_invoice.txIds"
                  :key="index"
                >
                  <b-col cols="6" md="4">TxHash</b-col>
                  <b-col cols="6" md="8">
                    <span class="text-truncate w-100">
                      <a
                        :href="
                          'https://blockchair.com/bitcoin/transaction/' +
                          item.txId
                        "
                        target="_blank"
                        v-if="data_table_invoice.currency == 'BTC'"
                      >
                        {{ item.txId.slice(0, 6) }} ...
                        {{
                          item.txId.slice(
                            item.txId.length - 7,
                            item.txId.length
                          )
                        }}
                      </a>
                      <a
                        :href="
                          'https://blockchair.com/litecoin/transaction/' +
                          item.txId
                        "
                        target="_blank"
                        v-if="data_table_invoice.currency == 'LTC'"
                      >
                        {{ item.txId.slice(0, 6) }} ...
                        {{
                          item.txId.slice(
                            item.txId.length - 7,
                            item.txId.length
                          )
                        }}
                      </a>
                      <a
                        :href="'https://etherscan.io/tx/' + item.txId"
                        target="_blank"
                        v-if="data_table_invoice.currency == 'ETH'"
                      >
                        {{ item.txId.slice(0, 6) }} ...
                        {{
                          item.txId.slice(
                            item.txId.length - 7,
                            item.txId.length
                          )
                        }}
                      </a>
                      <a
                        :href="
                          'https://tronscan.org/#/transaction/' + item.txId
                        "
                        target="_blank"
                        v-if="data_table_invoice.currency == 'USDT'"
                      >
                        {{ item.txId.slice(0, 6) }} ...
                        {{
                          item.txId.slice(
                            item.txId.length - 7,
                            item.txId.length
                          )
                        }}
                      </a>
                    </span>
                  </b-col>
                </b-row>
                <hr />
                <b-row v-if="data_table_invoice.type == 'dw'">
                  <b-col
                    cols="auto"
                    v-if="data_table_invoice.status == 'created'"
                  >
                    <base-button
                      class="px-0"
                      @click="confirmInvoiceOut(data_table_invoice.transaction)"
                      :disabled="disabled_btn"
                      >Вывести
                      {{ data_table_invoice.amount }}
                      {{ data_table_invoice.currency }}</base-button
                    >
                  </b-col>
                  <b-col cols="4" v-if="data_table_invoice.status == 'created'">
                    <base-button
                      variant="danger"
                      class="px-0"
                      :disabled="disabled_btn"
                      @click="canceledInvoice(data_table_invoice.transaction)"
                      >Отменить</base-button
                    >
                  </b-col>
                </b-row>
                <b-row v-if="data_table_invoice.type == 'up'">
                  <b-col
                    cols="auto"
                    v-if="data_table_invoice.status == 'partial'"
                  >
                    <base-button
                      variant="secondary"
                      class="px-0"
                      @click="
                        confirmInvoicePartial(data_table_invoice.transaction)
                      "
                      :disabled="disabled_btn"
                      >Подтвердить
                      {{
                        parseFloat(data_table_invoice.amount) -
                        data_table_invoice.min_surcharge
                      }}
                      {{ data_table_invoice.currency }}</base-button
                    >
                  </b-col>
                  <b-col cols="4">
                    <base-button
                      class="px-0"
                      variant="standart"
                      @click="openActionInvoice = true"
                      :disabled="disabled_btn"
                      >Подробнее
                    </base-button>
                  </b-col>
                </b-row>
                <!-- <b-row>
                  <b-col cols="12">
                    <b-row
                      v-for="(item, name, index) in data_table_invoice"
                      :key="index"
                    >
                      <b-col cols="6">
                        {{ name }}
                      </b-col>
                      <b-col cols="6">
                        {{ item }}
                      </b-col>
                    </b-row>
                  </b-col>
                  <hr />
                  <b-col cols="12">
                    <b-row
                      v-for="(item, index) in projects"
                      :key="index"
                      @click="openProject(item.id)"
                      class="pointer"
                    >
                      <b-col cols="6">
                        <p>{{ item.name }}</p>
                      </b-col>
                      <b-col cols="6" class="text-end">
                        <p class="mb-0">
                          {{ item.active }}
                        </p>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row> -->
              </base-card>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="12" md="6" v-else>
          <base-card v-if="data.length !== 0">
            <b-row align-h="center" class="text-center">
              <b-spinner variant="light"></b-spinner>
            </b-row>
          </base-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import BaseCalendar from "../components/calendar/BaseCalendar.vue";
import BaseCard from "../components/card/BaseCard.vue";
import { httpClient } from "../HttpClientV1/http-client";
import SearchButton from "../components/button/SearchButton.vue";
import BaseInput from "../components/input/BaseInput.vue";
import BaseSelect from "../components/input/BaseSelect.vue";
import BaseButton from "../components/button/BaseButton.vue";

var debounce = require("debounce");
export default {
  name: "Users",
  components: {
    BaseCalendar,
    BaseCard,
    SearchButton,
    BaseInput,
    BaseSelect,
    BaseButton,
  },
  data() {
    return {
      selectedStatus: [],
      selectedTypes: [],
      selectedCurrency: [],
      options_status: [
        {
          id: 0,
          selected: false,
          name: "Создан",
          code: "created",
        },
        {
          id: 1,
          selected: false,
          name: "Оплачен",
          code: "paid",
        },
        {
          id: 2,
          selected: false,
          name: "Частично оплачен",
          code: "partial",
        },
        {
          id: 3,
          selected: false,
          name: "Отменен",
          code: "canceled",
        },
      ],
      options_type: [
        {
          id: 0,
          selected: false,
          name: "Поступление",
          code: "up",
        },
        {
          id: 1,
          selected: false,
          name: "Выплата",
          code: "dw",
        },
        {
          id: 2,
          selected: false,
          name: "Обмен +",
          code: "exchange_up",
        },
        {
          id: 3,
          selected: false,
          name: "Обмен -",
          code: "exchange_dw",
        },
      ],
      options_currency: [
        {
          id: 0,
          selected: false,
          name: "BTC",
          code: "BTC",
        },
        {
          id: 1,
          selected: false,
          name: "LTC",
          code: "LTC",
        },
        {
          id: 2,
          selected: false,
          name: "ETH",
          code: "ETH",
        },
        {
          id: 3,
          selected: false,
          name: "USDT",
          code: "USDT",
        },
      ],
      start: moment().startOf("isoWeek").format("DD.MM.YY"),
      end: moment().format("DD.MM.YY"),
      options: ["BTC", "LTC", "ETH"],
      selected: 1,
      balances: null,
      projects: null,
      openProjectInfo: false,
      merchantEmail: "Longmail89@domain.com",
      loading: true,
      loading_data: true,
      arrowCalendar: false,
      merchant_id: null,
      amount_btc: 0,
      amount_ltc: 0,
      amount_eth: 0,
      amount_usdt: 0,
      balance_btc: 0,
      balance_ltc: 0,
      balance_eth: 0,
      balance_usdt: 0,
      data_table: [],
      telegram: null,
      data_table_project: [],
      data_table_invoice: [],
      query: null,
      disabled_btn: true,
      openActionInvoice: false,
      source: null,
      not_full_amount: false,
      balance: false,
      webhook: false,
      transit: false,
      disabled_not_full_amount: true,
      disabled_balance: false,
      disabled_webhook: false,
      disabled_transit: false,
      amount_invoice: 0,
      txId: "",
    };
  },
  methods: {
    ...mapActions(["toggleCalendar"]),
    openCalendar() {
      this.toggleCalendar();
    },
    logging(name) {
      console.log("[LOGGING]: {" + name + "}  - " + this[name]);
    },
    confirmInvoiceOut(invoice_id) {
      this.disabled_btn = true;
      httpClient
        .post("/v1/dashboard/invoice/confirm/payout", { uuid: invoice_id })
        .then((response) => {
          alert("Задача успешно создана, ждем");
        });
    },
    confirmInvoice(invoice_id) {
      this.disabled_btn = true;
      var data = {
        transit: this.transit,
        webhook: this.webhook,
        balance: this.balance,
        amount_invoice: this.amount_invoice,
        txId: this.txId,
        uuid: invoice_id,
      };
      httpClient
        .post("/v1/dashboard/invoice/confirm/send", data)
        .then((response) => {
          this.loadLedgerMerchantOne(this.selected);
          this.disabled_btn = false;
        });
    },
    canceledInvoice(invoice_id) {
      this.disabled_btn = true;
      var data = {
        uuid: invoice_id,
      };
      httpClient
        .post("/v1/dashboard/invoice/canceled", data)
        .then((response) => {
          this.loadLedgerMerchantOne(this.selected);
          this.disabled_btn = false;
        });
    },
    confirmInvoicePartial(invoice_id) {
      this.disabled_btn = true;
      httpClient
        .post("/v1/invoice/confirm/partial", { uuid: invoice_id })
        .then((response) => {
          this.loadLedgerMerchantOne(this.selected);
          this.disabled_btn = false;
          this.loadLedgerMerchantList();
        });
    },
    setSelectedStatus(item) {
      this.selectedStatus = [item];
      this.loading = true;
      this.loadLedgerMerchantList();
    },
    getUser(id) {
      httpClient
        .get("api/wallet/user_profile?user_id=" + this.merchant_id)
        .then((resp) => {
          this.merchantEmail = resp.data.userdata.username;
          this.telegram = resp.data.userdata.telegram;
          // this.created_at = resp.data.userdata.created_at
          // this.ref_balance = resp.data.userdata.ref_balance
          // this.ref_code = resp.data.userdata.ref_code
          // this.ref_percent = resp.data.userdata.ref_percent
          this.data_table = resp.data.balance_wallet;
          for (var item of resp.data.balance_wallet) {
            console.log(item);
            if (item.type == "BTC") {
              this.balance_btc = item.balance;
            } else if (item.type == "LTC") {
              this.balance_ltc = item.balance;
            } else if (item.type == "ETH") {
              this.balance_eth = item.balance;
            } else if (item.type == "TRX-20") {
              this.balance_usdt = item.balance;
            }
          }
          this.loading = false;
        });
    },
    dateFormate(date) {
      return moment(date).format("DD.MM.YY HH:mm:ss");
    },
    ChangeSelectedItem(item) {
      if (this.selectedStatus.includes(item)) {
        const findedOption = this.selectedStatus.find(
          (opt) => opt.code.toUpperCase() === item.code.toUpperCase()
        );
        let myIndex = this.selectedStatus.indexOf(findedOption);
        if (myIndex !== -1) {
          this.selectedStatus.splice(myIndex, 1);
        }
      } else {
        this.selectedStatus.push(item);
      }
      this.loading = true;
      this.loadLedgerMerchantList();
    },
    ChangeSelectedItemTypes(item) {
      if (this.selectedTypes.includes(item)) {
        const findedOption = this.selectedTypes.find(
          (opt) => opt.code.toUpperCase() === item.code.toUpperCase()
        );
        let myIndex = this.selectedTypes.indexOf(findedOption);
        if (myIndex !== -1) {
          this.selectedTypes.splice(myIndex, 1);
        }
      } else {
        this.selectedTypes.push(item);
      }
      this.loading = true;
      this.loadLedgerMerchantList();
    },
    ChangeSelectedItemCurrency(item) {
      if (this.selectedCurrency.includes(item)) {
        const findedOption = this.selectedCurrency.find(
          (opt) => opt.code.toUpperCase() === item.code.toUpperCase()
        );
        let myIndex = this.selectedCurrency.indexOf(findedOption);
        if (myIndex !== -1) {
          this.selectedCurrency.splice(myIndex, 1);
        }
      } else {
        this.selectedCurrency.push(item);
      }
      this.loading = true;
      this.loadLedgerMerchantList();
    },
    openProject(id) {
      this.loading = true;
      httpClient
        .get("/v1/dashboard/invocie/detail?id=" + id)
        .then((response) => {
          this.openProjectInfo = true;
          this.data_table_project = response.data;
          this.loading = false;
        });
    },
    loadLedgerMerchantList() {
      this.disabled_btn = false;
      let url = "";
      if ((this.query != "") & (this.query != null)) {
        url =
          "/v1/dashboard/invoice/list?start=" +
          this.start +
          "&end=" +
          this.end +
          "&q=" +
          this.query;
      } else {
        url =
          "/v1/dashboard/invoice/list?start=" + this.start + "&end=" + this.end;
      }
      let type = [];
      let status = [];
      let currency = [];

      for (let item of this.selectedTypes) {
        console.log("Item: " + item);
        type.push(item.code);
      }
      for (let item of this.selectedStatus) {
        console.log("Item: " + item);
        status.push(item.code);
      }
      for (let item of this.selectedCurrency) {
        console.log("Item: " + item);
        currency.push(item.code);
      }

      let filters = null;
      if (status.length > 0) {
        filters = {
          status: status,
        };
      } else {
        filters = null;
      }
      if (type.length > 0) {
        if (filters === null) {
          filters = {
            type: type,
          };
        } else {
          filters["type"] = type;
        }
      } else {
        if (filters === null) {
          filters = null;
        }
      }
      if (currency.length > 0) {
        if (filters === null) {
          filters = {
            currency__code: currency,
          };
        } else {
          filters["currency__code"] = currency;
        }
      } else {
        if (filters === null) {
          filters = null;
        }
      }
      httpClient.post(url, { filters }).then((response) => {
        this.data = response.data;
        this.loading = false;
        this.selected = this.data[0].id;
        this.loadLedgerMerchantOne(this.data[0].id);
      });
    },
    loadLedgerMerchantOne(id) {
      this.loading_data = true;
      httpClient
        .post("/v1/dashboard/invoice/detail?id=" + id)
        .then((response) => {
          this.data_table_invoice = response.data;
          this.loading_data = false;
          this.disabled_btn = false;
          if (this.data_table_invoice.status != "paid") {
            // this.loadLedgerMerchantOneEvent(id);
          }
        });
    },
    loadLedgerMerchantOneEvent(id) {
      if (this.source == null) {
        this.source = new EventSource(
          "https://api.cryptocloud.plus/v1/dashboard/invoice/detail/envet?id=" +
            id
        );
        this.source.onmessage = function (event) {
          try {
            var jsondata = JSON.parse(event.data);
            console.log(jsondata);
            this.data_table_invoice = jsondata.data;
          } catch (e) {
            console.log("INVOICE_INFO EVENT ERROR", e);
          }
        };
      }
      this.loading_data = true;
      httpClient
        .post("/v1/dashboard/invoice/detail?id=" + id)
        .then((response) => {
          this.data_table_invoice = response.data;
          this.loading_data = false;
          this.disabled_btn = false;
        });
    },
    selectItem(id) {
      this.selected = id;
      this.loading_data = true;
      this.loadLedgerMerchantOne(id);
    },
    openMerchant() {
      this.openMerchantInfo = true;
      this.loading = true;
      this.getUser();
      this.getUserProject();
    },
    closeMerchant() {
      this.openProjectInfo = false;
    },
    setDataFilter(data) {
      this.loading = true;
      this.start = data.start;
      this.end = data.end;
      this.loadLedgerMerchantList();
      this.loadStatistics();
    },
    searchUser: debounce(function () {
      this.loading = true;
      this.loadLedgerMerchantList();
    }, 500),

    async loadStatistics() {
      this.loading_stats = false;
      httpClient
        .get(
          "/v1/dashboard/invoice/statistics/date?start=" +
            this.start +
            "&end=" +
            this.end
        )
        .then((response) => {
          this.data_statistics = response.data;
          this.loading_stats = true;
        });
    },
  },
  mounted() {
    console.log(this.$route.query.q);
    if (this.$route.query.q) {
      this.query = this.$route.query.q;
    }
    this.loadLedgerMerchantList();
    this.loadStatistics();
  },
  beforeDestroy() {
    this.$off("search_invoice");
  },
};
</script>
