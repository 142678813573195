<template>
  <div class="content-block pe-0">
    <b-container>
      <div class="notification-wrapper">
        <span class="notification-item show" v-for="(item, index) in notification" :key="index" :class="item.type">{{
          item.text }}</span>
      </div>
      <b-row class="pt-4 h-100">
        <b-col>
          <h4>{{ article.title }}</h4>
        </b-col>
      </b-row>
      <b-row class="mb-2 align-content-stretch h-100">
        <b-col cols="12">
          <b-row align-h="center">
            <b-col cols="12" class="tools">
              <base-card class="border-0">
                <b-row class="">
                  <b-col cols="auto">
                    <dropdown-menu label="Текст" class="w-100">
                      <base-card class="context border-0 p-0 fit-content">
                        <div class="item text-center" @mousedown.prevent="execCommand('bold')">
                          Жирный
                        </div>
                        <div class="item text-center" @mousedown.prevent="execCommand('italic')">
                          Наклонный
                        </div>
                        <div class="item text-center" @mousedown.prevent="execCommand('underline')">
                          Подчеркнутый
                        </div>
                        <div class="item text-center" @mousedown.prevent="
          execCommand('quote', 'quote3-purple')
          ">
                          Цитата пурпурная
                        </div>
                        <div class="item text-center" @mousedown.prevent="
          execCommand('quote', 'quote3-green')
          ">
                          Цитата зеленая
                        </div>
                        <!-- <div
                          class="item text-center"
                          @mousedown.prevent="
                            execCommand('quote', 'quote3-blue')
                          "
                        >
                          Цитата синяя
                        </div>
                        <div
                          class="item text-center"
                          @mousedown.prevent="
                            execCommand('quote', 'quote3-gray')
                          "
                        >
                          Цитата серая
                        </div>
                        <div
                          class="item text-center"
                          @mousedown.prevent="execCommand('quote', 'quote2')"
                        >
                          Цитата обычная
                        </div>
                        <div
                          class="item text-center"
                          @mousedown.prevent="execCommand('quote', 'quote1')"
                        >
                          Цитата Не обычная
                        </div> -->
                      </base-card>
                    </dropdown-menu>
                  </b-col>
                  <b-col cols="auto">
                    <dropdown-menu label="Заголовок" class="w-100">
                      <base-card class="context border-0 p-0 fit-content">
                        <!-- <div
                          class="item text-center"
                          @mousedown.prevent="execCommand('formatBlock', 'h1')"
                        >
                          H1
                        </div> -->
                        <div class="item text-center" @mousedown.prevent="execCommand('formatBlock', 'h2')">
                          H2
                        </div>
                        <div class="item text-center" @mousedown.prevent="execCommand('formatBlock', 'h3')">
                          H3
                        </div>
                        <div class="item text-center" @mousedown.prevent="execCommand('formatBlock', 'h4')">
                          H4
                        </div>
                        <div class="item text-center" @mousedown.prevent="execCommand('formatBlock', 'p')">
                          Обычный
                        </div>
                      </base-card>
                    </dropdown-menu>
                  </b-col>
                  <b-col cols="auto">
                    <dropdown-menu label="Список">
                      <base-card class="context border-0 p-0 fit-content">
                        <div class="item text-center w-100" @mousedown.prevent="
          execCommand('insertUnorderedList')
          ">
                          Обычный
                        </div>
                        <div class="item text-center w-100" @mousedown.prevent="execCommand('insertOrderedList')">
                          Нумеровный
                        </div>
                      </base-card>
                    </dropdown-menu>
                  </b-col>
                  <b-col cols="auto">
                    <dropdown-menu label="Медиа">
                      <base-card class="context border-0 p-0 fit-content">
                        <div class="item text-center w-100" @mousedown.prevent="
          execCommand('createLink', 'Новая ссылка')
          ">
                          Ссылка
                        </div>
                        <div class="item text-center w-100" @mousedown.prevent="openImgEditer">
                          Изображение
                        </div>
                        <!-- <div
                          class="item text-center w-100"
                          @mousedown.prevent="openVideoEditer"
                        >
                          Видео
                        </div> -->
                      </base-card>
                    </dropdown-menu>
                  </b-col>
                  <b-col cols="auto">
                    <dropdown-menu label="Промо">
                      <base-card class="context border-0 p-0 fit-content">
                        <div v-for="item in promoList" :key="item.id" class="item text-center w-100" @mousedown.prevent="
          execCommand('promo', item.id, item.title)
          ">
                          {{ item.title }}
                        </div>

                      </base-card>
                    </dropdown-menu>
                  </b-col>
                  <b-col cols="auto">
                    <dropdown-menu label="Промо(еще)">
                      <base-card class="context border-0 p-0 fit-content">
                        <div v-for="item in minipromolist" :key="item.id" class="item text-center w-100"
                          @mousedown.prevent="
          execCommand('promo', item.id, item.title)
          ">
                          {{ item.title }}
                        </div>

                      </base-card>
                    </dropdown-menu>
                  </b-col>
                  <b-col cols="auto">
                    <base-card class="context pointer">
                      <div class="item text-center w-100" @mousedown.prevent="execCommand('removeFormat')">
                        Очистить
                      </div>
                    </base-card>
                  </b-col>
                  <b-col cols="auto">
                    <base-card class="context pointer">
                      <div class="item text-center w-100" @mousedown.prevent="getPreview">
                        Посмотреть
                      </div>
                    </base-card>
                  </b-col>
                  <b-col cols="auto" class="text-end">
                    <base-button @click="openSettingArticle" class="px-0 my-0"
                      variant="secondary">Настройки</base-button></b-col>
                  <b-col cols="auto" class="text-end">
                    <base-button @click="saveArticle" class="px-0 my-0">Сохранить</base-button></b-col>
                </b-row>
              </base-card>
            </b-col>
            <b-col cols="12" class="mb-4" style="z-index: 1">
              <base-card class="p-0 h-100" white>
                <b-row align-v="stretch">
                  <b-col cols="8" class="text-left"> </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <base-card white style="height: 78vh; overflow: scroll">
                      <b-row>
                        <b-col cols="8" class="article-content">
                          <HTMLEditer :data="content" v-model="content" ref="articleContent"></HTMLEditer>
                        </b-col>
                        <b-col cols="4">
                          <base-card style="position: sticky; top: 0px">
                            <b-row>
                              <b-col cols="12">
                                <h6>Содержание</h6>
                              </b-col>
                              <b-col cols="12" v-if="article_content.length > 0">
                                <ul>
                                  <li v-for="(item, index) of article_content" :key="index">
                                    <a :href="'#' + item.hash">{{
          item.title
        }}</a>
                                    <ul v-if="item.sub_content.length > 0">
                                      <li v-for="(
                                          item2, index2
                                        ) of item.sub_content" :key="index2">
                                        <a :href="'#' + item2.hash">{{
          item2.title
        }}</a>
                                      </li>
                                    </ul>
                                  </li>
                                </ul>
                              </b-col>
                            </b-row>
                          </base-card>
                        </b-col>
                      </b-row>
                    </base-card>
                  </b-col>
                </b-row>
              </base-card>
            </b-col>
            <!-- <b-col cols="8">
              <base-card white>
                <quill-editor
                  class="article-content"
                  ref="articleContent"
                  v-model="article.content"
                  :options="optionsEditer"
                ></quill-editor>
              </base-card>
            </b-col> -->
          </b-row>
        </b-col>
        <!-- <b-col cols="6" v-else>
          <base-card>
            <b-row align-h="center" class="text-center">
              <b-spinner variant="light"></b-spinner>
            </b-row>
          </base-card>
        </b-col> -->
      </b-row>
      <b-modal id="link-modal" ref="link-modal" title="Редактировать ссылку" scrollable hide-footer>
        <b-row class="mt-3" align-h="center">
          <b-col cols="12" class="px-0">
            <base-input placeholder="Название ссылки" v-model="target_link_text"></base-input>
          </b-col>
          <b-col cols="12" class="px-0">
            <base-input placeholder="Cсылка" v-model="target_link_url"></base-input>
          </b-col>
          <!-- <b-col cols="12" class="px-0">
            <base-input
              placeholder="укажи nofollow и noreferrer если не надо отслеживать переход"
              v-model="target_link_rel"
            ></base-input>
          </b-col> -->
          <b-col cols="12" class="">
            <div class="form-control px-0">
              <b-form-select v-model="target_link_rel" :options="[
          null,
          'nofollow',
          'nofollow noreferrer',
          'noreferrer',
        ]" class="base__input"></b-form-select>
            </div>
          </b-col>
          <!-- <b-col cols="12" class="px-0">
            <base-input
              placeholder="Как открыть? _blank - в новом окне, оставь пусто если в том же окне"
              v-model="target_link_target"
            ></base-input>
          </b-col> -->
          <b-col cols="12" class="">
            <div class="form-control px-0">
              <b-form-select v-model="target_link_target" :options="options_target" class="base__input"></b-form-select>
            </div>
          </b-col>
          <b-col cols="8" class="px-0">
            <base-button @click="saveTargetLink">Сохранить</base-button>
          </b-col>
        </b-row>
      </b-modal>
      <b-modal id="img-modal" ref="img-modal" title="Редактировать изорбажение" scrollable hide-footer>
        <b-row class="mt-3" align-h="center">
          <b-col cols="12">
            <base-card v-show="load_img" :white="true">
              <b-row align-h="center">
                <b-col cols="6" class="text-center">
                  <img src="@/assets/img/loader.svg" alt="" width="50%" class="rotate" />
                </b-col>
              </b-row>
            </base-card>
            <img v-show="!load_img" src="@/assets/img/upload.webp" alt="" id="image_drop_area" width="100%" />
          </b-col>
          <b-col cols="12">
            <b-form-file class="mt-4 px-3" v-model="file1" plain @input="uploadCover"></b-form-file>
          </b-col>
          <b-col cols="12" class="px-0" v-if="editImg">
            <base-input placeholder="ALT TAG" v-model="alt_tag_new_img"></base-input>
          </b-col>
          <b-col cols="8" class="px-0">
            <base-button @click="insertImage" v-if="!editImg" :disabled="load_img">Добавить</base-button>
            <base-button @click="saveImgEditer" v-if="editImg" :disabled="load_img">Сохранить</base-button>
          </b-col>
        </b-row>
      </b-modal>
      <b-modal id="video-modal" ref="video-modal" title="Редактировать видео" hide-footer>
        <b-row class="mt-3" align-h="center">
          <b-col cols="12">
            <iframe :src="video_link" alt="" id="video_drop_area" width="100%"></iframe>
          </b-col>
          <b-col cols="12" class="px-0">
            <base-input placeholder="Ссылка на видео" v-model="video_link"></base-input>
          </b-col>
          <b-col cols="8" class="px-0">
            <base-button @click="insertVideo" v-if="!editImg">Добавить</base-button>
            <base-button @click="saveImgEditer" v-if="editImg">Сохранить</base-button>
          </b-col>
        </b-row>
      </b-modal>
    </b-container>
  </div>
</template>

<script>
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import BaseCalendar from "../components/calendar/BaseCalendar.vue";
import BaseCard from "../components/card/BaseCard.vue";
import { httpClient } from "../HttpClientV1/http-client";
import BaseInput from "../components/input/BaseInput.vue";
import BaseButton from "../components/button/BaseButton.vue";
import HTMLEditer from "../components/editer/HTMLEditer.vue";
import DropdownMenu from "../components/editer/DropdownMenu.vue";

import { quillEditor, Quill } from "vue-quill-editor";
import { container, ImageExtend, QuillWatch } from "quill-image-extend-module";
import eventBus from "../EventBus";
import BaseSelect from "../components/input/BaseSelect.vue";
Quill.register("modules/ImageExtend", ImageExtend);
export default {
  name: "Payments",
  components: {
    BaseCalendar,
    BaseCard,
    BaseInput,
    BaseButton,
    HTMLEditer,
    DropdownMenu,
    quillEditor,
    BaseSelect,
  },
  data() {
    return {
      start: moment().startOf("isoWeek").format("DD.MM.YY"),
      end: moment().format("DD.MM.YY"),
      options: ["BTC", "LTC", "ETH"],
      selected: 1,
      openMerchantInfo: false,
      merchantEmail: "Longmail89@domain.com",
      loading: true,
      loading_data: true,
      arrowCalendar: false,
      merchant_id: null,
      editImg: false,
      amount_btc: 0,
      amount_ltc: 0,
      amount_eth: 0,
      amount_usdt: 0,
      balance_btc: 0,
      balance_ltc: 0,
      balance_eth: 0,
      balance_usdt: 0,
      data_table: [],
      telegram: null,
      data_table_project: [],
      address_usdt: null,
      address_eth: null,
      address_btc: null,
      address_ltc: null,
      disabled_btn: false,
      article: null,
      target_link: null,
      target_link_text: null,
      target_link_url: null,
      target_link_rel: null,
      target_link_target: null,
      tipEl: null,
      article_content: [],
      notification: [],
      selectedPosition: null,
      alt_tag_new_img: null,
      file1: null,
      video_link: null,
      content: null,
      tipWidth: null,
      config_tooltip: {
        scope: "body",
        minLength: 1,
        maxLength: Infinity,
        iconFormat: "",
        buttons: [],
        theme: "default",
        mobileOSBehaviour: "hide",
      },
      options_target: [
        {
          text: "В той же вкладке",
          value: "_self",
        },
        {
          text: "В новой вкладке",
          value: "_blank",
        },
      ],
      selectedTarget: "_self",
      selectedRef: "None",
      load_img: false,
      promoList: [
        {
          id: "guide-reg_company",
          title: "Лид-магнит Компании",
        },

        {
          id: "guide-payments",
          title: "Лид-магнит Платежки",
        },
        {
          id: "consult-reg_company",
          title: "Промо Компании",
        },
        {
          id: "consult-payments",
          title: "Промо Платежки",
        },
        {
          id: "consult-invoices",
          title: "Промо Онлайн-банки",
        },
        {
          id: "consult-cards",
          title: "Промо Карты",
        },
        {
          id: "consult-marketplaces",
          title: "Промо Маркетплейсы",
        },
      ],
      minipromolist: [

        {
          id: "reg-company",
          title: "Мини Промо Компании",
        },
        {
          id: "payments",
          title: "Мини Промо Платежки",
        },
        {
          id: "invoices",
          title: "Мини Промо Онлайн-банки",
        },
        {
          id: "cards",
          title: "Мини Промо Карты",
        },
        {
          id: "marketplaces",
          title: "Мини Промо Маркетплейсы",
        },
        {
          id: "reg-company_uk",
          title: "Мини Промо Великобритания",
        },
        {
          id: "reg-company_usa",
          title: "Мини Промо США",
        },
        {
          id: "reg-company_cyprus",
          title: "Мини Промо Кипр",
        },
        {
          id: "reg-company_uae",
          title: "Мини Промо ОАЭ",
        },
        {
          id: "reg-company_hk",
          title: "Мини Промо Гонконг",
        },
      ]
    };
  },
  methods: {
    ...mapActions(["toggleCalendar"]),
    ChangeSelectedTargetItem(item) {
      this.selectedTarget = item;

      // this.loading = true;
      // this.loadLedgerMerchantList();
    },
    uploadCover() {
      this.load_img = true;
      let formData = new FormData();
      formData.append("file", this.file1);
      httpClient
        .post("/api/blog/dashboard/article/upload", formData)
        .then((response) => {
          document
            .querySelector("#image_drop_area")
            .setAttribute(
              "src",
              "https://easypayments.online/media/" + response.data
            );
          this.cover = response.data;

          this.load_img = false;
        });
    },
    getPreview() {
      this.saveArticle();
      let base_url;
      if (window.location.host == "localhost:8080") {
        base_url = "http://localhost:3000";
      } else {
        base_url = "https://easypayments.online";
      }
      try {
        window.open(base_url + "/blog/" + this.article.slug, "_blank").focus();
      } catch (e) {
        console.log("error", e);
      }
    },
    openSettingArticle() {
      this.saveArticle();
      this.$router.push("/content/articles/" + this.$route.params.id + "/edit");
    },
    saveArticle() {
      this.generateContentArticle();
      let article = {
        id: parseInt(this.$route.params.id),
        content: this.$refs.articleContent.$el.innerHTML,
      };
      let data = {
        content: this.article_content,
        id: this.$route.params.id,
      };
      httpClient
        .post("api/blog/dashboard/article/update", { article })
        .then((response) => {
          this.notification.push({
            text: "Сохранено",
            type: "success",
          });
          let self = this;
          setTimeout(() => {
            self.notification = [];
          }, 2000);
        });
      httpClient.post("api/blog/dashboard/article/upload/content", data);
    },
    execCommand: function (commandName, valueArgument, title = "") {
      if (!valueArgument) {
        valueArgument = null;
      }
      if (commandName == "quote") {
        const selection = window.getSelection();
        let new_element = "";
        console.log(selection);
        if (valueArgument == "quote1") {
          document.execCommand(
            "insertHTML",
            false,
            `<div class="${valueArgument}"><p>${selection.anchorNode.parentElement.parentElement.innerHTML}</p><img
                class="img-quote"
                src="https://api.cryptocloud.plus/media/quote.png"
                alt=""
              /></div><br><p></p>`
          );
        } else {
          document.execCommand(
            "insertHTML",
            false,
            `<div class="${valueArgument}"><p>${selection.anchorNode.parentElement.innerHTML}</p></div><br>`
          );
        }
      } else if (commandName == "promo") {
        document.execCommand(
          "insertHTML",
          false,
          '<div data-promo="' +
          valueArgument +
          '" promoblock draggable="true"> ' +
          title +
          "</div><br><p></p>"
        );
      } else if (commandName == "createLink") {
        document.execCommand(commandName, true, valueArgument);
        const event = new MouseEvent("dblclick", {
          bubbles: true,
          cancelable: true,
          view: window,
        });
        window.getSelection().anchorNode.parentElement.dispatchEvent(event);
      } else {
        document.execCommand(commandName, true, valueArgument);
      }

      this.$refs.articleContent.parseHtml();
    },
    insertImage() {
      let buff_img = document
        .querySelector("#image_drop_area")
        .getAttribute("src");
      console.log(buff_img);
      document.querySelector("[data-focus=true]").focus();
      let self = this;
      setTimeout(() => {
        self.execCommand("insertImage", buff_img);
        // let new_img = document.querySelectorAll("img[src=" + buff_img + "");
        // new_img.setAttribute("alt", self.alt_tag_new_img);
      }, 200);
      this.$root.$emit("bv::hide::modal", "img-modal");
    },
    insertVideo() {
      let buff_img = document
        .querySelector("#video_drop_area")
        .getAttribute("src");
      let self = this;

      document.querySelector("[data-focus=true]").focus();
      setTimeout(() => {
        self.execCommand(
          "insertHTML",
          "<iframe src='" +
          this.video_link +
          "' width='100%' hieght='350px'></iframe>"
        );
        // let new_img = document.querySelectorAll("img[src=" + buff_img + "");
        // new_img.setAttribute("alt", self.alt_tag_new_img);
      }, 200);
      this.$root.$emit("bv::hide::modal", "video-modal");
    },
    saveImgEditer() {
      let buff_img = document
        .querySelector("#image_drop_area")
        .getAttribute("old-src");
      let buff_img_2 = document
        .querySelector("#image_drop_area")
        .getAttribute("src");
      let new_img = document.querySelector("img[src='" + buff_img + "']");
      new_img.setAttribute("alt", this.alt_tag_new_img);
      new_img.setAttribute("src", buff_img_2);
      this.alt_tag_new_img = null;
      this.$root.$emit("bv::hide::modal", "img-modal", "#img-modal");
    },
    openCalendar() {
      this.toggleCalendar();
    },
    generateContentArticle() {
      this.article_content = [];
      this.$refs.articleContent.generateContent();
    },
    loadArticleDetail() {
      httpClient
        .get("/api/blog/dashboard/article/detail?id=" + this.$route.params.id)
        .then((response) => {
          this.article = response.data;
          this.content = this.article.content;
          this.loading = false;
          let self = this;
          setTimeout(() => {
            self.generateContentArticle();
          }, 200);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    setDataFilter(data) {
      this.loading = true;
      this.start = data.start;
      this.end = data.end;
    },
    generatePopupTools(event) {
      console.log(event);
      if (event.target.tagName === "A") {
        this.target_link = event.target;
        if (this.target_link.innerText !== "Новая ссылка") {
          this.target_link_text = this.target_link.innerText;
          this.target_link_url = this.target_link.getAttribute("href");
        } else {
          this.target_link_text = "";
          this.target_link_url = "";
        }
        this.target_link_rel = this.target_link.getAttribute("rel");
        if (this.target_link.getAttribute("target") == null) {
          this.target_link_target = "_self";
        } else {
          this.target_link_target = this.target_link.getAttribute("target");
        }
        this.$root.$emit("bv::show::modal", "link-modal", "#link-modal");
      }

      if (event.target.tagName === "IMG") {
        this.$root.$emit("bv::show::modal", "img-modal", "#img-modal");
        setTimeout(() => {
          document
            .querySelector("#image_drop_area")
            .setAttribute("src", event.target.getAttribute("src"));
          document
            .querySelector("#image_drop_area")
            .setAttribute("old-src", event.target.getAttribute("src"));
        }, 200);
        this.alt_tag_new_img = event.target.getAttribute("alt");
        this.editImg = true;
      }
    },
    openImgEditer() {
      this.selectedPosition = window.getSelection().anchorNode.parentElement;
      console.log(this.selectedPosition);
      this.selectedPosition.setAttribute("data-focus", true);
      this.$root.$emit("bv::show::modal", "img-modal", "#img-modal");
    },
    openVideoEditer() {
      this.selectedPosition = window.getSelection().anchorNode.parentElement;
      console.log(this.selectedPosition);
      this.selectedPosition.setAttribute("data-focus", true);
      this.$root.$emit("bv::show::modal", "video-modal", "#video-modal");
    },
    saveTargetLink() {
      if (this.target_link.tagName === "A") {
        this.target_link.setAttribute("target", this.target_link_target);
        if (this.target_link_rel !== null) {
          this.target_link.setAttribute("rel", this.target_link_rel);
        } else {
          this.target_link.removeAttribute("rel");
        }
        this.target_link.setAttribute("href", this.target_link_url);
        this.target_link.innerText = this.target_link_text;
        this.$root.$emit("bv::hide::modal", "link-modal");
      } else {
        let new_element = document.createElement("a");
        new_element.setAttribute("target", this.target_link_target);
        new_element.setAttribute("rel", this.target_link_rel);
        new_element.setAttribute("href", this.target_link_url);
        new_element.innerText = this.target_link_text;
        this.target_link.parentNode.replaceChild(new_element, this.target_link);
      }
    },
  },
  mounted() {
    setTimeout(() => {
      const imgs = document.querySelector("img#image_drop_area");
      imgs.addEventListener("dragover", (event) => {
        event.stopPropagation();
        event.preventDefault();
      });
    }, 200);
    eventBus.$on("generateTagHash", (data) => {
      this.article_content.push(data);
    });
    this.$root.$on("bv::modal::hide", (event) => {
      this.editImg = false;
    });
    this.$root.$on("bv::modal::show", (event) => {
      setTimeout(() => {
        const imgs = document.querySelector("img#image_drop_area");
        console.log("IMAGE TAG FOUND");
        imgs.addEventListener("dragover", (event) => {
          console.log("dragover");
          event.stopPropagation();
          event.preventDefault();
        });
        imgs.addEventListener("drop", (event) => {
          console.log("drop");
          self.load_img = true;
          event.stopPropagation();
          event.preventDefault();
          const fileList = event.dataTransfer.files;
          let formData = new FormData();
          self.file1 = fileList[0];
          formData.append("file", fileList[0]);
          httpClient
            .post("/api/blog/dashboard/article/upload", formData)
            .then((response) => {
              document
                .querySelector("#image_drop_area")
                .setAttribute(
                  "src",
                  "https://easypayments.online/media/" + response.data
                );

              self.load_img = false;
            });
        });
      }, 200);
    });
    this.loadArticleDetail();
    let self = this;
    setTimeout(() => {
      const card = document.querySelector(".article-content");
      card.addEventListener("dblclick", (e) => {
        e.preventDefault();
        self.generatePopupTools(e);
      });
    }, 2000);
  },
  beforeDestroy() {
    eventBus.$off("generateTagHash");
  },
};
</script>
