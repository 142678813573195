<template>
  <div class="content-block pe-0">
    <b-container>
      <b-row class="pt-4 pb-4" align-h="between">
        <b-col>
          <h1>Пользователи</h1>
        </b-col>
        <b-col cols="4">
          <base-input
            class="pe-0"
            placeholder="Поиск..."
            v-model="query"
            @input="searchUser"
          ></base-input>
        </b-col>
      </b-row>
      <b-row class="mb-2 align-content-stretch h-100">
        <b-col cols="6">
          <keep-alive>
            <component :is="component_list[selected_component]"></component>
          </keep-alive>
        </b-col>
        <b-col cols="6">
          <keep-alive>
            <component :is="component_list['UserProfile']"></component>
          </keep-alive>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import BaseCalendar from "../components/calendar/BaseCalendar.vue";
import BaseCard from "../components/card/BaseCard.vue";
import { httpClient } from "../HttpClientV1/http-client";
import SearchButton from "../components/button/SearchButton.vue";
import BaseInput from "../components/input/BaseInput.vue";
import BaseButton from "../components/button/BaseButton.vue";
import ProjectDetail from "../components/carddata/ProjectDetail.vue";
import UserProfile from "../components/dynamicModules/UserProfile.vue";
import eventBus from "../EventBus";
import UserList from "../components/dynamicModules/UserList.vue";
import UserTagManager from "../components/dynamicModules/UserTagManager.vue";

var debounce = require("debounce");
export default {
  name: "Users",
  components: {
    BaseCalendar,
    BaseCard,
    SearchButton,
    BaseInput,
    BaseButton,
    ProjectDetail,
    UserList,
    UserTagManager,
  },
  data() {
    return {
      start: moment().startOf("isoWeek").format("DD.MM.YY"),
      end: moment().format("DD.MM.YY"),
      options: ["BTC", "LTC", "ETH"],
      selected: 1,
      balances: null,
      projects: null,
      data: [
        {
          id: 1,
          email: "Longmail89@domain.com",
          uuid: "INV-OK89NM",
          amount: 35.25,
        },
        {
          id: 2,
          email: "Longmail89@domain.com",
          uuid: "INV-OK89NM",
          amount: 35.25,
        },
        {
          id: 3,
          email: "Longmail89@domain.com",
          uuid: "INV-OK89NM",
          amount: 35.25,
        },
        {
          id: 4,
          email: "Longmail89@domain.com",
          uuid: "INV-OK89NM",
          amount: 35.25,
        },
        {
          id: 5,
          email: "Longmail89@domain.com",
          uuid: "INV-OK89NM",
          amount: 35.25,
        },
        {
          id: 6,
          email: "Longmail89@domain.com",
          uuid: "INV-OK89NM",
          amount: 35.25,
        },
        {
          id: 7,
          email: "Longmail89@domain.com",
          uuid: "INV-OK89NM",
          amount: 35.25,
        },
      ],
      openProjectInfo: false,
      selected_project: null,
      merchantEmail: "Longmail89@domain.com",
      loading: true,
      loading_data: true,
      arrowCalendar: false,
      merchant_id: null,
      amount_btc: 0,
      amount_ltc: 0,
      amount_eth: 0,
      amount_usdt: 0,
      balance_btc: 0,
      balance_ltc: 0,
      balance_eth: 0,
      balance_usdt: 0,
      data_table: [],
      telegram: null,
      data_table_project: [],
      query: null,
      disabled_btn: false,
      user_data: null,
      selected_component: "UserList",
      component_list: {
        UserProfile: UserProfile,
        UserList: UserList,
        ProjectDetail: ProjectDetail,
        UserTagManager: UserTagManager,
      },
    };
  },
  methods: {
    ...mapActions(["toggleCalendar"]),
    openCalendar() {
      this.toggleCalendar();
    },
    getUser(id) {
      httpClient
        .get("api/wallet/user_profile?user_id=" + this.merchant_id)
        .then((resp) => {
          this.merchantEmail = resp.data.userdata.username;
          this.telegram = resp.data.userdata.telegram;
          // this.created_at = resp.data.userdata.created_at
          // this.ref_balance = resp.data.userdata.ref_balance
          // this.ref_code = resp.data.userdata.ref_code
          // this.ref_percent = resp.data.userdata.ref_percent
          this.data_table = resp.data.balance_wallet;
          for (var item of resp.data.balance_wallet) {
            console.log(item);
            if (item.type == "BTC") {
              this.balance_btc = item.balance;
            } else if (item.type == "LTC") {
              this.balance_ltc = item.balance;
            } else if (item.type == "ETH") {
              this.balance_eth = item.balance;
            } else if (item.type == "TRX-20") {
              this.balance_usdt = item.balance;
            }
          }
          this.loading = false;
        });
    },
    openProject(id) {
      this.loading = true;
      this.selected_project = id;
      httpClient
        .get("/v1/user/dashboard/project/detail?id=" + id)
        .then((response) => {
          this.openProjectInfo = true;
          this.data_table_project = response.data;
          this.loading = false;
        });
    },
    addEuCardToProject() {
      this.disabled_btn = true;
      httpClient
        .get(
          "/v1/user/dashboard/project/add_eu_card?id=" + this.selected_project
        )
        .then((response) => {
          this.openProject(this.selected_project);

          this.disabled_btn = false;
        });
    },
    loadLedgerMerchantList() {
      let url = "";
      if ((this.query != "") & (this.query != null)) {
        url = "/v1/user/dashboard/user/list?q=" + this.query;
      } else {
        url = "/v1/user/dashboard/user/list";
      }
      httpClient.get(url).then((response) => {
        this.data = response.data;
        this.loading = false;
        this.selected = this.data[0].email;
        this.loadLedgerMerchantOne(this.data[0].email);
      });
    },
    loadLedgerMerchantOne(email) {
      httpClient
        .get("/v1/user/dashboard/user/detail?email=" + email)
        .then((response) => {
          this.balances = response.data.balances;
          this.projects = response.data.projects;
          this.user_data = response.data;
          this.loading_data = false;
        });
    },
    selectItem(email) {
      this.selected = email;
      this.loading_data = true;
      this.loadLedgerMerchantOne(email);
    },
    openMerchant() {
      this.openMerchantInfo = true;
      this.loading = true;
      this.getUser();
      this.getUserProject();
    },
    closeMerchant() {
      this.openProjectInfo = false;
    },
    setDataFilter(data) {
      this.loading = true;
      this.start = data.start;
      this.end = data.end;

      eventBus.$emit("load_user_list", { query: this.query });
    },
    searchUser: debounce(function () {
      this.selected_component = "UserList";
      eventBus.$emit("load_user_list", { query: this.query.trim() });
    }, 500),
  },
  mounted() {
    console.log("[LOGGING] ", "Loading user list");
    eventBus.$emit("load_user_list", { query: this.query });
    eventBus.$on("loading_user_profile", () => {
      this.loading_data = true;
    });
    eventBus.$on("loading_user_list", () => {
      this.loading = true;
    });
    eventBus.$on("set_selected_component", (data) => {
      this.selected_component = data;
    });
  },
  beforeDestroy() {
    // удаление слушателя из шины событий
    eventBus.$off("loading_user_profile");
    eventBus.$off("loading_user_list");
    eventBus.$off("set_selected_component");
  },
};
</script>
