<template>
  <div class="content-block pe-0">
    <b-container>
      <b-row class="pt-4" align-v="center" align-h="center">
        <b-col cols="12" md="4">
          <base-card>
            <b-row align-h="center">
              <b-col>
                <div class="user-avatar mx-auto">
                  <img src="@/assets/img/avatar.png" />
                </div>
                <div class="user-email mx-auto">Welcome back!</div>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <base-input
                  label="Email"
                  v-model="email"
                  placeholder="username@email.com"
                ></base-input>
              </b-col>
              <b-col cols="12">
                <base-input
                  label="Пароль"
                  v-model="password"
                  type="password"
                  placeholder="password"
                ></base-input>
              </b-col>
              <b-col cols="12" class="mt-3">
                <base-button @click="auth">Авторизоваться</base-button>
              </b-col>
            </b-row>
          </base-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import BaseButton from "../components/button/BaseButton.vue";
import BaseCard from "../components/card/BaseCard.vue";
import BaseInput from "../components/input/BaseInput.vue";
import { httpClient } from "../HttpClientV1/http-client";
import { mapActions } from "vuex";
export default {
  name: "Login",
  components: {
    BaseCard,
    BaseInput,
    BaseButton,
  },
  data() {
    return {
      email: null,
      password: null,
    };
  },
  methods: {
    ...mapActions(["login"]),
    auth() {
      const user = {
        email: this.email,
        password: this.password,
      };
      httpClient
        .post("/api/blog/dashboard/user/login", { user })
        .then((response) => {
          if (response.status == 200) {
            this.login(response.data.user.token);
            this.$router.push("/");
          } else {
            console.log("error", response.data);
          }
        });
    },
  },
};
</script>
