import axios from "axios";

const apiBaseUrl = "https://easypayments.online";
const config = {
  baseURL: apiBaseUrl,
};

const httpClient = axios.create(config);

const authInterceptor = (config) => {
  const token = localStorage.getItem("token") || null;
  if (token) {
    config.headers.Authorization = "Token " + token;
  } else {
    config.headers.Authorization = null;
  }
  return config;
};
const loggerInterceptor = (config) => {
  return config;
};

httpClient.interceptors.request.use(authInterceptor);
httpClient.interceptors.request.use(loggerInterceptor);

httpClient.interceptors.response.use((response) => {
  return response;
});

export { httpClient };
