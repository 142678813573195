<template>
  <div class="sidebar">
    <!-- <b-row align-h="center">
      <b-col class="w-100 text-center">
        <img src="@/assets/img/Logo_W.svg" width="100px" class="mx-auto mt-4" />
      </b-col>
    </b-row> -->
    <b-row align-h="center">
      <b-col>
        <div class="user-avatar mx-auto">
          <img src="@/assets/img/avatar.png" />
        </div>
        <div class="user-email mx-auto">Редактор</div>
      </b-col>
    </b-row>
    <!-- Первый блок меню -->
    <b-row>
      <b-col>
        <b-nav vertical class="sidebar__block">
          <h6>Dashboard</h6>
          <!-- <b-nav-item to="/" exact>
            <b-row>
              <span class="icon-sidebar icon-home"></span>
              Главная
            </b-row>
            <span class="indicator"></span>
          </b-nav-item>
          <b-nav-item to="/users">
            <b-row>
              <span class="icon-sidebar icon-user"></span>
              Пользователи
            </b-row>
            <span class="indicator"></span>
          </b-nav-item>
          <b-nav-item to="/invoices">
            <b-row>
              <span class="icon-sidebar icon-invoice"></span>
              Счета
            </b-row>
            <span class="indicator"></span>
          </b-nav-item>
          <b-nav-item to="/ledger">
            <b-row>
              <span class="icon-sidebar icon-ledger"></span>
              Финансы
            </b-row>
            <span class="indicator"></span>
          </b-nav-item>
          <b-nav-item to="/commission">
            <b-row>
              <span class="icon-sidebar icon-payments"></span>
              Коммиссии
            </b-row>
            <span class="indicator"></span>
          </b-nav-item>
          <b-nav-item to="/statistics">
            <b-row>
              <span class="icon-sidebar icon-payments"></span>
              Статистика
            </b-row>
            <span class="indicator"></span>
          </b-nav-item> -->
          <b-nav-item to="/">
            <b-row>
              <span class="icon-sidebar icon-support"></span>
              Контент
            </b-row>
            <span class="indicator"></span>
          </b-nav-item>
          <b-nav-item
            @click.prevent="logout, $router.push('/login')"
            to="/logout"
          >
            <b-row>
              <span class="icon-sidebar icon-logout"></span>
              Выйти
            </b-row>
            <span class="indicator"></span>
          </b-nav-item>
        </b-nav>
      </b-col>
    </b-row>
    <!-- Первый блок меню -->
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  inheritAttrs: false,
  name: "base-sidebar",
  props: {
    title: {
      type: String,
      description: "title",
    },
    subTitle: {
      type: String,
      description: "Table subtitle",
    },
  },
  methods: {
    ...mapActions(["logout"]),
  },
};
</script>
