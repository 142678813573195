import { httpClient } from "../../HttpClientV1/http-client";
export default {
  state: {
    openedCalendar: false
  },
  actions: {
    toggleCalendar({commit}){
      commit('updateCalendar')
    }
  },
  mutations: {
    updateCalendar(state){
      state.openedCalendar = !state.openedCalendar
    }
  },
  getters: {
    getStateCalendar: (state) => state.openedCalendar
  },
};
