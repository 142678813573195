<template>
  <div class="content-block pe-0">
    <b-container>
      <b-row class="pt-4">
        <b-col>
          <h1>Главная</h1>
        </b-col>
        <b-col md="auto" class="mt-3">
          <BaseCalendar
            :dateStart="start"
            :dateEnd="end"
            @submitFilterData="setDataFilter"
          >
          </BaseCalendar>
        </b-col>
      </b-row>
      <b-row class="mb-3" align-v="stretch">
        <b-col cols="12" md="3">
          <base-card :accent="true" class="h-100">
            <b-row v-if="loading_stats">
              <b-col cols="12">
                <h6>Оплачено счетов</h6>
              </b-col>
              <b-col cols="12">
                <h4>{{ data_statistics.paid.amount.toLocaleString() }} USD</h4>
                <p class="mb-0">{{ data_statistics.paid.count }} шт</p>
              </b-col>
            </b-row>
            <b-row v-else align-h="center" align-v="center" class="h-100">
              <b-col cols="auto">
                <b-spinner variant="light"></b-spinner
              ></b-col>
            </b-row>
          </base-card>
        </b-col>
        <b-col cols="12" md="3">
          <base-card class="h-100">
            <b-row v-if="loading_stats">
              <b-col cols="12">
                <h6>Всего счетов</h6>
              </b-col>
              <b-col cols="12">
                <h4>{{ data_statistics.all.amount.toLocaleString() }} USD</h4>
                <p class="mb-0">{{ data_statistics.all.count }} шт</p>
              </b-col>
            </b-row>
            <b-row v-else align-h="center" align-v="center" class="h-100">
              <b-col cols="auto">
                <b-spinner variant="light"></b-spinner
              ></b-col>
            </b-row>
          </base-card>
        </b-col>
        <b-col cols="12" md="3">
          <base-card class="h-100">
            <b-row v-if="loading_stats">
              <b-col cols="12">
                <h6>Частично оплачено</h6>
              </b-col>
              <b-col cols="12">
                <h4>
                  {{ data_statistics.partial.amount.toLocaleString() }} USD
                </h4>
                <p class="mb-0">{{ data_statistics.partial.count }} шт</p>
              </b-col>
            </b-row>
            <b-row v-else align-h="center" align-v="center" class="h-100">
              <b-col cols="auto">
                <b-spinner variant="light"></b-spinner
              ></b-col>
            </b-row>
          </base-card>
        </b-col>
        <b-col cols="12" md="3">
          <base-card class="h-100">
            <b-row v-if="loading_stats">
              <b-col cols="12">
                <h6>Отмененных счетов</h6>
              </b-col>
              <b-col cols="12">
                <h4>
                  {{ data_statistics.canceled.amount.toLocaleString() }} USD
                </h4>
                <p class="mb-0">{{ data_statistics.canceled.count }} шт</p>
              </b-col>
            </b-row>
            <b-row v-else align-h="center" align-v="center" class="h-100">
              <b-col cols="auto">
                <b-spinner variant="light"></b-spinner
              ></b-col>
            </b-row>
          </base-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6">
          <base-card>
            <b-row>
              <b-col cols="12">
                <h6>Оборот накопительно</h6>
              </b-col>
              <line-chart
                :data="{ '2017-05-13': 2, '2017-05-14': 5 }"
              ></line-chart>
            </b-row>
          </base-card>
        </b-col>
        <b-col md="6">
          <base-card>
            <b-row>
              <b-col cols="12">
                <h6>Создано счетов</h6>
              </b-col>
              <column-chart
                :data="[
                  ['Sun', 32],
                  ['Mon', 46],
                  ['Tue', 28],
                ]"
              ></column-chart>
            </b-row>
          </base-card>
        </b-col>
        <b-col md="6">
          <base-card>
            <b-row>
              <b-col cols="12">
                <h6>Подтверждено счетов</h6>
              </b-col>
              <line-chart
                :data="{ '2017-05-13': 2, '2017-05-14': 5 }"
              ></line-chart>
            </b-row>
          </base-card>
        </b-col>
        <b-col md="6">
          <base-card>
            <b-row>
              <b-col cols="12">
                <h6>Оплачено счетов</h6>
              </b-col>
              <line-chart
                :data="{ '2017-05-13': 2, '2017-05-14': 5 }"
              ></line-chart>
            </b-row>
          </base-card>
        </b-col>
        <b-col md="6">
          <base-card>
            <b-row>
              <b-col cols="12">
                <h6>Оборот по дням</h6>
              </b-col>
              <line-chart
                :data="{ '2017-05-13': 2, '2017-05-14': 5 }"
              ></line-chart>
            </b-row>

            <b-row class="text-center">
              <b-col>
                <p>MIN</p>
                <p>10</p>
              </b-col>
              <b-col>
                <p>MAX</p>
                <p>100</p>
              </b-col>
              <b-col>
                <p>AVG</p>
                <p>25</p>
              </b-col>
            </b-row>
          </base-card>
        </b-col>
        <b-col md="6">
          <base-card>
            <b-row>
              <b-col cols="12">
                <h6>CR Создан-Оплачен</h6>
              </b-col>
              <line-chart
                :data="{ '2017-05-13': 2, '2017-05-14': 5 }"
              ></line-chart>
            </b-row>
            <b-row class="text-center">
              <b-col>
                <p>MIN</p>
                <p>10</p>
              </b-col>
              <b-col>
                <p>MAX</p>
                <p>100</p>
              </b-col>
              <b-col>
                <p>AVG</p>
                <p>25</p>
              </b-col>
            </b-row>
          </base-card>
        </b-col>
        <b-col md="6">
          <base-card>
            <b-row>
              <b-col cols="12">
                <h6>AVG счетов по дням</h6>
              </b-col>
              <column-chart
                :data="[
                  ['Sun', 32],
                  ['Mon', 46],
                  ['Tue', 28],
                  ['Wed', 32],
                  ['Thu', 46],
                  ['Fri', 28],
                ]"
              ></column-chart>
            </b-row>
          </base-card>
        </b-col>
        <b-col md="6">
          <base-card>
            <b-row>
              <b-col cols="12">
                <h6>AVG оборот по дням</h6>
              </b-col>
              <column-chart
                :data="[
                  ['Sun', 32],
                  ['Mon', 46],
                  ['Tue', 28],
                  ['Wed', 32],
                  ['Thu', 46],
                  ['Fri', 28],
                ]"
              ></column-chart>
            </b-row>
          </base-card>
        </b-col>
      </b-row>
    </b-container>

    <invoice-modal></invoice-modal>
  </div>
</template>

<script>
import moment from "moment";
import BaseCalendar from "../components/calendar/BaseCalendar.vue";
import BaseSelect from "../components/input/BaseSelect.vue";
import { httpClient } from "../HttpClientV1/http-client";
export default {
  name: "Home",
  data() {
    return {
      start: moment().startOf("month").format("DD.MM.YY"),
      end: moment().format("DD.MM.YY"),
      data_statistics: {
        paid: {
          amount: 0,
          count: 0,
        },
        all: {
          amount: 0,
          count: 0,
        },
        partial: {
          amount: 0,
          count: 0,
        },
        canceled: {
          amount: 0,
          count: 0,
        },
      },
      loading_stats: false,
    };
  },
  methods: {
    setDataFilter(data) {
      this.start = data.start;
      this.end = data.end;
    },
    async loadStatistics() {
      this.loading_stats = false;
      httpClient
        .get(
          "/v1/dashboard/invoice/statistics/date?start=" +
            this.start +
            "&end=" +
            this.end
        )
        .then((response) => {
          this.data_statistics = response.data;
          this.loading_stats = true;
        });
    },
  },
  mounted() {
    this.loadStatistics();
  },
  components: { BaseCalendar, BaseSelect },
};
</script>
