<template>
  <div v-if="loading">
    <b-row>
      <b-col cols="12">
        <base-card style="position: sticky; top: 20px">
          <b-row>
            <b-col cols="6"><h4>Детали проекта</h4></b-col>
            <b-col cols="6" class="text-end">
              <img
                src="@/assets/img/icon/Solid/Interface/Cross.svg"
                alt=""
                class="pointer"
                @click="closeInfoProject()"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="4">Статус</b-col>
            <b-col cols="8" v-if="data_table_project.active"
              ><span class="true">Активен</span></b-col
            >
            <b-col cols="8" v-else><span class="false">Не активен</span></b-col>
          </b-row>
          <b-row>
            <b-col cols="4">Удален</b-col>
            <b-col cols="8" v-if="data_table_project.deleted"
              ><span class="false">Удален</span></b-col
            >
            <b-col cols="8" v-else><span class="true">Не удален</span></b-col>
          </b-row>
          <b-row>
            <b-col cols="4">Есть сайт</b-col>
            <b-col cols="8" v-if="data_table_project.site"
              ><span class="true">Есть</span></b-col
            >
            <b-col cols="8" v-else><span class="false">Без сайта</span></b-col>
          </b-row>
          <b-row>
            <b-col cols="4">CMS</b-col>
            <b-col cols="8" v-if="data_table_project.cms_enable"
              ><span class="true">CMS</span></b-col
            >
            <b-col cols="8" v-else
              ><span class="true">API/Html-Модуль</span></b-col
            >
          </b-row>
          <b-row v-if="data_table_project.cms_enable">
            <b-col cols="4">CMS</b-col>
            <b-col cols="8"
              ><span class="true">{{ data_table_project.cms }}</span></b-col
            >
          </b-row>
          <b-row>
            <b-col cols="4">SHOP_ID</b-col>
            <b-col cols="8">{{ data_table_project.shop_id }}</b-col>
          </b-row>
          <b-row>
            <b-col cols="4">Вид деятельности</b-col>
            <b-col cols="8">{{ data_table_project.kind_of_activity }}</b-col>
          </b-row>
          <b-row>
            <b-col cols="4">Описание</b-col>
            <b-col cols="8">{{ data_table_project.description }}</b-col>
          </b-row>
          <b-row v-if="data_table_project.site">
            <b-col cols="4">Сайт</b-col>
            <b-col cols="8"
              ><a :href="data_table_project.url_site">{{
                data_table_project.url_site
              }}</a></b-col
            >
          </b-row>
          <b-row v-if="data_table_project.site">
            <b-col cols="4">Успешный URL</b-col>
            <b-col cols="8"
              ><a :href="data_table_project.success_url">{{
                data_table_project.success_url
              }}</a></b-col
            >
          </b-row>
          <b-row v-if="data_table_project.site">
            <b-col cols="4">Не успешный URL</b-col>
            <b-col cols="8"
              ><a :href="data_table_project.fail_url">{{
                data_table_project.fail_url
              }}</a></b-col
            >
          </b-row>
          <b-row v-if="data_table_project.site">
            <b-col cols="4">Webhook</b-col>
            <b-col cols="8"
              ><a :href="data_table_project.result_url">{{
                data_table_project.result_url
              }}</a></b-col
            >
          </b-row>
          <b-row v-if="data_table_project.site">
            <b-col cols="4">EU CARD</b-col>
            <b-col cols="8">{{ data_table_project.widget }}</b-col>
          </b-row>
          <b-row v-if="data_table_project.site">
            <b-col
              cols="5"
              class="px-0"
              v-if="
                data_table_project.widget == 'Создан' ||
                data_table_project.widget == 'На рассмотрении'
              "
              ><base-button
                :disabled="disabled_btn"
                @click="addEuCardToProject"
              >
                Подключить карту</base-button
              ></b-col
            >
            <b-col
              cols="5"
              class="px-0"
              v-if="
                data_table_project.widget == 'Создан' ||
                data_table_project.widget == 'На рассмотрении'
              "
              ><base-button
                variant="danger"
                :disabled="disabled_btn"
                @click="cancelEuCardToProject"
              >
                Отклонить</base-button
              ></b-col
            >
          </b-row>
        </base-card>
      </b-col>
    </b-row>
  </div>
  <div v-else>
    <b-row>
      <b-col cols="12">
        <base-card style="position: sticky; top: 20px">
          <b-row align-h="center" class="text-center">
            <b-spinner variant="light"></b-spinner>
          </b-row>
        </base-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import eventBus from "../../EventBus";
import { httpClient } from "../../HttpClientV1/http-client";
import BaseButton from "../button/BaseButton.vue";
import BaseCard from "../card/BaseCard.vue";
export default {
  components: { BaseCard, BaseButton },
  data() {
    return {
      data_table_project: null,
      loading: false,
      id: null,
      disabled_btn: false,
    };
  },
  methods: {
    openProject() {
      this.loading = false;
      this.selected_project = this.id;
      httpClient
        .get("/v1/user/dashboard/project/detail?id=" + this.id)
        .then((response) => {
          this.data_table_project = response.data;
          this.loading = true;
        });
    },
    addEuCardToProject() {
      this.disabled_btn = true;
      httpClient
        .get("/v1/user/dashboard/project/add_eu_card?id=" + this.id)
        .then((response) => {
          this.openProject();

          this.disabled_btn = false;
        });
    },
    cancelEuCardToProject() {
      this.disabled_btn = true;
      httpClient
        .get("/v1/user/dashboard/project/cancel_eu_card?id=" + this.id)
        .then((response) => {
          this.openProject();

          this.disabled_btn = false;
        });
    },
    closeInfoProject() {
      eventBus.$emit("set_selected_component", "UserList");
    },
  },
  mounted() {
    eventBus.$on("loading_project_detail", (data) => {
      if (this.id != data) {
        this.id = data;
        this.openProject();
      }
    });
  },
  beforeDestroy() {
    // удаление слушателя из шины событий
    eventBus.$off("loading_project_detail");
  },
};
</script>
