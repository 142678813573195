<template>
  <div class="main-calendar me-0" @click="toggleCalendar" tabindex="1">
    <div class="calendar-icon"></div>
    <span class="date-start">{{ dateStart }}</span>
    <span class="tire">&mdash;</span>
    <span class="date-end">{{ dateEnd }}</span>
    <div
      class="user-arrow ms-2"
      :class="{
        active: getStateCalendar(),
      }"
    >
      <div class="user-arrow__img"></div>
    </div>
    <div class="main-calendar__calendar" v-if="getStateCalendar()" @click.stop>
      <v-date-picker
        v-model="range"
        is-range
        class="ml-auto mr-auto"
        color="gray"
        is-dark
        :attributes="attrs"
        @input="sendRange"
        :model-config="modelConfig"
        :columns="$screens({ lg: 1 }, 1)"
      />
      <div class="calendar-nav">
        <b-row align-h="center">
          <b-col cols="auto" class="dates text-cener mx-auto">
            <span class="calendar-nav__date">{{ dateStart }}</span>
            <span class="ms-2">—</span>
            <span class="calendar-nav__date ms-2">{{ dateEnd }}</span>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
export default {
  props: {
    dateStart: {
      required: true,
    },
    dateEnd: {
      required: true,
    },
  },
  data() {
    return {
      attrs: [
        {
          key: "today",
          highlight: {
            contentClass: "calendar-element",
            class: "calendar-element",
          },
          dates: new Date(),
        },
      ],
      arrowCalendar: false,
      range: {
        start: this.dateStart,
        end: this.dateEnd,
      },
      modelConfig: {
        type: "string",
        mask: "DD.MM.YY", // Uses 'iso' if missing
      },
    };
  },
  methods: {
    ...mapActions(["toggleCalendar"]),
    ...mapGetters(["getStateCalendar"]),
    sendRange: function () {
      this.$emit("submitFilterData", this.range);
    },
  },
};
</script>
