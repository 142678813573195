<template>
  <div
    class="context-menu-wrapper"
    @blur="is_open = false"
    tabindex="1"
    ref="profile_content"
  >
    <div class="list-items" v-if="is_open">
      <base-card class="p-0 border-0 context">
        <div
          class="item text-center"
          v-for="(item, index) in options"
          :key="index"
          @click.stop="selectItem(item)"
        >
          {{ item.name }}
        </div>
      </base-card>
    </div>
  </div>
</template>
<script>
import eventBus from "../../EventBus";
export default {
  data() {
    return {
      email: null,
      is_open: false,
      options: [
        {
          name: "Деактивировать",
          component: "UserList",
        },
        {
          name: "Найти счета",
          component: "UserInvoice",
        },
        {
          name: "Тэги",
          component: "UserTagManager",
        },
      ],
    };
  },
  methods: {
    selectItem(item) {
      console.log(this.email);
      let email = this.email;
      if (item.component == "UserTagManager") {
        eventBus.$emit("set_selected_component", item.component);
        setTimeout(() => {
          eventBus.$emit("open_tag_manager", this.email);
        }, 100);
      } else if (item.component == "UserInvoice") {
        this.$router.push("/invoices?q=" + email);

        eventBus.$emit("search_invoice", email);
      }
    },
  },
  mounted() {
    eventBus.$on("open_profile_context_menu", (data) => {
      this.email = data;
      this.is_open = !this.is_open;
      if (this.is_open) {
        this.$refs.profile_content.focus();
      }
    });
  },
  beforeDestroy() {
    eventBus.$off("open_profile_context_menu");
  },
};
</script>
