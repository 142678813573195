<template>
  <div id="app">
    <!-- <div class="blur__backgroud"></div>
    <div class="blur__backgroud2"></div>
    <div class="blur__backgroud3"></div>-->
    <b-container fluid class="body">
      <b-row class="flex-row">
        <b-col cols="auto" class="ps-0 pe-0" v-if="$route.meta.requiresAuth">
          <base-sidebar></base-sidebar>
        </b-col>
        <b-col class="ps-0 pe-0">
          <transition name="component-fade" mode="out-in">
            <router-view />
          </transition>
        </b-col>
        <!-- <b-col cols="auto" class="ps-0 pe-0">
          <right-sidebar></right-sidebar>
        </b-col>-->
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "App",
  computed: {
    ...mapGetters(["isLoggedIn"]),
  },
  mounted() {
    // const requiresAuth = this.$route.meta.requiresAuth;
    // if (requiresAuth) {
    //   if (!this.isLoggedIn) {
    //     this.$router.push("/login");
    //   }
    // }
  },
};
</script>
