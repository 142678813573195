<template>
  <div class="content-block pe-0">
    <b-container>
      <b-row class="pt-4 h-100">
        <b-col>
          <h4>{{ article.title }}</h4>
        </b-col>
      </b-row>
      <b-row class="mb-2 align-content-stretch h-100">
        <b-col cols="12" v-if="!loading">
          <b-row align-h="center">
            <b-col cols="12" class="tools">
              <base-card class="border-0">
                <b-row class="">
                  <b-col cols="auto">
                    <dropdown-menu label="Текст" class="w-100">
                      <base-card class="context border-0 p-0 fit-content">
                        <div
                          class="item text-center"
                          @mousedown.prevent="execCommand('bold')"
                        >
                          Жирный
                        </div>
                        <div
                          class="item text-center"
                          @mousedown.prevent="execCommand('italic')"
                        >
                          Наклонный
                        </div>
                        <div
                          class="item text-center"
                          @mousedown.prevent="execCommand('underline')"
                        >
                          Подчеркнутый
                        </div>
                      </base-card>
                    </dropdown-menu>
                  </b-col>
                  <b-col cols="auto">
                    <dropdown-menu label="Заголовок" class="w-100">
                      <base-card class="context border-0 p-0 fit-content">
                        <div
                          class="item text-center"
                          @mousedown.prevent="execCommand('formatBlock', 'h1')"
                        >
                          H1
                        </div>
                        <div
                          class="item text-center"
                          @mousedown.prevent="execCommand('formatBlock', 'h2')"
                        >
                          H2
                        </div>
                        <div
                          class="item text-center"
                          @mousedown.prevent="execCommand('formatBlock', 'h3')"
                        >
                          H3
                        </div>
                        <div
                          class="item text-center"
                          @mousedown.prevent="execCommand('formatBlock', 'h4')"
                        >
                          H4
                        </div>
                        <div
                          class="item text-center"
                          @mousedown.prevent="execCommand('formatBlock', 'p')"
                        >
                          Обычный
                        </div>
                      </base-card>
                    </dropdown-menu>
                  </b-col>
                  <b-col cols="auto">
                    <dropdown-menu label="Список">
                      <base-card class="context border-0 p-0 fit-content">
                        <div
                          class="item text-center w-100"
                          @mousedown.prevent="
                            execCommand('insertUnorderedList')
                          "
                        >
                          Обычный
                        </div>
                        <div
                          class="item text-center w-100"
                          @mousedown.prevent="execCommand('insertOrderedList')"
                        >
                          Нумеровный
                        </div>
                      </base-card>
                    </dropdown-menu>
                  </b-col>
                  <b-col cols="auto">
                    <dropdown-menu label="Медиа">
                      <base-card class="context border-0 p-0 fit-content">
                        <div
                          class="item text-center w-100"
                          @mousedown.prevent="
                            execCommand(
                              'createLink',
                              'https://cryptocloud.plus/'
                            )
                          "
                        >
                          Изображение
                        </div>
                        <div
                          class="item text-center w-100"
                          @mousedown.prevent="execCommand('createList', 'ol')"
                        >
                          Видео
                        </div>
                      </base-card>
                    </dropdown-menu>
                  </b-col>
                  <b-col cols="auto">
                    <base-card class="context pointer">
                      <div
                        class="item text-center w-100"
                        @mousedown.prevent="execCommand('removeFormat')"
                      >
                        Очистить
                      </div>
                    </base-card>
                  </b-col>
                  <b-col cols="auto">
                    <base-card class="context pointer">
                      <div
                        class="item text-center w-100"
                        @mousedown.prevent="generateContentArticle"
                      >
                        Оглавление
                      </div>
                    </base-card>
                  </b-col>
                </b-row>
              </base-card>
            </b-col>
            <b-col cols="12" class="mb-4">
              <base-card class="p-0 h-100" white>
                <b-row align-v="stretch">
                  <b-col cols="8" class="text-left"> </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <base-card white>
                      <b-row>
                        <b-col cols="8" class="article-content">
                          <HTMLEditer
                            :data="article.content"
                            ref="articleContent"
                          ></HTMLEditer>
                        </b-col>
                        <b-col cols="4" v-if="article_content.length">
                          <b-row>
                            <b-col
                              cols="12"
                              v-for="(item, index) of article_content"
                              :key="index"
                            >
                              <a :href="'#' + item.hash">{{ item.title }}</a>
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                    </base-card>
                  </b-col>
                </b-row>
              </base-card>
            </b-col>
            <!-- <b-col cols="8">
              <base-card white>
                <quill-editor
                  class="article-content"
                  ref="articleContent"
                  v-model="article.content"
                  :options="optionsEditer"
                ></quill-editor>
              </base-card>
            </b-col> -->
          </b-row>
        </b-col>
        <b-col cols="6" v-else>
          <base-card>
            <b-row align-h="center" class="text-center">
              <b-spinner variant="light"></b-spinner>
            </b-row>
          </base-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import BaseCalendar from "../components/calendar/BaseCalendar.vue";
import BaseCard from "../components/card/BaseCard.vue";
import { httpClient } from "../HttpClientV1/http-client";
import BaseInput from "../components/input/BaseInput.vue";
import BaseButton from "../components/button/BaseButton.vue";
import HTMLEditer from "../components/editer/HTMLEditer.vue";
import DropdownMenu from "../components/editer/DropdownMenu.vue";

import { quillEditor, Quill } from "vue-quill-editor";
import { container, ImageExtend, QuillWatch } from "quill-image-extend-module";
import eventBus from "../EventBus";
Quill.register("modules/ImageExtend", ImageExtend);
export default {
  name: "Payments",
  components: {
    BaseCalendar,
    BaseCard,
    BaseInput,
    BaseButton,
    HTMLEditer,
    DropdownMenu,
    quillEditor,
  },
  data() {
    return {
      start: moment().startOf("isoWeek").format("DD.MM.YY"),
      end: moment().format("DD.MM.YY"),
      options: ["BTC", "LTC", "ETH"],
      selected: 1,
      openMerchantInfo: false,
      merchantEmail: "Longmail89@domain.com",
      loading: true,
      loading_data: true,
      arrowCalendar: false,
      merchant_id: null,
      amount_btc: 0,
      amount_ltc: 0,
      amount_eth: 0,
      amount_usdt: 0,
      balance_btc: 0,
      balance_ltc: 0,
      balance_eth: 0,
      balance_usdt: 0,
      data_table: [],
      telegram: null,
      data_table_project: [],
      address_usdt: null,
      address_eth: null,
      address_btc: null,
      address_ltc: null,
      disabled_btn: false,
      article: null,
      optionsEditer: {
        modules: {
          ImageExtend: {
            loading: true,
            name: "img",
            action: "https://cryptocloud.plus",
            response: (res) => {
              return res.info;
            },
          },
          toolbar: [
            ["bold", "italic", "underline", "strike"], // toggled buttons
            [{ quote: ["blockquote", "code-block"] }],

            [{ list: "ordered" }, { list: "bullet" }],
            [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
            [{ direction: "rtl" }], // text direction

            // [{ size: ["small", false, "large", "huge"] }], // custom dropdown
            [{ header: [2, 3] }],

            [{ color: [] }], // dropdown with defaults from theme
            [{ align: [] }],

            ["clean"], // remove formatting button

            [
              "link",
              {
                image: function () {
                  QuillWatch.emit(this.quill.id);
                },
              },
              "video",
            ],
          ],
        },
        theme: "snow",
      },
      article_content: [],
    };
  },
  methods: {
    ...mapActions(["toggleCalendar"]),
    execCommand: function (commandName, valueArgument) {
      if (!valueArgument) {
        valueArgument = null;
      }
      if (commandName == "insertHTML") {
        const selection = window.getSelection();
        document.execCommand(
          commandName,
          false,
          `<${valueArgument}>${selection}</${valueArgument}>`
        );
      } else if (commandName == "createList") {
        const selection = window.getSelection();
        let new_element = "";
        selection
          .toString()
          .split("\n")
          .forEach((element) => {
            if (element !== "") {
              new_element += "<li>" + element + "</li>";
            }
          });
        document.execCommand(
          "insertHTML",
          false,
          `<${valueArgument}>${new_element}</${valueArgument}>`
        );
      } else {
        document.execCommand(commandName, true, valueArgument);
      }

      this.$refs.articleContent.parseHtml();
    },
    openCalendar() {
      this.toggleCalendar();
    },
    generateContentArticle() {
      this.article_content = [];
      this.$refs.articleContent.generateContent();
    },
    loadArticleDetail() {
      httpClient
        .get("/api/blog/dashboard/article/detail?id=" + this.$route.params.id)
        .then((response) => {
          this.article = response.data;
          this.loading = false;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    editImgUrl() {
      let el = (this.$refs.articleContent.contentDocument.designMode = "on");
      // let all_img = el.querySelectorAll("img");
      // for (let item of all_img) {
      //   console.log(item);
      //   item.setAttribute(
      //     "src",
      //     "https://cryptocloud.plus" + item.getAttribute("src")
      //   );
      // }
    },
    setDataFilter(data) {
      this.loading = true;
      this.start = data.start;
      this.end = data.end;
    },
  },
  mounted() {
    eventBus.$on("generateTagHash", (data) => {
      this.article_content.push(data);
    });
    this.loadArticleDetail();
    let self = this;
    setTimeout(() => {
      self.editImgUrl();
    }, 200);
  },
  beforeDestroy() {
    eventBus.$off("generateTagHash");
  },
};
</script>
