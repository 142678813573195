<template>
  <div class="content-block pe-0">
    <b-container>
      <b-row class="pt-4">
        <b-col>
          <h1>Список статей</h1>
        </b-col>
      </b-row>
      <b-row class="mb-2 align-content-stretch h-100">
        <b-col cols="12" v-if="!loading">
          <b-row>
            <b-col
              cols="3"
              v-for="(item, index) in data.slice(0, 3)"
              :key="index"
              class="pointer mb-4"
            >
              <router-link :to="'/content/articles/' + item.id + '/edit'">
                <base-card class="p-0 h-100">
                  <b-row align-v="stretch">
                    <b-col cols="12">
                      <img
                        :src="'https://easypayments.online' + item.cover"
                        alt=""
                        class="br-8"
                        width="100%"
                      />
                    </b-col>
                    <b-col cols="6" class="p-2 px-4">{{ item.created }}</b-col>
                    <b-col cols="12" class="p-2 px-4"
                      ><h6>{{ item.title }}</h6></b-col
                    >
                    <b-col cols="12" class="text-left p-2 px-4">
                      {{ item.meta_description }}
                    </b-col>
                  </b-row>
                </base-card>
              </router-link>
            </b-col>
            <b-col cols="3" class="pointer mb-4">
              <b-row align-h="center" align-v="stretch" class="h-50 pb-3">
                <b-col cols="12">
                  <router-link to="/content/articles/add">
                    <base-card accent class="h-100">
                      <b-row
                        class="h-100"
                        align-content="center"
                        align-h="center"
                        align-v="center"
                      >
                        <b-col cols="12">
                          <h6 class="mb-0">Добавить статью</h6>
                        </b-col>
                      </b-row>
                    </base-card>
                  </router-link>
                </b-col>
              </b-row>
              <b-row class="h-50">
                <b-col cols="12">
                  <router-link to="/content/articles">
                    <base-card class="h-100">
                      <b-row
                        class="h-100"
                        align-content="center"
                        align-h="center"
                        align-v="center"
                      >
                        <b-col cols="12">
                          <h6 class="mb-0">Показать все</h6>
                        </b-col>
                      </b-row></base-card
                    >
                  </router-link>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="12" v-if="data.length == 0">
              <base-card>
                <b-row align-h="center" class="text-center">
                  <p>Нет данных за выбранный период</p>
                  <p
                    @click="openCalendar"
                    class="pointer text-decoration-underline mb-0"
                  >
                    Выбрать период
                  </p>
                </b-row>
              </base-card>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="6" v-else>
          <base-card v-if="data.length !== 0">
            <b-row align-h="center" class="text-center">
              <b-spinner variant="light"></b-spinner>
            </b-row>
          </base-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import BaseCalendar from "../components/calendar/BaseCalendar.vue";
import BaseCard from "../components/card/BaseCard.vue";
import { httpClient } from "../HttpClientV1/http-client";
import BaseInput from "../components/input/BaseInput.vue";
import BaseButton from "../components/button/BaseButton.vue";

export default {
  name: "Payments",
  components: {
    BaseCalendar,
    BaseCard,
    BaseInput,
    BaseButton,
  },
  data() {
    return {
      start: moment().startOf("isoWeek").format("DD.MM.YY"),
      end: moment().format("DD.MM.YY"),
      options: ["BTC", "LTC", "ETH"],
      selected: 1,
      data: [
        {
          id: 1,
          email: "Longmail89@domain.com",
          uuid: "INV-OK89NM",
          amount: 35.25,
        },
        {
          id: 2,
          email: "Longmail89@domain.com",
          uuid: "INV-OK89NM",
          amount: 35.25,
        },
        {
          id: 3,
          email: "Longmail89@domain.com",
          uuid: "INV-OK89NM",
          amount: 35.25,
        },
        {
          id: 4,
          email: "Longmail89@domain.com",
          uuid: "INV-OK89NM",
          amount: 35.25,
        },
        {
          id: 5,
          email: "Longmail89@domain.com",
          uuid: "INV-OK89NM",
          amount: 35.25,
        },
        {
          id: 6,
          email: "Longmail89@domain.com",
          uuid: "INV-OK89NM",
          amount: 35.25,
        },
        {
          id: 7,
          email: "Longmail89@domain.com",
          uuid: "INV-OK89NM",
          amount: 35.25,
        },
      ],
      openMerchantInfo: false,
      merchantEmail: "Longmail89@domain.com",
      loading: true,
      loading_data: true,
      arrowCalendar: false,
      merchant_id: null,
      amount_btc: 0,
      amount_ltc: 0,
      amount_eth: 0,
      amount_usdt: 0,
      balance_btc: 0,
      balance_ltc: 0,
      balance_eth: 0,
      balance_usdt: 0,
      data_table: [],
      telegram: null,
      data_table_project: [],
      address_usdt: null,
      address_eth: null,
      address_btc: null,
      address_ltc: null,
      disabled_btn: false,
    };
  },
  methods: {
    ...mapActions(["toggleCalendar"]),
    openCalendar() {
      this.toggleCalendar();
    },
    loadArticleList() {
      httpClient
        .get("/api/blog/dashboard/article/list")
        .then((response) => {
          this.data = response.data;
          this.loading = false;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    setDataFilter(data) {
      this.loading = true;
      this.start = data.start;
      this.end = data.end;
    },
  },
  mounted() {
    this.loadArticleList();
  },
};
</script>
