<template>
  <div
    class="article-editor-content"
    :contenteditable="true"
    v-focus
    @input="parseHtml"
    @paste="pastePlainText"
    v-html="content"
    tabindex="1"
    ref="articleContent"
    data-texttip-scope-id="id_tooltip"
  ></div>
</template>
<script>
import eventBus from "../../EventBus";

export default {
  inheritAttrs: false,
  props: {
    data: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      editable: false,
    };
  },
  model: {
    prop: "value",
    event: "input",
  },
  directives: {
    focus: {
      inserted: function (el) {
        el.focus();
      },
    },
  },
  computed: {
    content: {
      get() {
        return this.data;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    listeners() {
      return {
        ...this.$listeners,
        input: this.onInput,
      };
    },
  },
  methods: {
    parseHtml() {
      let style = {
        span: "",
        img: "width:100%;border-radius: 16px;",
        iframe: "height: 350px",
        a: "",
      };
      let html = document.getElementsByClassName("article-editor-content")[0];
      let tagNames = Object.keys(style);
      for (let i = 0; i < tagNames.length; i++) {
        let _tagNames = html.getElementsByTagName(tagNames[i]);
        if (_tagNames.length > 0) {
          for (let j = 0; j < _tagNames.length; j++) {
            _tagNames[j].style = style[tagNames[i]];
            if (_tagNames[j].tagName == "A") {
              _tagNames[j].innerHTML = _tagNames[j].innerText;
            }
          }
        }
      }
      this.editImgUrl();
    },
    generateContent() {
      let html = document.getElementsByClassName("article-editor-content")[0];
      let allH2Tags = html.getElementsByTagName("h2");
      Array.from(allH2Tags).forEach((tag) => {
        let text = tag.innerText;
        let hash = window.URLify(text);
        tag.setAttribute("id", hash);
        tag.style["scroll-margin-top"] = "90px";
        let find = true;
        let new_tag = tag.nextElementSibling;
        let sub_content = [];
        for (find = true; find; ) {
          try {
            if (new_tag.tagName != "H2") {
              if (new_tag.tagName == "H3") {
                if (new_tag.innerText !== "") {
                  new_tag.setAttribute("id", window.URLify(new_tag.innerText));
                  new_tag.style["scroll-margin-top"] = "90px";
                  sub_content.push({
                    hash: window.URLify(new_tag.innerText),
                    title: new_tag.innerText,
                  });
                }
              }
            } else {
              find = false;
            }
            new_tag = new_tag.nextElementSibling;
          } catch (e) {
            console.log(e);
            find = false;
          }
        }
        if (text != "") {
          eventBus.$emit("generateTagHash", {
            hash: hash,
            title: text,
            sub_content: sub_content,
          });
        }
      });
    },
    replaceVideoLink() {
      let html = document.getElementsByClassName("article-editor-content")[0];
    },
    pastePlainText(e) {
      e.preventDefault();
      var text = (e.originalEvent || e).clipboardData.getData("text/plain");
      console.log(text.split("\n"));
      text.split("\n").forEach((element) => {
        console.log(element);
        if (!/youtu.be/.test(element)) {
          document.execCommand("insertHTML", false, "<p>" + element + "</p>");
        } else {
          let link = element.split("//")[1].split("/")[1];
          document.execCommand(
            "insertHTML",
            false,
            "<iframe src='https://www.youtube.com/embed/" +
              link +
              "' width='100%'></iframe><br><p></p>"
          );
        }
      });
    },
    editImgUrl() {
      let el = this.$refs.articleContent;
      let all_img = el.querySelectorAll("img");
      for (let item of all_img) {
        if (!/easypayments.online/.test(item.getAttribute("src"))) {
          item.setAttribute(
            "src",
            "https://easypayments.online" + item.getAttribute("src")
          );
        }
      }
    },
    toggleEdit() {
      this.editable = !this.editable;
    },
    onInput(evt) {
      this.$emit("input", evt.target.value);
    },
  },
  mounted() {
    const RUSSIAN_MAP = {
      а: "a",
      б: "b",
      в: "v",
      г: "g",
      д: "d",
      е: "e",
      ё: "yo",
      ж: "zh",
      з: "z",
      и: "i",
      й: "j",
      к: "k",
      л: "l",
      м: "m",
      н: "n",
      о: "o",
      п: "p",
      р: "r",
      с: "s",
      т: "t",
      у: "u",
      ф: "f",
      х: "h",
      ц: "c",
      ч: "ch",
      ш: "sh",
      щ: "sh",
      ъ: "",
      ы: "y",
      ь: "",
      э: "e",
      ю: "yu",
      я: "ya",
      А: "A",
      Б: "B",
      В: "V",
      Г: "G",
      Д: "D",
      Е: "E",
      Ё: "Yo",
      Ж: "Zh",
      З: "Z",
      И: "I",
      Й: "J",
      К: "K",
      Л: "L",
      М: "M",
      Н: "N",
      О: "O",
      П: "P",
      Р: "R",
      С: "S",
      Т: "T",
      У: "U",
      Ф: "F",
      Х: "H",
      Ц: "C",
      Ч: "Ch",
      Ш: "Sh",
      Щ: "Sh",
      Ъ: "",
      Ы: "Y",
      Ь: "",
      Э: "E",
      Ю: "Yu",
      Я: "Ya",
    };
    const ALL_DOWNCODE_MAPS = [RUSSIAN_MAP];
    const Downcoder = {
      Initialize: function () {
        if (Downcoder.map) {
          // already made
          return;
        }
        Downcoder.map = {};
        for (const lookup of ALL_DOWNCODE_MAPS) {
          Object.assign(Downcoder.map, lookup);
        }
        Downcoder.regex = new RegExp(Object.keys(Downcoder.map).join("|"), "g");
      },
    };
    function downcode(slug) {
      Downcoder.Initialize();
      return slug.replace(Downcoder.regex, function (m) {
        return Downcoder.map[m];
      });
    }
    function URLify(s, num_chars, allowUnicode) {
      // changes, e.g., "Petty theft" to "petty-theft"
      if (!allowUnicode) {
        s = downcode(s);
      }
      s = s.toLowerCase(); // convert to lowercase
      // if downcode doesn't hit, the char will be stripped here
      if (allowUnicode) {
        // Keep Unicode letters including both lowercase and uppercase
        // characters, whitespace, and dash; remove other characters.
        s = XRegExp.replace(s, XRegExp("[^-_\\p{L}\\p{N}\\s]", "g"), "");
      } else {
        s = s.replace(/[^-\w\s]/g, ""); // remove unneeded chars
      }
      s = s.replace(/^\s+|\s+$/g, ""); // trim leading/trailing spaces
      s = s.replace(/[-\s]+/g, "-"); // convert spaces to hyphens
      s = s.substring(0, num_chars); // trim to first num_chars chars
      s = s.replace(/-+$/g, ""); // trim any trailing hyphens
      return s;
    }
    window.URLify = URLify;
    this.editImgUrl();
  },
};
</script>
