<template>
  <div class="content-block pe-0">
    <b-container>
      <b-row class="pt-4">
        <b-col>
          <h3>Оборот</h3>
        </b-col>
        <b-col md="auto" class="mt-3">
          <BaseCalendar
            :dateStart="start"
            :dateEnd="end"
            @submitFilterData="setDataFilter"
          >
          </BaseCalendar>
        </b-col>
      </b-row>
      <b-row class="mb-3" align-v="stretch" v-if="loading_stats_user">
        <b-col cols="12" md="2" class="mb-3">
          <base-card :accent="true" class="h-100">
            <b-row>
              <b-col cols="12">
                <h6>Регистраций</h6>
              </b-col>
              <b-col cols="12">
                <h6>
                  {{ data_statistics_user.toLocaleString() }}
                </h6>
              </b-col>
            </b-row>
          </base-card>
        </b-col>
        <b-col cols="12" md="2" class="mb-3">
          <base-card :accent="true" class="h-100">
            <b-row>
              <b-col cols="12">
                <h6>Проекты</h6>
              </b-col>
              <b-col cols="12">
                <h6>
                  {{ data_statistics_project.toLocaleString() }}
                </h6>
                <p class="small">
                  {{
                    (
                      (data_statistics_project / data_statistics_user) *
                      100
                    ).toLocaleString()
                  }}%
                </p>
              </b-col>
            </b-row>
          </base-card>
        </b-col>
        <b-col cols="12" md="2" class="mb-3">
          <base-card :accent="true" class="h-100">
            <b-row>
              <b-col cols="12">
                <h6>Проекты</h6>
                <p class="small">с 1ым созданным счетом</p>
              </b-col>
              <b-col cols="12">
                <h6>
                  {{
                    data_statistics_project_stat.count_created_.toLocaleString()
                  }}
                </h6>
              </b-col>
            </b-row>
          </base-card>
        </b-col>
        <b-col cols="12" md="2" class="mb-3">
          <base-card :accent="true" class="h-100">
            <b-row>
              <b-col cols="12">
                <h6>Проекты</h6>
                <p class="small">с 1ым удачным платежом</p>
              </b-col>
              <b-col cols="12">
                <h6>
                  {{
                    data_statistics_project_stat.count_paid_.toLocaleString()
                  }}
                </h6>
              </b-col>
            </b-row>
          </base-card>
        </b-col>
      </b-row>
      <b-row v-else align-h="center" align-v="center" class="h-100">
        <b-col cols="auto"> <b-spinner variant="light"></b-spinner></b-col>
      </b-row>
      <b-row class="mb-3" align-v="stretch" v-if="loading_stats">
        <b-col
          cols="12"
          md="2"
          class="mb-3"
          v-for="(item, name, index) of data_statistics"
          :key="index"
        >
          <base-card :accent="true" class="h-100">
            <b-row>
              <b-col cols="12">
                <h6>{{ name }}</h6>
              </b-col>
              <b-col cols="12">
                <h6>
                  {{ item.toLocaleString() }}
                </h6>
              </b-col>
            </b-row>
          </base-card>
        </b-col>
      </b-row>
      <b-row v-else align-h="center" align-v="center" class="h-100">
        <b-col cols="auto"> <b-spinner variant="light"></b-spinner></b-col>
      </b-row>
      <b-row class="mb-3" align-v="stretch" v-if="loading_stats_invoice">
        <b-col cols="12" md="2" class="mb-3">
          <base-card :accent="true" class="h-100">
            <b-row>
              <b-col cols="12">
                <h6>Всего создано</h6>
              </b-col>
              <b-col cols="12">
                <h6>
                  {{ data_statistics_invoice.created_invoice.toLocaleString() }}
                </h6>
              </b-col>
            </b-row>
          </base-card>
        </b-col>
        <b-col cols="12" md="2" class="mb-3">
          <base-card :accent="true" class="h-100">
            <b-row>
              <b-col cols="12">
                <h6>Подтверждено</h6>
              </b-col>
              <b-col cols="12">
                <h6>
                  {{ data_statistics_invoice.confirm_invoice.toLocaleString() }}
                </h6>
                <p class="small mb-0">
                  {{
                    (
                      (data_statistics_invoice.confirm_invoice /
                        data_statistics_invoice.created_invoice) *
                      100
                    ).toLocaleString()
                  }}
                  %
                </p>
              </b-col>
            </b-row>
          </base-card>
        </b-col>
        <b-col cols="12" md="2" class="mb-3">
          <base-card :accent="true" class="h-100">
            <b-row>
              <b-col cols="12">
                <h6>Оплачено</h6>
              </b-col>
              <b-col cols="12">
                <h6>
                  {{ data_statistics_invoice.paid_invoice.toLocaleString() }}
                </h6>
                <p class="small mb-0">
                  {{
                    (
                      (data_statistics_invoice.paid_invoice /
                        data_statistics_invoice.created_invoice) *
                      100
                    ).toLocaleString()
                  }}
                  %
                </p>
              </b-col>
            </b-row>
          </base-card>
        </b-col>
      </b-row>
      <b-row v-else align-h="center" align-v="center" class="h-100">
        <b-col cols="auto"> <b-spinner variant="light"></b-spinner></b-col>
      </b-row>
      <b-row>
        <b-col md="4">
          <base-card white>
            <b-row>
              <b-col cols="12">
                <h6>Создано счетов</h6>
              </b-col>
              <line-chart
                :data="chart_create"
                :curve="false"
                :points="false"
              ></line-chart>
            </b-row>
          </base-card>
        </b-col>
        <b-col md="4">
          <base-card white>
            <b-row>
              <b-col cols="12">
                <h6>Подтверждено счетов</h6>
              </b-col>
              <line-chart
                :data="chart_confirm"
                :curve="false"
                :stacked="true"
                :points="false"
              ></line-chart>
            </b-row>
          </base-card>
        </b-col>
        <b-col md="4">
          <base-card white>
            <b-row>
              <b-col cols="12">
                <h6>Оплачено счетов</h6>
              </b-col>
              <line-chart
                :data="chart_paid"
                :curve="false"
                :points="false"
              ></line-chart>
            </b-row>
          </base-card>
        </b-col>
        <b-col md="6">
          <base-card white>
            <b-row>
              <b-col cols="12">
                <h6>Оборот</h6>
              </b-col>
              <line-chart
                :data="chart_turnover"
                :curve="false"
                :points="false"
              ></line-chart>
            </b-row>
          </base-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <base-card white>
            <b-table striped hover :items="items"></b-table>
          </base-card>
        </b-col>
      </b-row>
    </b-container>

    <invoice-modal></invoice-modal>
  </div>
</template>

<script>
import moment from "moment";
import BaseCalendar from "../components/calendar/BaseCalendar.vue";
import BaseSelect from "../components/input/BaseSelect.vue";
import { httpClient } from "../HttpClientV1/http-client";
export default {
  name: "Home",
  data() {
    return {
      start: moment().startOf("month").format("DD.MM.YY"),
      end: moment().format("DD.MM.YY"),
      data_statistics: null,
      data_statistics_user: null,
      data_statistics_project: null,
      data_statistics_project_stat: null,
      data_statistics_invoice: null,
      data_commission: null,
      loading_stats: false,
      loading_stats_invoice: false,
      loading_stats_user: false,
      loading_commission: false,
      chart_confirm: {},
      chart_paid: {},
      chart_create: {},
      chart_turnover: {},
      items: null,
    };
  },
  methods: {
    setDataFilter(data) {
      this.start = data.start;
      this.end = data.end;
      this.loadStatisticsUser();
      this.loadStatistics();
      this.loadStatisticsInvoice();
    },
    async getGraphicDataConfirm() {
      this.max_amount = 0;
      const response = await httpClient.get(
        `/v1/dashboard/statistics/invoice/chart/confirm`
      );
      for (let item in response.data.days) {
        if (response.data.days.length - 1 > item) {
          let key = response.data.days[item];
          let value = response.data.sum[item];
          let line = new Object();
          line[key] = value;
          this.chart_confirm = { ...this.chart_confirm, ...line };
        }
      }
    },
    async getGraphicDataPaid() {
      this.max_amount = 0;
      const response = await httpClient.get(
        `/v1/dashboard/statistics/invoice/chart/paid`
      );
      for (let item in response.data.days) {
        if (response.data.days.length - 1 > item) {
          let key = response.data.days[item];
          let value = response.data.sum[item];
          let line = new Object();
          line[key] = value;
          this.chart_paid = { ...this.chart_paid, ...line };
        }
      }
    },
    async getGraphicDataCreate() {
      this.max_amount = 0;
      const response = await httpClient.get(
        `/v1/dashboard/statistics/invoice/chart/create`
      );
      for (let item in response.data.days) {
        if (response.data.days.length - 1 > item) {
          let key = response.data.days[item];
          let value = response.data.sum[item];
          let line = new Object();
          line[key] = value;
          this.chart_create = { ...this.chart_create, ...line };
        }
      }
    },
    async getGraphicDataTurnover() {
      this.max_amount = 0;
      const response = await httpClient.get(
        `/v1/dashboard/statistics/invoice/chart/turnover`
      );
      for (let item in response.data.days) {
        let key = response.data.days[item];
        let value = response.data.sum[item];
        let line = new Object();
        line[key] = value;
        this.chart_turnover = { ...this.chart_turnover, ...line };
      }
    },
    async getGraphicDatatable() {
      this.max_amount = 0;
      const response = await httpClient.get(
        `/v1/dashboard/statistics/invoice/table`
      );
      this.items = response.data;
    },
    loadStatistics() {
      this.loading_stats = false;
      httpClient
        .get(
          "v1/dashboard/statistics/turnover/date?start=" +
            this.start +
            "&end=" +
            this.end
        )
        .then((response) => {
          this.data_statistics = response.data;
          this.loading_stats = true;
        });
    },
    loadStatisticsInvoice() {
      this.loading_stats_invoice = false;
      httpClient
        .get(
          "v1/dashboard/statistics/invoice/created/count?start=" +
            this.start +
            "&end=" +
            this.end
        )
        .then((response) => {
          this.data_statistics_invoice = response.data;
          this.loading_stats_invoice = true;
        });
    },
    loadStatisticsUser() {
      this.loading_stats_user = false;
      httpClient
        .get(
          "v1/user/dashboard/registration/count?start=" +
            this.start +
            "&end=" +
            this.end
        )
        .then((result) => {
          httpClient
            .get(
              "v1/user/dashboard/project/count?start=" +
                this.start +
                "&end=" +
                this.end
            )
            .then((response) => {
              httpClient
                .get(
                  "v1/user/dashboard/project/count/invoice?start=" +
                    this.start +
                    "&end=" +
                    this.end
                )
                .then((result_2) => {
                  this.data_statistics_user = result.data;
                  this.data_statistics_project = response.data;
                  this.data_statistics_project_stat = result_2.data;
                  this.loading_stats_user = true;
                });
            });
        });
    },
  },
  mounted() {
    this.loadStatistics();
    this.loadStatisticsInvoice();
    this.loadStatisticsUser();
    this.getGraphicDataConfirm();
    this.getGraphicDataPaid();
    this.getGraphicDataCreate();
    this.getGraphicDataTurnover();
    this.getGraphicDatatable();
  },
  components: { BaseCalendar, BaseSelect },
};
</script>
