<template>
    <div class="button__wrapper"> 
      <button class="base__btn" :class="variant" v-bind="$attrs" 
          v-on="listeners">
              <slot>
              </slot>
      </button>
    </div>
</template>
<script>
export default {
    inheritAttrs: false,
    name: "base-button",
    props: {
      variant: {
        type: [String, Number],
        description: "Вариант кнопки",
        default: 'primary'
      },
    },
    computed:{
      listeners() {
        return {
            ...this.$listeners,
            click: this.onClick
        }
      }
    },
    methods: {
      onClick(evt) {
        this.$emit('click', evt.target.value)
      },
    }
}
</script>
<style scoped>

</style>