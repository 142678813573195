import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Users from "../views/Users.vue";
import Login from "../views/Login.vue";
import Commission from "../views/Commission.vue";
import Statistics from "../views/Statistics.vue";
import Articles from "../views/ArticleList.vue";
import ContentNewsLetters from "../views/ContentNewsLetters.vue";
import ArticlesDetails from "../views/ArticlesDetails.vue";
import ArticlesAdd from "../views/ArticlesAdd.vue";
import ArticlesEditorDetails from "../views/ArticlesEditor.vue";
import ArticlesEdit from "../views/ArticlesEdit.vue";
import Support from "../views/Support.vue";
import Invoices from "../views/Invoices.vue";
import Ledger from "../views/Ledger.vue";

import store from "../store";
Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/",
    name: "Home",
    component: ContentNewsLetters,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/users",
    name: "Users",
    component: Users,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/commission",
    name: "Commission",
    component: Commission,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/statistics",
    name: "Statistics",
    component: Statistics,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/content",
    name: "ContentNewsLetters",
    component: ContentNewsLetters,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/content/articles",
    name: "Articles",
    component: Articles,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/content/articles/add",
    name: "ArticlesAdd",
    component: ArticlesAdd,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/content/articles/:id",
    name: "ArticlesDetails",
    component: ArticlesDetails,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/content/articles/:id/editor",
    name: "ArticlesEditorDetails",
    component: ArticlesEditorDetails,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/content/articles/:id/edit",
    name: "ArticlesEdit",
    component: ArticlesEdit,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/support",
    name: "Support",
    component: Support,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/invoices",
    name: "Invocies",
    component: Invoices,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/ledger",
    name: "Ledger",
    component: Ledger,
    meta: {
      requiresAuth: true,
    },
  },
];

const router = new VueRouter({
  routes,
  mode: "history",
  linkActiveClass: "active",
});
router.beforeEach((to, from, next) => {
  console.log(store.getters["isLoggedIn"]);
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters["isLoggedIn"]) {
      // let roles = store.getters["get_roles"];
      // let list_email = store.getters["get_list_email"];
      // store.dispatch("fetchUser").then((response) => {
      //   let email = response.data.email;
      //   for (let item of list_email) {
      //     if (item.email === email) {
      //       if (item.rules == "admin") {
      //         next();
      //       } else {
      //         let rules_list = roles[item.rules];
      //         if (rules_list.includes(to.name)) {
      //           next();
      //         } else {
      //           next({ name: rules_list[0] });
      //         }
      //       }
      //     }
      //   }
      // });
      next();
    } else {
      next("/login");
    }
  } else {
    next();
  }
});
export default router;
