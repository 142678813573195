<template>
  <b-modal
    id="invoice-modal"
    ref="invoice-modal"
    title="BootstrapVue"
    scrollable
    hide-footer
    hide-backdrop
    centered
  >
    <p class="my-4">Hello from modal!</p>
  </b-modal>
</template>
<script>
export default {
  name: 'invoice-modal'
}
</script>