import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import SideBar from "./components/navbar/BaseSidebar";
import Card from "./components/card/BaseCard";
import RightSideBar from "./components/navbar/RightSidebar";
import ChatCard from "./components/card/ChatCard";
import InvoiceModal from "./components/modal/ModalInvoice";
// import * as VueUiKit from "vue-demo-uikit";
import VCalendar from "v-calendar";
import Chartkick from "vue-chartkick";
import Highcharts from "highcharts";
import VueQuillEditor from "vue-quill-editor";
import "quill/dist/quill.core.css"; // import styles
import "quill/dist/quill.snow.css"; // for snow theme
import "quill/dist/quill.bubble.css"; // for bubble theme

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "./assets/css/app.css";
import "vue-demo-uikit/lib/vue-demo-uikit.css";
import * as components from "vue-demo-uikit";

Object.entries(components).forEach(([name, component]) => {
  Vue.component(name, component);
});
Vue.use(BootstrapVue);
// Vue.use(VueUiKit);
Vue.use(IconsPlugin);
Vue.use(VueQuillEditor /* { default global options } */);
Vue.component("right-sidebar", RightSideBar);
Vue.component("invoice-modal", InvoiceModal);
Vue.component("base-sidebar", SideBar);
Vue.component("chat-card", ChatCard);
Vue.component("base-card", Card);
// Vue.component("CCButton", CCButton);

Vue.use(VCalendar, {
  componentPrefix: "v",
});
Chartkick.options = {
  colors: ["#5B40C7", "#B7B3DF"],
  backgroundColor: "#6a6a6a30",
};
Vue.use(Chartkick.use(Highcharts));
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
