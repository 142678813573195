<template>
  <div class="content-block pe-0">
    <b-container>
      <div class="notification-wrapper">
        <span
          class="notification-item show"
          v-for="(item, index) in notification"
          :key="index"
          :class="item.type"
          >{{ item.text }}</span
        >
      </div>
      <b-row class="pt-4 h-100" align-v="center">
        <b-col>
          <h4>Новая статья</h4>
        </b-col>
        <!-- <b-col cols="auto">
          <base-select
            :options="all_tags"
            :selectedOptions="tags"
            v-model="tags"
            @change="addUserTag"
            label="Добавить тэги"
            :blur="true"
          ></base-select>
        </b-col> -->
      </b-row>
      <b-row v-if="tags.length > 0">
        <b-col
          cols="auto"
          class="px-2"
          v-for="(item, index) in tags"
          :key="index"
        >
          <base-card class="p-1 px-3 border-0 context" :accent="true">
            <b-row align-h="between" align-v="center">
              <b-col cols="auto">{{ item.name }}</b-col>
              <b-col cols="auto" class="p-0">
                <img
                  class="pointer"
                  @click="addUserTag(item)"
                  src="@/assets/img/icon/Solid/Interface/Cross.svg"
              /></b-col> </b-row
          ></base-card>
        </b-col>
      </b-row>

      <!-- <b-row v-else>
        <b-col cols="auto">
          <p class="small">Здесь появяться тэги, когда ты их выберешь</p>
        </b-col>
      </b-row> -->
      <b-row class="mb-2 align-content-stretch h-100">
        <b-col cols="12">
          <base-card>
            <b-row>
              <b-col cols="12">
                <b-row align-h="between">
                  <b-col cols="6">
                    <h4>Основные данные</h4>
                  </b-col>
                  <b-col cols="auto">
                    <base-select
                      :label="article_status.name"
                      :options="options_status"
                      :selectedOptions="article_status"
                      @change="selectStatus"
                      :blur="false"
                    ></base-select>
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="6">
                <base-input
                  v-model="title"
                  @input="generateSlugArticle"
                  label="Заголовок статьи"
                  placeholder="Напиши сюда заголовок статьи, он будет отображаться в списке статей..."
                >
                </base-input>
              </b-col>
              <b-col cols="6">
                <base-input
                  v-model="titleH1"
                  label="Заголовок H1"
                  placeholder="Напиши сюда заголовок статьи, он будет отображаться на странице статьи..."
                >
                </base-input>
              </b-col>
              <b-col cols="6" class="position-relative">
                <base-input
                  v-model="slug"
                  label="Ссылка на статью"
                  placeholder="Ссылка на статью, к ней добавить /blog/{ссылка}"
                  :readonly="!hand_slug"
                >
                </base-input>

                <b-badge
                  variant="success"
                  class="btn-input pointer"
                  v-if="!hand_slug"
                  @click="toggleHandSlug"
                  >Указать руками</b-badge
                >
                <b-badge
                  variant="warning"
                  class="btn-input pointer"
                  @click="toggleHandSlug"
                  v-else
                  >Автоматически</b-badge
                >
              </b-col>
              <b-col cols="6">
                <base-input
                  v-model="time_to_read"
                  label="Время на чтение"
                  placeholder="Напиши сюда сколько примерно необходимо потратить на чтение статьи..."
                >
                </base-input>
              </b-col>
              <b-col cols="6">
                <base-input
                  v-model="keywords"
                  label="Ключевые слова"
                  placeholder="Укажи ключевые слова через запятую, это нужно для СЕО"
                >
                </base-input>
              </b-col>
              <b-col cols="6">
                <base-input
                  v-model="meta_description"
                  label="Описание"
                  placeholder="Короткое описание 160-180 символов"
                >
                </base-input>
              </b-col>
              <b-col cols="6">
                <v-date-picker
                  v-model="published_date"
                  mode="date"
                  is24hr
                  :model-config="modelConfig"
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <base-input
                      label="Дата публикации"
                      placeholder="Укажи дату и время когда статья будет доступна(только в статусе опубликована)"
                      class="rounded focus:outline-none focus:border-blue-300"
                      :value="inputValue"
                      v-on="inputEvents"
                    />
                  </template>
                </v-date-picker>
              </b-col>
              <b-col cols="6">
                <img
                  src=""
                  alt=""
                  id="image_drop_area"
                  width="100%"
                  class="mt-3"
                />
                <b-form-file
                  class="mt-4 px-3"
                  v-model="cover_file"
                  plain
                  @input="uploadCover"
                ></b-form-file>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="3" class="mt-3">
                <base-button class="py-0" @click="createArticle"
                  >Добавить</base-button
                >
              </b-col>
            </b-row>
          </base-card>
        </b-col>
      </b-row>
      <b-modal
        id="tags-modal"
        ref="tags-modal"
        title="Создать Тэг"
        scrollable
        hide-footer
      >
        <b-row class="mt-3" align-h="center">
          <b-col cols="12" class="px-0">
            <base-input
              placeholder="Название"
              v-model="nameTag"
              @input="generateCodeTag"
            ></base-input>
          </b-col>
          <b-col cols="12" class="px-0">
            <base-input
              placeholder="Код тэга"
              v-model="codeTag"
              readonly
            ></base-input>
          </b-col>
          <b-col cols="8">
            <base-button @click="addNewTag">Добавить</base-button>
          </b-col>
        </b-row>
      </b-modal>
    </b-container>
  </div>
</template>

<script>
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import BaseCalendar from "../components/calendar/BaseCalendar.vue";
import BaseCard from "../components/card/BaseCard.vue";
import { httpClient } from "../HttpClientV1/http-client";
import BaseInput from "../components/input/BaseInput.vue";
import BaseButton from "../components/button/BaseButton.vue";
import HTMLEditer from "../components/editer/HTMLEditer.vue";
import DropdownMenu from "../components/editer/DropdownMenu.vue";

import eventBus from "../EventBus";
import BaseSelect from "../components/input/BaseSelect.vue";
export default {
  name: "Payments",
  components: {
    BaseCalendar,
    BaseCard,
    BaseInput,
    BaseButton,
    HTMLEditer,
    DropdownMenu,
    BaseSelect,
  },
  data() {
    return {
      hand_slug: true,
      start: moment().startOf("isoWeek").format("DD.MM.YY"),
      end: moment().format("DD.MM.YY"),
      slug: null,
      title: null,
      titleH1: null,
      time_to_read: null,
      keywords: null,
      meta_description: null,
      cover_file: null,
      tags: [],
      article_status: {
        name: "Черновик",
        value: "draft",
      },
      options_status: [
        {
          name: "Черновик",
          value: "draft",
        },
        {
          name: "На проверке",
          value: "review",
        },
        {
          name: "Опубликована",
          value: "published",
        },
      ],
      modelConfig: {
        type: "string",
        mask: "DD.MM.YY", // Uses 'iso' if missing
      },
      all_tags: [],
      codeTag: null,
      nameTag: null,
      published_date: null,
      cover: null,
      notification: [],
    };
  },
  methods: {
    ...mapActions(["toggleCalendar"]),
    toggleHandSlug() {
      this.hand_slug = !this.hand_slug;
      if (!this.hand_slug) {
        this.slug = window.URLify(this.title);
      }
    },
    createArticle() {
      let article_data = {
        title: this.title,
        titleH1: this.titleH1,
        time_to_read: this.time_to_read,
        slug: this.slug,
        meta_description: this.meta_description,
        status: this.article_status.value,
        published_date: this.published_date,
        keywords: this.keywords,
        cover: this.cover,
      };
      httpClient
        .post("/api/blog/dashboard/article/create", { article_data })
        .then((response) => {
          this.$router
            .push("/content/articles/" + response.data.article_id)
            .then(() => {
              this.notification.push({
                text: "Сохранено",
                type: "success",
              });
              let self = this;
              setTimeout(() => {
                self.notification = [];
                if (go_edir) {
                  self.$router.push(
                    "/content/articles/" + this.$route.params.id
                  );
                }
              }, 2000);
            });
        });
    },
    uploadCover() {
      let formData = new FormData();
      formData.append("file", this.cover_file);
      httpClient
        .post("/api/blog/dashboard/article/upload", formData)
        .then((response) => {
          document
            .querySelector("#image_drop_area")
            .setAttribute(
              "src",
              "https://api.cryptocloud.plus/media/" + response.data
            );
          this.cover = response.data;
        });
    },
    selectStatus(item) {
      this.article_status = item;
    },
    generateSlugArticle() {
      if (!this.hand_slug) {
        this.slug = window.URLify(this.title);
      }
    },
    generateCodeTag() {
      this.codeTag = window.URLify(this.nameTag);
    },
    addNewTag() {
      let tag = {
        name: this.nameTag,
        code: this.codeTag,
      };
      httpClient
        .post("v1/blog/dashboard/tags/create", { tag })
        .then((response) => {
          this.all_tags = response.data;
          this.all_tags.push({
            id: this.all_tags.length + 1,
            name: "Создать новый",
            code: "add_new_tag",
          });
        });
    },
    getAllTags() {
      this.all_tags.push({
        name: "Создать новый",
        code: "add_new_tag",
      });
      httpClient.get("v1/blog/dashboard/tags/all").then((response) => {
        this.all_tags = [...this.all_tags, ...response.data];
      });
    },
    addUserTag(item) {
      if (item.code == "add_new_tag") {
        this.$root.$emit("bv::show::modal", "tags-modal", "#tags-modal");
      } else {
        if (this.tags.includes(item)) {
          const findedOption = this.tags.find(
            (opt) => opt.code.toUpperCase() === item.code.toUpperCase()
          );
          let myIndex = this.tags.indexOf(findedOption);
          if (myIndex !== -1) {
            let data = {
              id: this.id,
              tag: item.code,
            };
            httpClient
              .post("v1/blog/dashboard/tags/unbind", data)
              .then((response) => {
                this.getAllUserTags();
              });
          }
        } else {
          let data = {
            id: this.id,
            tag: item.code,
          };
          httpClient
            .post("v1/blog/dashboard/tags/bind", data)
            .then((response) => {
              this.getAllUserTags();
            });
        }
      }
    },
  },
  mounted() {
    const RUSSIAN_MAP = {
      а: "a",
      б: "b",
      в: "v",
      г: "g",
      д: "d",
      е: "e",
      ё: "yo",
      ж: "zh",
      з: "z",
      и: "i",
      й: "j",
      к: "k",
      л: "l",
      м: "m",
      н: "n",
      о: "o",
      п: "p",
      р: "r",
      с: "s",
      т: "t",
      у: "u",
      ф: "f",
      х: "h",
      ц: "c",
      ч: "ch",
      ш: "sh",
      щ: "sh",
      ъ: "",
      ы: "y",
      ь: "",
      э: "e",
      ю: "yu",
      я: "ya",
      А: "A",
      Б: "B",
      В: "V",
      Г: "G",
      Д: "D",
      Е: "E",
      Ё: "Yo",
      Ж: "Zh",
      З: "Z",
      И: "I",
      Й: "J",
      К: "K",
      Л: "L",
      М: "M",
      Н: "N",
      О: "O",
      П: "P",
      Р: "R",
      С: "S",
      Т: "T",
      У: "U",
      Ф: "F",
      Х: "H",
      Ц: "C",
      Ч: "Ch",
      Ш: "Sh",
      Щ: "Sh",
      Ъ: "",
      Ы: "Y",
      Ь: "",
      Э: "E",
      Ю: "Yu",
      Я: "Ya",
    };
    const ALL_DOWNCODE_MAPS = [RUSSIAN_MAP];
    const Downcoder = {
      Initialize: function () {
        if (Downcoder.map) {
          // already made
          return;
        }
        Downcoder.map = {};
        for (const lookup of ALL_DOWNCODE_MAPS) {
          Object.assign(Downcoder.map, lookup);
        }
        Downcoder.regex = new RegExp(Object.keys(Downcoder.map).join("|"), "g");
      },
    };
    function downcode(slug) {
      Downcoder.Initialize();
      return slug.replace(Downcoder.regex, function (m) {
        return Downcoder.map[m];
      });
    }
    function URLify(s, num_chars, allowUnicode) {
      // changes, e.g., "Petty theft" to "petty-theft"
      if (!allowUnicode) {
        s = downcode(s);
      }
      s = s.toLowerCase(); // convert to lowercase
      // if downcode doesn't hit, the char will be stripped here
      if (allowUnicode) {
        // Keep Unicode letters including both lowercase and uppercase
        // characters, whitespace, and dash; remove other characters.
        s = XRegExp.replace(s, XRegExp("[^-_\\p{L}\\p{N}\\s]", "g"), "");
      } else {
        s = s.replace(/[^-\w\s]/g, ""); // remove unneeded chars
      }
      s = s.replace(/^\s+|\s+$/g, ""); // trim leading/trailing spaces
      s = s.replace(/[-\s]+/g, "-"); // convert spaces to hyphens
      s = s.substring(0, num_chars); // trim to first num_chars chars
      s = s.replace(/-+$/g, ""); // trim any trailing hyphens
      return s;
    }
    window.URLify = URLify;

    this.getAllTags();
  },
};
</script>
