<template>
  <div class="content-block pe-0">
    <b-container>
      <b-row class="pt-4">
        <b-col>
          <h1>Финансы</h1>
        </b-col>
        <b-col md="auto" class="mt-3">
          <BaseCalendar
            :dateStart="start"
            :dateEnd="end"
            @submitFilterData="setDataFilter"
          >
          </BaseCalendar>
        </b-col>
      </b-row>
      <b-row class="mb-3" align-v="stretch">
        <b-col cols="12" md="3" class="mb-3">
          <base-card :accent="true" class="h-100">
            <b-row v-if="loading_stats">
              <b-col cols="12">
                <h6>Тотал оборот</h6>
              </b-col>
              <b-col cols="12">
                <h4>
                  $
                  {{
                    (
                      data_statistics.btc.balance_usd_debit +
                      data_statistics.ltc.balance_usd_debit +
                      data_statistics.eth.balance_usd_debit +
                      data_statistics.usdt.balance_usd_debit
                    ).toLocaleString()
                  }}
                </h4>
              </b-col>
            </b-row>
            <b-row v-else align-h="center" align-v="center" class="h-100">
              <b-col cols="auto">
                <b-spinner variant="light"></b-spinner
              ></b-col>
            </b-row>
          </base-card>
        </b-col>
        <b-col cols="12" md="3" class="mb-3">
          <base-card :accent="true" class="h-100">
            <b-row v-if="loading_stats">
              <b-col cols="12">
                <h6>Тотал комиссии</h6>
              </b-col>
              <b-col cols="12">
                <h4>
                  $
                  {{
                    (
                      data_statistics.btc.balance_usd_commission +
                      data_statistics.ltc.balance_usd_commission +
                      data_statistics.eth.balance_usd_commission +
                      data_statistics.usdt.balance_usd_commission
                    ).toLocaleString()
                  }}
                </h4>
              </b-col>
            </b-row>
            <b-row v-else align-h="center" align-v="center" class="h-100">
              <b-col cols="auto">
                <b-spinner variant="light"></b-spinner
              ></b-col>
            </b-row>
          </base-card>
        </b-col>
        <b-col cols="12" md="3" class="mb-3">
          <base-card :accent="true" class="h-100">
            <b-row v-if="loading_commission">
              <b-col cols="12">
                <h6>Тотал комиссии к выводу</h6>
              </b-col>
              <b-col cols="12">
                <h4>
                  $
                  {{
                    (
                      data_commission.btc.usd_commission +
                      data_commission.ltc.usd_commission +
                      data_commission.eth.usd_commission +
                      data_commission.usdt.usd_commission
                    ).toLocaleString()
                  }}
                </h4>
              </b-col>
            </b-row>
            <b-row v-else align-h="center" align-v="center" class="h-100">
              <b-col cols="auto">
                <b-spinner variant="light"></b-spinner
              ></b-col>
            </b-row>
          </base-card>
        </b-col>
        <b-col cols="12" md="3" class="mb-3">
          <base-card :accent="true" class="h-100">
            <b-row v-if="loading_commission">
              <b-col cols="12">
                <h6>Тотал комиссии выведено</h6>
              </b-col>
              <b-col cols="12">
                <h4>
                  $
                  {{
                    (
                      data_commission.btc.paid_usd_commission +
                      data_commission.ltc.paid_usd_commission +
                      data_commission.eth.paid_usd_commission +
                      data_commission.usdt.paid_usd_commission
                    ).toLocaleString()
                  }}
                </h4>
              </b-col>
            </b-row>
            <b-row v-else align-h="center" align-v="center" class="h-100">
              <b-col cols="auto">
                <b-spinner variant="light"></b-spinner
              ></b-col>
            </b-row>
          </base-card>
        </b-col>
        <b-col cols="12" md="3">
          <base-card :accent="false" class="h-100">
            <b-row v-if="loading_stats">
              <b-col cols="12">
                <h6>Оборот в BTC</h6>
              </b-col>
              <b-col cols="12">
                <h4>
                  {{ data_statistics.btc.balance_debit.toLocaleString() }}
                </h4>
                <p class="mb-0">
                  ${{ data_statistics.btc.balance_usd_debit.toLocaleString() }}
                </p>
              </b-col>
            </b-row>
            <b-row v-else align-h="center" align-v="center" class="h-100">
              <b-col cols="auto">
                <b-spinner variant="light"></b-spinner
              ></b-col>
            </b-row>
          </base-card>
        </b-col>
        <b-col cols="12" md="3">
          <base-card class="h-100">
            <b-row v-if="loading_stats">
              <b-col cols="12">
                <h6>Оборот в LTC</h6>
              </b-col>
              <b-col cols="12">
                <h4>
                  {{ data_statistics.ltc.balance_debit.toLocaleString() }}
                </h4>
                <p class="mb-0">
                  ${{ data_statistics.ltc.balance_usd_debit.toLocaleString() }}
                </p>
              </b-col>
            </b-row>
            <b-row v-else align-h="center" align-v="center" class="h-100">
              <b-col cols="auto">
                <b-spinner variant="light"></b-spinner
              ></b-col>
            </b-row>
          </base-card>
        </b-col>
        <b-col cols="12" md="3">
          <base-card class="h-100">
            <b-row v-if="loading_stats">
              <b-col cols="12">
                <h6>Оборот в ETH</h6>
              </b-col>
              <b-col cols="12">
                <h4>
                  {{ data_statistics.eth.balance_debit.toLocaleString() }}
                </h4>
                <p class="mb-0">
                  ${{ data_statistics.eth.balance_usd_debit.toLocaleString() }}
                </p>
              </b-col>
            </b-row>
            <b-row v-else align-h="center" align-v="center" class="h-100">
              <b-col cols="auto">
                <b-spinner variant="light"></b-spinner
              ></b-col>
            </b-row>
          </base-card>
        </b-col>
        <b-col cols="12" md="3">
          <base-card class="h-100">
            <b-row v-if="loading_stats">
              <b-col cols="12">
                <h6>Оборот в USDT</h6>
              </b-col>
              <b-col cols="12">
                <h4>
                  {{ data_statistics.usdt.balance_debit.toLocaleString() }}
                </h4>
                <p class="mb-0">
                  ${{ data_statistics.usdt.balance_usd_debit.toLocaleString() }}
                </p>
              </b-col>
            </b-row>
            <b-row v-else align-h="center" align-v="center" class="h-100">
              <b-col cols="auto">
                <b-spinner variant="light"></b-spinner
              ></b-col>
            </b-row>
          </base-card>
        </b-col>
      </b-row>
      <b-row class="mb-3" align-v="stretch">
        <b-col cols="12" md="3">
          <base-card :accent="false" class="h-100">
            <b-row v-if="loading_stats">
              <b-col cols="12">
                <h6>Комиссия в BTC</h6>
              </b-col>
              <b-col cols="12">
                <h4>
                  {{ data_statistics.btc.balance_commission.toLocaleString() }}
                </h4>
                <p class="mb-0">
                  ${{
                    data_statistics.btc.balance_usd_commission.toLocaleString()
                  }}
                </p>
              </b-col>
            </b-row>
            <b-row v-else align-h="center" align-v="center" class="h-100">
              <b-col cols="auto">
                <b-spinner variant="light"></b-spinner
              ></b-col>
            </b-row>
          </base-card>
        </b-col>
        <b-col cols="12" md="3">
          <base-card class="h-100">
            <b-row v-if="loading_stats">
              <b-col cols="12">
                <h6>Комиссия в LTC</h6>
              </b-col>
              <b-col cols="12">
                <h4>
                  {{ data_statistics.ltc.balance_commission.toLocaleString() }}
                </h4>
                <p class="mb-0">
                  ${{
                    data_statistics.ltc.balance_usd_commission.toLocaleString()
                  }}
                </p>
              </b-col>
            </b-row>
            <b-row v-else align-h="center" align-v="center" class="h-100">
              <b-col cols="auto">
                <b-spinner variant="light"></b-spinner
              ></b-col>
            </b-row>
          </base-card>
        </b-col>
        <b-col cols="12" md="3">
          <base-card class="h-100">
            <b-row v-if="loading_stats">
              <b-col cols="12">
                <h6>Комиссия в ETH</h6>
              </b-col>
              <b-col cols="12">
                <h4>
                  {{ data_statistics.eth.balance_commission.toLocaleString() }}
                </h4>
                <p class="mb-0">
                  ${{
                    data_statistics.eth.balance_usd_commission.toLocaleString()
                  }}
                </p>
              </b-col>
            </b-row>
            <b-row v-else align-h="center" align-v="center" class="h-100">
              <b-col cols="auto">
                <b-spinner variant="light"></b-spinner
              ></b-col>
            </b-row>
          </base-card>
        </b-col>
        <b-col cols="12" md="3">
          <base-card class="h-100">
            <b-row v-if="loading_stats">
              <b-col cols="12">
                <h6>Комиссия в USDT</h6>
              </b-col>
              <b-col cols="12">
                <h4>
                  {{ data_statistics.usdt.balance_commission.toLocaleString() }}
                </h4>
                <p class="mb-0">
                  ${{
                    data_statistics.usdt.balance_usd_commission.toLocaleString()
                  }}
                </p>
              </b-col>
            </b-row>
            <b-row v-else align-h="center" align-v="center" class="h-100">
              <b-col cols="auto">
                <b-spinner variant="light"></b-spinner
              ></b-col>
            </b-row>
          </base-card>
        </b-col>
      </b-row>
      <hr />
      <b-row class="mb-3" align-v="stretch">
        <b-col cols="12" md="3">
          <base-card :accent="false" class="h-100">
            <b-row v-if="loading_commission">
              <b-col cols="12">
                <h6>К выводу комиссии</h6>
              </b-col>
              <b-col cols="12">
                <h4>
                  {{ data_commission.btc.commission.toLocaleString() }} BTC
                </h4>
                <p class="mb-0">
                  ${{ data_commission.btc.usd_commission.toLocaleString() }}
                </p>
              </b-col>
            </b-row>
            <b-row v-else align-h="center" align-v="center" class="h-100">
              <b-col cols="auto">
                <b-spinner variant="light"></b-spinner
              ></b-col>
            </b-row>
          </base-card>
        </b-col>
        <b-col cols="12" md="3">
          <base-card class="h-100">
            <b-row v-if="loading_commission">
              <b-col cols="12">
                <h6>К выводу комиссии</h6>
              </b-col>
              <b-col cols="12">
                <h4>
                  {{ data_commission.ltc.commission.toLocaleString() }} LTC
                </h4>
                <p class="mb-0">
                  ${{ data_commission.ltc.usd_commission.toLocaleString() }}
                </p>
              </b-col>
            </b-row>
            <b-row v-else align-h="center" align-v="center" class="h-100">
              <b-col cols="auto">
                <b-spinner variant="light"></b-spinner
              ></b-col>
            </b-row>
          </base-card>
        </b-col>
        <b-col cols="12" md="3">
          <base-card class="h-100">
            <b-row v-if="loading_commission">
              <b-col cols="12">
                <h6>К выводу комиссии</h6>
              </b-col>
              <b-col cols="12">
                <h4>
                  {{ data_commission.eth.commission.toLocaleString() }} ETH
                </h4>
                <p class="mb-0">
                  ${{ data_commission.eth.usd_commission.toLocaleString() }}
                </p>
              </b-col>
            </b-row>
            <b-row v-else align-h="center" align-v="center" class="h-100">
              <b-col cols="auto">
                <b-spinner variant="light"></b-spinner
              ></b-col>
            </b-row>
          </base-card>
        </b-col>
        <b-col cols="12" md="3">
          <base-card class="h-100">
            <b-row v-if="loading_commission">
              <b-col cols="12">
                <h6>К выводу комиссии</h6>
              </b-col>
              <b-col cols="12">
                <h4>
                  {{ data_commission.usdt.commission.toLocaleString() }} USDT
                </h4>
                <p class="mb-0">
                  ${{ data_commission.usdt.usd_commission.toLocaleString() }}
                </p>
              </b-col>
            </b-row>
            <b-row v-else align-h="center" align-v="center" class="h-100">
              <b-col cols="auto">
                <b-spinner variant="light"></b-spinner
              ></b-col>
            </b-row>
          </base-card>
        </b-col>
      </b-row>
      <b-row class="mb-3" align-v="stretch">
        <b-col cols="12" md="3">
          <base-card :accent="false" class="h-100">
            <b-row v-if="loading_commission">
              <b-col cols="12">
                <h6>Выведено комиссии</h6>
              </b-col>
              <b-col cols="12">
                <h4>
                  {{ data_commission.btc.paid_commission.toLocaleString() }} BTC
                </h4>
                <p class="mb-0">
                  ${{
                    data_commission.btc.paid_usd_commission.toLocaleString()
                  }}
                </p>
              </b-col>
            </b-row>
            <b-row v-else align-h="center" align-v="center" class="h-100">
              <b-col cols="auto">
                <b-spinner variant="light"></b-spinner
              ></b-col>
            </b-row>
          </base-card>
        </b-col>
        <b-col cols="12" md="3">
          <base-card class="h-100">
            <b-row v-if="loading_commission">
              <b-col cols="12">
                <h6>Выведено комиссии</h6>
              </b-col>
              <b-col cols="12">
                <h4>
                  {{ data_commission.ltc.paid_commission.toLocaleString() }} LTC
                </h4>
                <p class="mb-0">
                  ${{
                    data_commission.ltc.paid_usd_commission.toLocaleString()
                  }}
                </p>
              </b-col>
            </b-row>
            <b-row v-else align-h="center" align-v="center" class="h-100">
              <b-col cols="auto">
                <b-spinner variant="light"></b-spinner
              ></b-col>
            </b-row>
          </base-card>
        </b-col>
        <b-col cols="12" md="3">
          <base-card class="h-100">
            <b-row v-if="loading_commission">
              <b-col cols="12">
                <h6>Выведено комиссии</h6>
              </b-col>
              <b-col cols="12">
                <h4>
                  {{ data_commission.eth.paid_commission.toLocaleString() }} ETH
                </h4>
                <p class="mb-0">
                  ${{
                    data_commission.eth.paid_usd_commission.toLocaleString()
                  }}
                </p>
              </b-col>
            </b-row>
            <b-row v-else align-h="center" align-v="center" class="h-100">
              <b-col cols="auto">
                <b-spinner variant="light"></b-spinner
              ></b-col>
            </b-row>
          </base-card>
        </b-col>
        <b-col cols="12" md="3">
          <base-card class="h-100">
            <b-row v-if="loading_commission">
              <b-col cols="12">
                <h6>Выведено комиссии</h6>
              </b-col>
              <b-col cols="12">
                <h4>
                  {{ data_commission.usdt.paid_commission.toLocaleString() }}
                  USDT
                </h4>
                <p class="mb-0">
                  ${{
                    data_commission.usdt.paid_usd_commission.toLocaleString()
                  }}
                </p>
              </b-col>
            </b-row>
            <b-row v-else align-h="center" align-v="center" class="h-100">
              <b-col cols="auto">
                <b-spinner variant="light"></b-spinner
              ></b-col>
            </b-row>
          </base-card>
        </b-col>
      </b-row>
    </b-container>

    <invoice-modal></invoice-modal>
  </div>
</template>

<script>
import moment from "moment";
import BaseCalendar from "../components/calendar/BaseCalendar.vue";
import BaseSelect from "../components/input/BaseSelect.vue";
import { httpClient } from "../HttpClientV1/http-client";
export default {
  name: "Home",
  data() {
    return {
      start: moment().startOf("month").format("DD.MM.YY"),
      end: moment().format("DD.MM.YY"),
      data_statistics: null,
      data_commission: null,
      loading_stats: false,
      loading_commission: false,
    };
  },
  methods: {
    setDataFilter(data) {
      this.start = data.start;
      this.end = data.end;
      this.loadStatistics();
      this.loadStatisticsCommission();
    },
    loadStatistics() {
      this.loading_stats = false;
      httpClient
        .get(
          "v1/dashboard/ledger/statistics/period?start=" +
            this.start +
            "&end=" +
            this.end
        )
        .then((response) => {
          this.data_statistics = response.data;
          this.loading_stats = true;
        });
    },
    loadStatisticsCommission() {
      this.loading_commission = false;
      httpClient
        .get(
          "v1/dashboard/ledger/statistics/commission/period?start=" +
            this.start +
            "&end=" +
            this.end
        )
        .then((response) => {
          this.data_commission = response.data;
          this.loading_commission = true;
        });
    },
  },
  mounted() {
    this.loadStatistics();
    this.loadStatisticsCommission();
  },
  components: { BaseCalendar, BaseSelect },
};
</script>
