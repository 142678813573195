export default {
  state: {
    token: localStorage.getItem("token") || "",
  },
  actions: {
    login({ commit }, token) {
      localStorage.setItem("token", token);
      commit("auth_success", token);
    },
    logout({ commit }) {
      commit("logout");
      localStorage.removeItem("token");
    },
  },
  mutations: {
    auth_success(state, token) {
      state.token = token;
    },
    logout(state) {
      state.token = "";
    },
  },
  getters: {
    isLoggedIn: (state) => !!state.token,
  },
};
