<template>
  <b-row v-if="loading">
    <b-col
      cols="12"
      v-for="(item, index) in data"
      :key="index"
      @click="selectItem(item.email)"
      class="pointer"
    >
      <base-card
        :accent="selected == item.email"
        :selected="selected == item.email"
      >
        <b-row align-v="center">
          <b-col cols="6">
            <b-row align-v="center">
              <b-col cols="auto" class="px-0"
                ><img
                  v-if="item.is_active"
                  src="@/assets/img/icon/Solid/Status/Checked-box.svg"
                /><img
                  v-else
                  src="@/assets/img/icon/Solid/Status/Info-triangle.svg"
                />
              </b-col>
              <b-col cols="auto">
                <p class="mb-0">{{ item.email }}</p>
                <p class="mb-0">Телеграм</p>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="6" class="text-end">
            <p class="mb-0">{{ item.balance }} USD</p>
          </b-col>
        </b-row>
      </base-card>
    </b-col>
    <b-col cols="12" v-if="data.length == 0">
      <base-card>
        <b-row align-h="center" class="text-center">
          <p>Нет данных по запросу</p>
        </b-row>
      </base-card>
    </b-col>
  </b-row>
  <b-row v-else>
    <base-card>
      <b-row align-h="center" class="text-center">
        <b-spinner variant="light"></b-spinner>
      </b-row>
    </base-card>
  </b-row>
</template>

<script>
import eventBus from "../../EventBus";
import { httpClient } from "../../HttpClientV1/http-client";
export default {
  data() {
    return {
      selected: null,
      data: [],
      query: null,
      loading: false,
    };
  },
  methods: {
    loadLedgerMerchantList() {
      let url = "";
      if ((this.query != "") & (this.query != null)) {
        url = "/v1/user/dashboard/user/list?q=" + this.query;
      } else {
        url = "/v1/user/dashboard/user/list";
      }
      httpClient.get(url).then((response) => {
        this.data = response.data;
        this.loading = false;
        this.selected = this.data[0].email;
        this.loading = true;
        eventBus.$emit("load_user_detail", this.data[0].email);
        // this.loadLedgerMerchantOne(this.data[0].email);
      });
    },
    selectItem(email) {
      this.selected = email;
      this.loading_data = true;
      eventBus.$emit("load_user_detail", email);
    },
  },
  mounted() {
    console.log("[LOGGING] ", "Loading user list");
    eventBus.$on("load_user_list", (data) => {
      this.query = data.query;
      this.loadLedgerMerchantList();
    });
  },
  beforeDestroy() {
    eventBus.$off("load_user_list");
  },
};
</script>
